import Axios from "../api/Axios";
import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { format, subDays } from "date-fns";
import storage from "../Services/Storage";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import useLogout from "../Hooks/useLogout";
import { EndpointDefault, createAPIDefaultEndpoint } from "../Services/Lite/LiteThemeService";
// import { EndpointDefault, createAPIDefaultEndpoint } from "../Services/LiteTheme/LiteThemeService";

let from_date;
let to_date;

const AppContext = React.createContext();
//
const AppProvider = ({ children }) => {
    const auth = storage.getLocal("user");
    // let Token = auth.Token;
    // Axios.defaults.headers.common["Authorization"] = `Bearer ${Token}`;
    const [overAllSales, setOverAllSales] = useState([]);
    const [allSalesOrders, setAllSalesOrders] = useState([]);
    const [filteredSalesOrders, setFilteredSalesOrders] = useState([]);
    const [selectedSalesOder, setSelectedSalesOder] = useState([]);
    //25-12-2022 Loading
    const [dashboardSales, setDashboardSales] = useState([]);
    const [dashboardLoader, setDashboardLoader] = useState(false);

    const [allSalesLoader, setAllSalesLoader] = useState(null);
    const [loaderStatus, setLoaderStatus] = useState(false);
    // const [salesOrderStatus, setSalesOrderStatus] = useState("Open");
    const [responsiveSideBar, setResponsiveSideBar] = useState(false);
    const [dashboardInvoices, setDashboarInvoices] = useState([]);
    const [parameterPopupShow, setParameterPopUpShow] = useState(false);
    const [lightTheme, setLightTheme] = useState([]);
    const [invoiceFilters, setInvoiceFilters] = useState({
        INVH_NO: "",
        CUST_NAME: "",
    });

    const [filters, setFilters] = useState({
        SO_NO: "",
        MASK_PARTY_CODE: "",
        CUST_NAME: "",
        MASK_EMP_NAME: "",
        BEAT_FREQUENCY: "",
        MASK_ORD_MODE_DESC: "",
    });
    const [dashboardFilter, setDashBoardFilter] = useState({
        SO_NO: "",
        MASK_PARTY_CODE: "",
        CUST_NAME: "",
        MASK_EMP_NAME: "",
        BEAT_FREQUENCY: "",
        MASK_ORD_MODE_DESC: "",
    });
    const Base_SALES_URL = "SalesOrder/GetAllSalesOrder?";
    const GetParam_URL = "DashBoard/GetParameterValues?";
    const GetDayDate_URL = "login/GetDayDate";

    //   /api/DashBoard/GetParameterValues
    const selectSalesOrder = (id) => {
        const selected = filteredSalesOrders.filter(
            (item) => item.MASK_SO_ID == id
        );
        setSelectedSalesOder(selected);
    };
    //eslint-disable-next-line
    const logout = useLogout();
    const findDateSales = (e, fromDate, toDate) => {
        const fDate = new Date(fromDate);
        const fromDateRange = format(fDate, "dd/MM/yyyy");
        const tDate = new Date(toDate);
        const toDateRange = format(tDate, "dd/MM/yyyy");

        from_date = format(fDate, "yyyy/MM/dd");
        to_date = format(tDate, "yyyy/MM/dd");
        if (from_date > to_date) {
            toast.warn("From Date should be equal to or less than To Date", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 4000,
            });

            return filteredSalesOrders;
        } else if (from_date < "2010-01-01") {
            toast.warn("From Date should be equal to or greater than 01-01-2010", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 4000,
            });

            return filteredSalesOrders;
        }
        salesRange(fromDateRange, toDateRange);

        e.preventDefault();
    };

    const salesRange = async (fromDate1, toDate1) => {
        setLoaderStatus(true);
        // if (salesStatus == "Open") {
        //     salesStatus = "open";
        //     setSalesOrderStatus("Open");
        // } else if (salesStatus == "Closed") {
        //     salesStatus = "closed";
        //     setSalesOrderStatus("Closed");
        // } else if (salesStatus == "Cancelled") {
        //     salesStatus = "cancelled";
        //     setSalesOrderStatus("Cancelled");
        // }

        try {
            const Sales_url = `${Base_SALES_URL}CICode=${auth.CICode}&FromDate=${fromDate1}&ToDate=${toDate1}&Tag=N&LangId=1&UserId=${auth.UserId}&CompId=${auth.CompId}`;
            const response = await Axios.get(Sales_url);
            // const result = response.data.filter(
            //     (val) =>
            //         val.STATUS.toLocaleLowerCase() === salesStatus.toLocaleLowerCase()
            // );

            let filteredFOC = response.data.filter(
                (p) => !String(p.SO_NO).startsWith("FOC")
            );

            {
                response.data.length < 1
                    ? setFilteredSalesOrders([])
                    : setFilteredSalesOrders(filteredFOC);
            }

            setAllSalesLoader(response.data);
            setAllSalesOrders(filteredFOC); // for filter
            setLoaderStatus(false);
        } catch (error) {
            setLoaderStatus(false);
            toast.error(error.response.data.Message, {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
            setFilteredSalesOrders([]);
        }
    };

    const getCountry = async () => {
        setDashboardLoader(1);

        try {
            const currentDate = format(new Date(), "dd/MM/yyyy");
            const date = subDays(new Date(), 7);
            const sevenDays = format(date, "dd/MM/yyyy");
            const Sales_url = `${Base_SALES_URL}CICode=${auth.CICode}&FromDate=${sevenDays}&ToDate=${currentDate}&Tag=N&LangId=1&UserId=${auth.UserId}&CompId=${auth.CompId}`;
            const response = await Axios.get(Sales_url);
            let filteredFOC = response.data.filter(
                (p) => !String(p.SO_NO).startsWith("FOC")
            );
            // const invoicesAll = `Invoice/GetAllInvoice?CICode=${auth.CICode}&strFromDateString=11/04/2023&strToDateString=18/04/2023&CompId=${auth.CompId}`;
            // const InvoiceRes = await Axios.get(invoicesAll);
            //
            // setDashboarInvoices(InvoiceRes.data);
            setOverAllSales(response.data);
            setAllSalesOrders(filteredFOC);
            {
                response.data.length < 1
                    ? setFilteredSalesOrders([])
                    : setFilteredSalesOrders(filteredFOC);
            }
            setDashboardSales(response.data);
            setDashboardLoader(0);
        } catch (error) {
            setDashboardLoader(0);
            toast.error(error.response.data.Message, {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
        }
    };

    const underMaintenance =async ()=>{
        try{
            const res = await Axios.get(GetDayDate_URL);
            let currentdatetime = new Date(res?.data[0].DateTime);
            let starttime = new Date();
            let endtime = new Date();
            if (currentdatetime.getDay() === 0) {
                // 8:30 AM
                starttime.setHours(8);
                starttime.setMinutes(30);
                starttime.setSeconds(0);
                starttime.setMilliseconds(0);
                //04.30 PM
                endtime.setHours(16);
                endtime.setMinutes(30);
                endtime.setSeconds(0);
                endtime.setMilliseconds(0);
                if (currentdatetime > starttime && currentdatetime < endtime) {
                    getCountry();
                } else {
                    // getCountry();
                    logout();
                    // nav("/");
                }
            } else {
                // 8:30 AM
                starttime.setHours(8);
                starttime.setMinutes(30);
                starttime.setSeconds(0);
                starttime.setMilliseconds(0);
                //11.30 PM
                endtime.setHours(23);
                endtime.setMinutes(29);
                endtime.setSeconds(0);
                endtime.setMilliseconds(0);
                if (currentdatetime > starttime && currentdatetime < endtime) {
                    getCountry();
                } else {
                    // getCountry();
                    logout();
                    // nav("/");
                }
            }}
        catch(error){
            console.log("error",error);
        }
    };

    useEffect( () => {    
        underMaintenance();
    }, [1]);

    
    const location = useLocation();
    useEffect(() => {
        const getParamvalues = async () => {
            if (
                location.pathname == "/usermanagement/parametersettings/transaction" ||
          location.pathname == "/"
            ) {
                setParameterPopUpShow(false);
            } else {
                try {
                    const Param_url = `${GetParam_URL}CompId=${auth.CompId}`;
                    const response = await Axios.get(Param_url);
                    console.log("response", response.data);
                    if (response.data.popupStatus == "Y") {
                        setParameterPopUpShow(true);
                    } else {
                        setParameterPopUpShow(false);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        };
        getParamvalues();
    }, [location]);

  

    const nav = useNavigate();

    const clickOk = () => {
        nav("/usermanagement/parametersettings/transaction");
        setParameterPopUpShow(false);
    };

    const ComponentToReturn = () => {
        return (
            <>
                <Dialog
                    header="Confirmation"
                    visible={parameterPopupShow}
                    style={{ width: "50vw" }}
                    closable={false}
                    resizable={false}
                    draggable={false}
                >
                    <div className="col-md-12 col-lg-12 col-sm-12 text-center">
                        <p>Please set your Parameter values before proceeding.</p>
                    </div>
                    <div className="float-end mt-4">
                        <button
                            type="button"
                            className="btn btn-primary me-3"
                            onClick={() => {
                                clickOk();
                            }}
                        >
              OK
                        </button>
                    </div>
                </Dialog>
            </>
        );
    };

    useEffect(() => {
        var tempSalesOrder = [...allSalesOrders];

        if (filters.SO_NO) {
            tempSalesOrder = tempSalesOrder.filter((orders) => {
                return orders.SO_NO.toLocaleLowerCase().includes(
                    filters.SO_NO.toLocaleLowerCase()
                );
            });
        }
        if (filters.MASK_PARTY_CODE) {
            //
            tempSalesOrder = tempSalesOrder.filter((orders) => {
                return orders.MASK_PARTY_CODE.toLocaleLowerCase().includes(
                    filters.MASK_PARTY_CODE.toLocaleLowerCase()
                );
            });
        }
        if (filters.CUST_NAME) {
            tempSalesOrder = tempSalesOrder.filter((orders) => {
                return orders.CUST_NAME.toLocaleLowerCase().includes(
                    filters.CUST_NAME.toLocaleLowerCase()
                );
            });
        }
        if (filters.MASK_EMP_NAME) {
            tempSalesOrder = tempSalesOrder.filter((orders) => {
                return orders.MASK_EMP_NAME.toLocaleLowerCase().includes(
                    filters.MASK_EMP_NAME.toLocaleLowerCase()
                );
            });
        }
        if (filters.BEAT_FREQUENCY) {
            tempSalesOrder = tempSalesOrder.filter((orders) => {
                return orders.BEAT_FREQUENCY.toLocaleLowerCase().includes(
                    filters.BEAT_FREQUENCY.toLocaleLowerCase()
                );
            });
        }
        if (filters.MASK_ORD_MODE_DESC) {
            tempSalesOrder = tempSalesOrder.filter((orders) => {
                return orders.MASK_ORD_MODE_DESC.toLocaleLowerCase().includes(
                    filters.MASK_ORD_MODE_DESC.toLocaleLowerCase()
                );
            });
        }
        setFilteredSalesOrders(tempSalesOrder);
    }, [filters]);

    useEffect(()=>{
        createAPIDefaultEndpoint()
            .get(`${EndpointDefault.GetLiteThemeStatus}?UserId=${auth.UserId}`)
            .then((res)=>{
                setLightTheme(res.data[0].LITE_THEME);
                localStorage.setItem("LightTheme",JSON.stringify(res.data[0].LITE_THEME));
            });
    },[]);

    const handleChange = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        setFilters({ ...filters, [id]: value });
    };
    useEffect(() => {
        var tempSalesOrder = [...allSalesOrders];

        if (dashboardFilter.SO_NO) {
            tempSalesOrder = tempSalesOrder.filter((orders) => {
                return orders.SO_NO.toLocaleLowerCase().includes(
                    dashboardFilter.SO_NO.toLocaleLowerCase()
                );
            });
        }
        if (dashboardFilter.MASK_PARTY_CODE) {
            tempSalesOrder = tempSalesOrder.filter((orders) => {
                return orders.MASK_PARTY_CODE.toLocaleLowerCase().includes(
                    dashboardFilter.MASK_PARTY_CODE.toLocaleLowerCase()
                );
            });
        }
        if (dashboardFilter.CUST_NAME) {
            tempSalesOrder = tempSalesOrder.filter((orders) => {
                return orders.CUST_NAME.toLocaleLowerCase().includes(
                    dashboardFilter.CUST_NAME.toLocaleLowerCase()
                );
            });
        }
        if (dashboardFilter.MASK_EMP_NAME) {
            tempSalesOrder = tempSalesOrder.filter((orders) => {
                return orders.MASK_EMP_NAME.toLocaleLowerCase().includes(
                    dashboardFilter.MASK_EMP_NAME.toLocaleLowerCase()
                );
            });
        }
        if (dashboardFilter.BEAT_FREQUENCY) {
            tempSalesOrder = tempSalesOrder.filter((orders) => {
                return orders.BEAT_FREQUENCY.toLocaleLowerCase().includes(
                    dashboardFilter.BEAT_FREQUENCY.toLocaleLowerCase()
                );
            });
        }
        if (dashboardFilter.MASK_ORD_MODE_DESC) {
            tempSalesOrder = tempSalesOrder.filter((orders) => {
                return orders.MASK_ORD_MODE_DESC.toLocaleLowerCase().includes(
                    dashboardFilter.MASK_ORD_MODE_DESC.toLocaleLowerCase()
                );
            });
        }
        setDashboardSales(tempSalesOrder);
    }, [dashboardFilter]);

    const dashboardHandleChange = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        setDashBoardFilter({ ...dashboardFilter, [id]: value });
    };
    const getOrientation = () =>
        window.screen.orientation.type;
    const [orientation, setOrientation] =
    useState(getOrientation());

    const updateOrientation = () => {
        setOrientation(getOrientation());
    };

    useEffect(() => {
        window.addEventListener(
            "orientationchange",
            updateOrientation
        );
        return () => {
            window.removeEventListener(
                "orientationchange",
                updateOrientation
            );
        };
    }, []);

    console.log(orientation,"orientation");
    
    return (
        <>
            <AppContext.Provider
                value={{
                    handleChange,
                    dashboardHandleChange,
                    filters,
                    setFilters,
                    getCountry,
                    allSalesOrders,
                    setAllSalesOrders,
                    overAllSales,
                    filteredSalesOrders,
                    selectSalesOrder,
                    selectedSalesOder,
                    setSelectedSalesOder,
                    findDateSales,
                    allSalesLoader,
                    loaderStatus,
                    dashboardSales,
                    dashboardLoader,
                    // salesOrderStatus,
                    setResponsiveSideBar,
                    responsiveSideBar,
                    dashboardInvoices,
                    setDashboarInvoices,
                    invoiceFilters,
                    setInvoiceFilters,
                    ComponentToReturn,
                    parameterPopupShow,
                    clickOk,
                    lightTheme, 
                    setLightTheme,
                    orientation
                }}
            >
                {children}
            </AppContext.Provider>
        </>
    );
};
export const useGlobalContext = () => {
    return useContext(AppContext);
};

export { AppContext, AppProvider };

