import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { RadioButton } from "primereact/radiobutton";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import { createAPICustReActivationSegChangeEndpoint, Endpoint } from "../../Services/SegmentReactivationChangeRequest/SegmentReactivationChangeRequest";
import storage from "../../Services/Storage";

const SegmentReactivationAllRecords = () => {

    const auth = storage.getLocal("user");
    const [strType, setStrType] = useState("SC");
    const [allData, setAllData] = useState([]);
    const [loader,setLoader] = useState(false);

    const GetAllRecordsofCustomerForReactivation = async(Type) =>{
        setLoader(true);
        createAPICustReActivationSegChangeEndpoint()
            .get(`${Endpoint.GetAllRecordsofCustomerForReactivation}?CompId=${auth.CompId}&strType=${(Type==="SC"||Type==="EC")?Type:strType}`)
            .then((res)=>{
                setAllData(res.data.Result.Item1.Data);
                setLoader(false);
            })
            .catch((err)=>{
                console.log(err,"GetAllRecordsofCustomerForReactivation");
                setLoader(false);
            });
    };
    useEffect(()=>{
        GetAllRecordsofCustomerForReactivation();
    },[]);
    if(loader){
        return(<Loader/>);
    }
    else{
        return (
            <>
                <div className="col-12 pt-3 pb-3">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h3 className="py-2">Segment / Reactivation Change Request All Records</h3>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="float-end mb-2">
                                <Link to="/masters/segmentreactivationchangerequest">
                                    <button type="button" className="btn btn-primary" >
                  Back
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* <section className="sales-box">
          <form>
            <div className="row d-flex justify-content-end align-items-end">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <label className="form-label">From Date</label>
                <input type="date" className="form-control" />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <label className="form-label">To Date</label>
                <input type="date" className="form-control" />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <label className="form-label">Select Type</label>
                <select className="form-select form-select-lg">
                  <option value="Cre">Segment Change</option>
                  <option value="Deb">Re-activation</option>
                </select>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="text-end">
                  <button type="button" className="btn btn-primary" >
                    Get Data
                  </button>
                </div>
              </div>
            </div>
          </form>
        </section> */}
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                        <form>
                            <div className="my-3 d-flex justify-content-center">
                                <span className='me-3'>Select Type :</span>
                                <div className="d-inline-flex">
                                    <RadioButton name="Type" value="SC" onChange = {(e)=>{setStrType(e.value);GetAllRecordsofCustomerForReactivation(e.value);}} checked={strType === "SC"}/>
                                    <label className="mx-2">Segment Change</label>
                                    <RadioButton name="Type" value="EC" onChange = {(e)=>{setStrType(e.value);GetAllRecordsofCustomerForReactivation(e.value);}} checked={strType === "EC"}/>
                                    <label className="mx-2">Re-activation</label>
                                </div>
                            </div>
                        </form>
                    </div>


                    {/* Segment Change */}
                    {strType==="SC"&&<section className="mt-3">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h3 className="py-2">Records</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">

                                </div>
                            </div>

                            <div className="DataTable pb-4">
                                <DataTable
                                    value={allData}
                                    // dataKey="DRCR_NO"
                                    paginator
                                    rows={10}
                                    rowsPerPageOptions={[10, 25, 50]}
                                    scrollHeight="80vh"
                                    filterDisplay="row"
                                    showGridlines
                                    scrollable
                                    stripedRows
                                    // selection={selectedDrCr}
                                    // onSelectionChange={(e) => {
                                    //     setSelectedDrCr(e.value);
                                    // }}
                                    emptyMessage="No Data Found."
                                    tableStyle={{ minWidth: "100rem" }}
                                >
                                    <Column
                                        field="CUST_CODE"
                                        header="Customer Code"
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    ></Column>
                                    <Column
                                        field="CUST_NAME"
                                        header="Customer Name"
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    ></Column>
                                    <Column
                                        field="UNIQUE_CODE"
                                        header="Unique Code"
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    ></Column>
                                    <Column
                                        field="SEG_CODE"
                                        header="Segment Code"
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    ></Column>
                                    <Column
                                        field="PROP_SEG_CODE"
                                        header="Proposed Segment Code"
                                    ></Column>
                                    <Column
                                        field="PROP_SEG_NAME"
                                        header="Proposed Segment Name"
                                    ></Column>
                                    <Column
                                        field="COMMENTS"
                                        header="Comments"
                                    ></Column>
                                    <Column
                                        field="STATUS"
                                        header="Status"
                                    ></Column>
                                    <Column
                                        field="SUBMITTED_DATE"
                                        header="Submitted Date"
                                    ></Column>
                                </DataTable>
                            </div>
                        </div>
                    </section>}

                    {/* Re-activation Change */}
                    {strType==="EC"&&<section className="mt-3">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h3 className="py-2">Records</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">

                                </div>
                            </div>

                            <div className="DataTable pb-4">
                                <DataTable
                                    value={allData}
                                    // dataKey="DRCR_NO"
                                    paginator
                                    rows={10}
                                    rowsPerPageOptions={[10, 25, 50]}
                                    scrollHeight="80vh"
                                    filterDisplay="row"
                                    showGridlines
                                    scrollable
                                    stripedRows
                                    // selection={selectedDrCr}
                                    // onSelectionChange={(e) => {
                                    //     setSelectedDrCr(e.value);
                                    // }}
                                    emptyMessage="No Data Found."
                                    tableStyle={{ minWidth: "100rem" }}
                                >
                                    <Column
                                        field="CUST_CODE"
                                        header="Customer Code"
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    ></Column>
                                    <Column
                                        field="CUST_NAME"
                                        header="Customer Name"
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    ></Column>
                                    <Column
                                        field="UNIQUE_CODE"
                                        header="Unique Code"
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    ></Column>
                                    <Column
                                        field="SEG_CODE"
                                        header="Segment Code"
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    ></Column>
                                    <Column
                                        field="EXPIRY_DATE"
                                        header="Expiry Date"
                                    ></Column>
                                    <Column
                                        field="PROP_EXPIRY_DATE"
                                        header="Revised Expiry Date"
                                    ></Column>
                                    <Column
                                        field="COMMENTS"
                                        header="Comments"
                                    ></Column>
                                    <Column
                                        field="STATUS"
                                        header="Status"
                                    ></Column>
                                    <Column
                                        field="SUBMITTED_DATE"
                                        header="Submitted Date"
                                    ></Column>
                                </DataTable>
                            </div>
                        </div>
                    </section>}
                </div>
            </>
        );
    }
};

export default SegmentReactivationAllRecords;