import Axios from "../api/Axios";
import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import storage from "../Services/Storage";
import { useEffect } from "react";
import { format, subDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "./SalesOrderContext";

//Global Variable for geting Available quantity--------------------------------------------
let invoiceQty;
let reasonCode;
let reasonName;
let hdrID;
const SalesDetailsContext = React.createContext();

const SalesDetailsProvider = ({ children }) => {
    const {
        setSelectedSalesOder,
    // selectedSalesOder
    } = useGlobalContext();
    // const dt = format(new Date(),"dd/MM/yyyy");
    let date = new Date();
    let day = String(date.getDate()).padStart(2, "0");
    let month = String(date.getMonth() + 1).padStart(2, "0");
    let year = date.getFullYear();
    // const [cancelFlag, setCancelFlag] = useState(false);

    let fullDate = `${day}/${month}/${year}`.toString();
    const currentDate = `${day}/${month}/${year}`.toString();
    const nav = useNavigate();
    const auth = storage.getLocal("user");
    const [salesOrderHdr, setSalesOrderHdr] = useState([]);
    const [salesDetails, setSalesDetails] = useState([]);
    const [filteredSalesDetails, setFilteredSalesDetails] = useState([]);
    const [disableAutoAllocate, setDisableAutoAllocate] = useState(true);
    const [reason, setReason] = useState(false);
    // const [cancelItem, setCancelItem] = useState(false);
    //cancle options
    const [cancel, setCancel] = useState([]);
    const [productCodeCancel, setProductCodeCancel] = useState();
    const [lotNoCancel, setLotNoCancel] = useState();

    const [loading, setLoading] = useState(false);
    const [showMsg, setShowMsg] = useState(false);
    const [showUpdateMsg, setShowUpdateMsg] = useState(false);
    //07-12-2022
    const [productName, setProductName] = useState([]);
    const [productCode, setProductCode] = useState([]);
    const [reallocateToggle, setReallocateToggle] = useState(false);
    const [orderQty, setOrderQty] = useState([]);
    const [lotNo, setLotNo] = useState([]);
    const [productPrice, setProductPrice] = useState([]);
    const [availableQty, setAvailableQty] = useState([]); //pending
    const [fullCancelToggle, setFullCancelToggle] = useState(false);
    const [fullCancelColor, setFullCancelColor] = useState(false);
    const [userReasonSelected, setUserReasonSelected] = useState("Select Reason");
    // const [lineLevelUpdate, setLineLevelUpdate] = useState(false);

    //12-12-2022
    const [modalShow, setModalShow] = useState(false);
    const [fgtModalShow, setFgtModalShow] = useState(false);
    const [creditDetails, setCreditDetails] = useState([]);
    const [creditDetails2, setCreditDetails2] = useState([]);
    const [gross, setGross] = useState([]);
    //volume liter&weight
    const [netVolLtr, setNetVolLtr] = useState([]);
    const [netVolWt, setNetVolWt] = useState([]);
    //sum product
    const [totalOrder, setTotalOrder] = useState([]);
    //amount before tax
    const [amountBeforeTax, setAmountBeforeTax] = useState([]);
    //total amount
    const [totalAmount, setTotalAmount] = useState([]);
    const [refReason, setRefReason] = useState("");
    const [editModal, setEditModal] = useState(false);
    const [disable, setDisable] = useState(true);
    const [printInvoiceModalData, setPrintInvoiceModalData] = useState([]);
    const [releaseQtyCheck, setReleaseQtyCheck] = useState(); // calculating ReleaseQty is 0 or not.
    const [updateStatusCheck, setUpdateStatusCheck] = useState(false); // check
    const [invoicePopup, setInvoicePopup] = useState(false);
    const [reasonDetails, setReasonDetails] = useState([]);
    const [invoiceBtn, setInvoiceBtn] = useState(true);

    //journey map
    const [add, setAdd] = useState(false);
    const [update, setUpdate] = useState(false);
    const [invoice, setInvoice] = useState(false);

    //manual SO journey map
    const [customer, setCustomer] = useState(false);
    const [product, setProduct] = useState(false);
    const [addToCart, setAddToCart] = useState(false);
    const [save, setSave] = useState(false);
    // const [Customerinformationapi, setCustomerinformationapi] = useState();
    const [PopulateCrdNote, setPopulateCrdNote] = useState();
    const [isPrintInvoice, setIsPrintInvoice] = useState(false);

    const [poptobeshow, setpoptobeshow] = useState(false);
    const [cancelFullorderModal, setCancelFullorderModal] = useState(false);

    //manuslSOOrder
    const [custId, setCustId] = useState([]);
    const [SONo, setSONo] = useState([]);
    const [cartVal, setCartVal] = useState([]);
    const [weight, setWeight] = useState([]);
    const [volume, setVolume] = useState([]);
    const [custInfo, setCustInfo] = useState([]);
    const [exchRate, setExchRate] = useState([]);
    const [priceCalculation, setPriceCalculation] = useState([]);
    const [payTermDueDate, setPayTermDueDate] = useState([]);
    const [defWh, setDefWh] = useState([]);
    const [printMessage, setPrintMessage] = useState([]);
    const [remarks, setRemarks] = useState("");
    const [serie, setSerie] = useState([]);
    const [orderSummary, setOrderSummary] = useState(false);
    const [manualSOLoader, setManualSOLoader] = useState(false);
    const [tradeDisc, setTradeDisc] = useState("");
    const [custPONo, setCustPONo] = useState("");

    //Payload manage
    const [payload, setPayload] = useState(false);
    const [soHdrId, setSoHdrId] = useState([]);
    const [switchUpdate, setSwitchUpdate] = useState(false);
    const [salesOrderDtlpath, setSalesOrderDtlpath] = useState(true);

    //default values
    const [OrdMode, setOrdMode] = useState([]);
    const [deliveryInstruction, setDeliveryInstruction] = useState([]);
    const [billingInstruction, setBillingInstruction] = useState([]);
    const [printHeading, setPrintHeading] = useState([]);
    const [ESPECIE, setESPECIE] = useState([]);

    const [salesOrderSummary, setSalesOrderSummary] = useState([]);
    const [disableRelease, setDisableRelease] = useState([0]);
    const [orderSatus, setOrderStatus] = useState();
    const [manualCart, setManualCart] = useState([]);
    const [payTerm, setPayTerm] = useState();
    const [MopId, setMopId] = useState();
    const [hedaerReasonDetails, setHedaerReasonDetails] = useState();
    const [defaultReasonData, setDefaultReasonData] = useState();

    const [kycdisabled, setKycdisabled] = useState(false);

    //password policy validation
    const [passExpCaution,setpassExpCaution] =useState(false);

    let url = "SalesOrder/";
    const CREATE_INVOICE = "Invoice/createInvoice";
    // const PRINT_INVOICE = "Invoice/PrintInvoice";
    // const reallocation = `${url}ReAllocation`;
    const CreditPosition_URL = `${url}GetCustFinancialInfo?`;
    const CreditPosition_URL2 = `${url}GetCustCreditDtlData?`;
    // const UPDATE_AUTOFILL = `${url}SaveSOData`;
    const INSERT_SO = `${url}UPsertSOData`;
    const SALES_ORDER_URL = `${url}GetProductDetails?`;
    const cancelDetails_url = `${url}GetReasonDetails?`;
    const GetNetVolltr_URL = `${url}GetNetVolltr?`;
    const GetNetVolwt_URL = `${url}GetNetVolwt?`;
    const CancelData_URL = `${url}CancelData?`;
    const CancelDataILS_URL = `${url}CancelDataILS?`;
    const SalesOrderSummary_URL = `${url}GetSalesOrderSummary?`;
    const Base_SALES_URL = `${url}GetAllSalesOrder?`;
    const SOStatus = `${url}GetSOStatus?`;
    const apicalling = `${url}GetCustomerInformation?`;
    const generate_SONO = `${url}GenerateSO_NO?`;
    const headerReason_URL = `${url}GetHdrReasonDetails?`;
    const PopulateCreditNote = "Invoice/PopulateCreditNote";
    const AdjustCreditNote = "Invoice/AdjustCreditNote";
    const DefaultVal_URL = "Default/DefaultVal?";
    // /api/SalesOrder/GetHdrReasonDetails
    //passing invoice number from printInvoice to salesorderdetails page
    const [invoiceNo, setInvoiceNo] = useState([]);
    const getCustomerInfoObj =async()=>{
        const custinfo = `${apicalling}CICode=${auth.CICode}&strCustId=${salesOrderHdr.MASK_CUST_ID}`;
        const custinfo_res = await Axios.get(custinfo);
        // setLoading(true);
        const objCustInfo = custinfo_res.data.Table.map((val) => ({
            MASK_CURRENCY_ID: val.MASK_CURRENCY_ID,
            MASK_CURRENCY_NAME: val.MASK_CURRENCY_NAME,
            MASK_MOP_ID: val.MASK_MOP_ID,
            MASK_MOP_DESC: val.MASK_MOP_DESC,
            MASK_PAY_TERM_ID: val.MASK_PAY_TERM_ID,
            MASK_PAY_TERM_DESC: val.MASK_PAY_TERM_DESC,
            MASK_TAX_FORM_ID: val.MASK_TAX_FORM_ID,
            MASK_TAX_FORM_DESC: val.MASK_TAX_FORM_DESC,
            MASK_VAN_RT_ID: val.MASK_VAN_RT_ID,
            MASK_VAN_RT_DESC: val.MASK_VAN_RT_DESC,
            MASK_CUST_DOC_DISC: val.MASK_CUST_DOC_DISC.toString(),
            CBILL_ADD1: val.CBILL_ADD1,
            CBILL_ADD2: val.CBILL_ADD2,
            BILL_STATE: val.BILL_STATE,
            CBILL_ADD_GEOG_ID: val.CBILL_ADD_GEOG_ID,
            BILL_TOWN: val.BILL_TOWN,
            CBILL_ADD_PIN: val.CBILL_ADD_PIN,
            CSHIP_ADD1: val.CSHIP_ADD1,
            CSHIP_ADD2: val.CSHIP_ADD2,
            SHIP_STATE: val.SHIP_STATE,
            CSHIP_ADD_GEOG_ID: val.CSHIP_ADD_GEOG_ID,
            SHIP_TOWN: val.SHIP_TOWN,
            CSHIP_ADD_PIN: val.CSHIP_ADD_PIN,
            MASK_PAY_TERM_TYPE: val.MASK_PAY_TERM_TYPE,
            MASK_TAX_AREA_ID: val.MASK_TAX_AREA_ID,
            MASK_AREA_NAME: val.MASK_AREA_NAME,
            MASK_TRANS_CHARGE_ID: val.MASK_TRANS_CHARGE_ID,
            MASK_TRANSCHARGE_DESCRIPTION: val.MASK_TRANSCHARGE_DESCRIPTION,
            MASK_TRANSCHARGE_RATE: val.MASK_TRANSCHARGE_RATE,
            MASK_SALES_EXEC_EMP_ID: val.MASK_SALES_EXEC_EMP_ID,
            MASK_EMP_NAME: val.MASK_EMP_NAME,
            MASK_CUST_DELIVERY_INST: val.MASK_CUST_DELIVERY_INST,
            MASK_CUST_BILLING_INST: val.MASK_CUST_BILLING_INST,
            MASK_SALES_AREA_ID: val.MASK_SALES_AREA_ID,
            MASK_CSHIP_SRL_NO: val.MASK_CSHIP_SRL_NO,
            MASK_CBILL_CONT_FNAME: val.MASK_CBILL_CONT_FNAME,
            MASK_CIF_FOB: val.MASK_CIF_FOB,
            EMP_ID: val.EMP_ID,
            SHIPEMP_NAME: val.SHIPEMP_NAME,
            DAY_OF_WEEK: val.DAY_OF_WEEK.toString(),
            
        }));
        return objCustInfo;
    };
    //15-12-2022----------------------------------------------------
    // const SegmentCode_Url_Base = `${url}GetSegmentCODE?`;
    // const segment_Url_Base = `${url}GetSegment?`;
    // const EM_IE_Url_Base = `${url}GetOEM_IE?`;
    // const SEGwiseProdDataSOCreation_Url_Base = `${url}GetSEGwiseProdDataSOCreation?`;

    const invoiceCall =async (data) => {
      
        setLoading(true);
        // Create Invoice
        let modelInvProdList = filteredSalesDetails.map((a) => ({
            productCode: a.MASK_PROD_ID,
            productName: a.PROD_SMALL_DESC,
            lotNo: a.PROD_LOT_NO,
            mrp: a.MASK_PRODUCT_MRP.toString(),
            productPrice: a.MASK_SODT_PRL_PRICE,
            extendedPrice: a.EXTENDED_PRICE.toString(),
            PACK_ORD_QTY: a.TOT_ORD_QTY.toString(),
            TOT_INV_QTY: a.TOT_INV_QTY.toString(),
            TOT_REL_QTY: a.TOT_REL_QTY.toString(),
            TOT_BACK_QTY: a.MASK_TOT_BACK_QTY.toString(),
            MASK_STATUS: a.MASK_STATUS,
            reason: a.RBO_REAS_CODE,
        }));
        const objCustInfo = await getCustomerInfoObj();
        let obj1 = {
            ciCode: auth.CICode,
            invId: null,
            soHrdId: payload ? soHdrId : salesOrderHdr.MASK_SO_ID,
            userId: auth.UserId,
            custId: payload ? custId[0].MASK_CUST_ID : salesOrderHdr.MASK_CUST_ID,
            compId: auth.CompId,
            eInvApplicability: auth.EInvApplicability,
            eInvIRPUserPassword: auth.EInvIRPUserPassword,
            eInvIRPUserId: auth.EInvIRPUserId,
            eInvGSTNO: auth.EInvGSTNO,
            printFormatCode: auth.PrintFormatCode,
            popupRes: data=="false" ? "N" : "",
            objCustInfo: objCustInfo,
            clsModelInvProdList: modelInvProdList,
            invoiceNo: null,
            invoiceId: null,
            invhId: null,
            invNo: null,
            soStatus: null,
            compGSTDate: null,
            compState: null,
            eInvoiceErrorCode: null,
            eInvoiceErrorMessage: null,
        };
        console.log("createinv",obj1);
        // return;
        Axios
            .post(CREATE_INVOICE, JSON.stringify(obj1), {
                headers: { "Content-Type": "application/json" },
            })
            .then((res) => {
                if (res.data.Status === "Cr Note Found") {
                    // console.log("credit note is found");

                    setpoptobeshow(true);

                    // console.log("Abhirup007", res.data);

                    setLoading(false);
                } 
                else if(res.data.Status=="Error"){
                    toast.error(res.data.Message, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    });
                    setLoading(false);

                }
                else {
                    // debugger;
                    let objInvoice = [{
                        CICode: auth.CICode,
                        InvoiceId: res.data.clsMGenerateInv.InvoiceId,
                        CompId: auth.CompId,
                        EInvPaymentUPI: auth.EInvPaymentUPI,
                        InvoiceDate: fullDate,
                        DistTag: auth.DistTag,
                        EInvApplicability: auth.EInvApplicability,
                        EInvB2CQR: auth.EInvB2CQR,
                    }];
                    setPrintInvoiceModalData(objInvoice);
                    setInvoicePopup(true);
                    setUpdate(true);
                    setAdd(true);
                    if (res) {
                        setInvoicePopup(true);
                        setLoading(false);
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                toast.error(error.response.Message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
                // console.log("error,", error);
            });
    };

    // PopulateCreditNote
    const PopulateCreditNoteFunc =async () => {
        setLoading(true);
        const objCustInfo = await getCustomerInfoObj();
        // Populate Credit Note
        let PopulateCreditNoteData = {
            ciCode: auth.CICode,
            custId: payload ? custId[0].MASK_CUST_ID : salesOrderHdr.MASK_CUST_ID,
            // popupRes: selectedbtn == true ? 'Y' : 'N',
            objCustInfo: objCustInfo,
        };

        Axios
            .post(PopulateCreditNote, JSON.stringify(PopulateCreditNoteData), {
                headers: { "Content-Type": "application/json" },
            })
            .then((res) => {
                setLoading(false);
                setIsPrintInvoice(true);
                setPopulateCrdNote(res?.data?.PayableDtl);
                
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error.response.data.Message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });

                console.log("error,", error);
            });
    };
    // console.log();
    // return;

    // Calling Adjust Credit Note
    const AdjustCreditNoteFunc =async (creditAjustment) => {
       
        setLoading(true);
        // console.log("loading..........");
        const objCustInfo = await getCustomerInfoObj();
        let modelInvProdList = filteredSalesDetails.map((a) => ({
            productCode: a.MASK_PROD_ID,
            productName: a.PROD_SMALL_DESC,
            lotNo: a.PROD_LOT_NO,
            mrp: a.MASK_PRODUCT_MRP.toString(),
            productPrice: a.MASK_SODT_PRL_PRICE,
            extendedPrice: a.EXTENDED_PRICE.toString(),
            PACK_ORD_QTY: a.TOT_ORD_QTY.toString(),
            TOT_INV_QTY: a.TOT_INV_QTY.toString(),
            TOT_REL_QTY: a.TOT_REL_QTY.toString(),
            TOT_BACK_QTY: a.MASK_TOT_BACK_QTY.toString(),
            MASK_STATUS: a.MASK_STATUS,
            reason: a.RBO_REAS_CODE,
        }));
        // Populate Credit Note
        let AdjustCreditNoteData = {
            ciCode: auth.CICode,
            definedBy: auth.DefinedBy,
            userId: auth.UserId,
            compId: auth.CompId,
            checkCount: creditAjustment.length.toString(),
            custId: payload ? custId[0].MASK_CUST_ID : salesOrderHdr.MASK_CUST_ID,
            isTrueCNAdj:true,
            invNo: "",
            invHdrId:"",
            totAmt: creditAjustment.reduce((acc,val)=>(acc=acc+Number(val.PAYABLE_AMT)),0).toString(),//creditAjustment.PAYABLE_AMT,      // Payble Amount Sum on selected rows
            compGSTDate:"",
            compState:"",   
            invhId:"",
            soHrdId:payload ? soHdrId : salesOrderHdr.MASK_SO_ID,
            soStatus:"",
            popupRes:"",
            invoiceNo:"",
            invoiceId:"",
            objPayableDocBody:creditAjustment,
            clsModelInvProdList:modelInvProdList,//filteredSalesDetails,
            objCustInfo: objCustInfo,//Customerinformationapi,
        };
        console.log("final data ",AdjustCreditNoteData);
        // return;


        Axios
            .post(AdjustCreditNote, JSON.stringify(AdjustCreditNoteData), {
                headers: { "Content-Type": "application/json" },
            })
            .then((res) => {
                if(res.data.Status=="Error"){
                    toast.error(res.data.Message, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    });
                    setLoading(false);
                    setIsPrintInvoice(false);
                }
                else{
                    setIsPrintInvoice(false);
                    setPopulateCrdNote(res?.data);
                    // console.log("AdjustCreditNote",res?.data);
                    const objInvoice = [{
                        CICode: auth.CICode,
                        InvoiceId: res.data.clsMGenerateInv.InvoiceId,
                        CompId: auth.CompId,
                        EInvPaymentUPI: auth.EInvPaymentUPI,
                        InvoiceDate: fullDate,
                        DistTag: auth.DistTag,
                        EInvApplicability: auth.EInvApplicability,
                        EInvB2CQR: auth.EInvB2CQR,
                    }];
                    // console.log("Adjustment print payload",objInvoice)
                    // return 
                    setPrintInvoiceModalData(objInvoice);
                    setInvoicePopup(true);
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error.response.data.Message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
                console.log("error,", error);
            });
    };

    // const updatedStatus = (OrdrQty, BckQty, InvQty, RelQty, CNStatus, reason) => {
    // // if (Number(OrdrQty) >= Number(InvQty) + Number(RelQty)) {
    // //     if (
    // //         reason === "REAS/52" ||
    // // reason === "REAS/57" ||
    // // reason === "REAS/53" ||
    // // reason === "REAS/50"
    // //     ) {
    // //         return "CN";
    // //     }
    // //     return "N";
    // // } else {
    // //     return "N";
    // // }
    //     if (CNStatus === "CN") {
    //         return "CN";
    //     } else {
    //         return CNStatus;
    //     }
    // };
    //update SOdata

    function changeDateFormat(inputDate) {
        if(inputDate !== null){
            const [day, month, year] = inputDate.split("/");
            const formattedDate = `${month}/${day}/${year}`;
            return formattedDate;
        }
        else{
            return null;
        }
    }

    const updateSales = async (filteredSalesDetails) => {
        if(filteredSalesDetails.some((val) => Number(val.PROD_PRICE) < 0)){
            toast.error("Price of product is zero or negative.", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
        }
        else{
        // debugger;
            setLoading(true);
        
            let docPayTermData = [salesOrderHdr].map((a) => ({
                COMP_ID: auth.CompId,
                DOC_ID: a.MASK_SO_ID,
                PAY_TERM_ID: a.MASK_PAY_TERM_ID,
                DOC_TYPE: "",
                DUE_DATE: changeDateFormat(a.MASK_SOHD_PROMISED_DUE_DATE),
                // DUE_DATE: format(a.MASK_SOHD_PROMISED_DUE_DATE,"dd/MM/yyyy"),
                TYPE: "",
            }));
            let _salesOrderHdr = [salesOrderHdr].map((a) => ({
                SOHD_ID: a.MASK_SO_ID,
                SOHD_SO_NO: a.SO_NO,
                SOHD_SO_DATE: changeDateFormat(a.SO_DATE),
                SOHD_TYPE: a.MASK_SOHD_TYPE,
                COMP_ID: a.MASK_COMP_ID,
                DIVN_ID: "GEN",
                //  DIVN_ID:a.MASK_DIVN_ID,
                SOHD_REQ_DEL_DATE: changeDateFormat(a.MASK_SOHD_REQ_DEL_DATE),
                SOHD_PROMISED_DUE_DATE: changeDateFormat(a.MASK_SOHD_PROMISED_DUE_DATE),
                CURRENCY_ID: a.MASK_CURRENCY_ID,
                SOHD_EXCH_RATE: a.MASK_SOHD_EXCH_RATE,
                SOHD_TRADE_DISC_PERC: a.MASK_SOHD_TRADE_DISC_PERC??"0",
                ORD_MODE_ID: a.MASK_ORD_MODE_ID,
                MOP_ID: a.MASK_MOP_ID,
                SOHD_CREATE_EMP_ID: a.MASK_SOHD_CREATE_EMP_ID,
                WH_ID: a.MASK_WH_ID,
                TAX_FORM_ID: a.MASK_TAX_FORM_ID,
                VAN_RT_ID: a.MASK_VAN_RT_ID,
                PAY_TERM_ID: a.MASK_PAY_TERM_ID,
                SOHD_CUST_PO_NO: a.MASK_SOHD_CUST_PO_NO,
                SOHD_CUST_PO_DATE: changeDateFormat(a.MASK_SOHD_CUST_PO_DATE),
                CUST_ID: a.MASK_CUST_ID,
                CUST_NAME: a.CUST_NAME,
                CBILL_ADD1: a.MASK_CBILL_ADD1,
                CBILL_ADD2: a.MASK_CBILL_ADD2,
                CBILL_ADD_GEOG_ID: a.MASK_CBILL_ADD_GEOG_ID,
                CBILL_ADD_PIN: a.MASK_CBILL_ADD_PIN,
                CSHIP_ADD1: a.MASK_CSHIP_ADD1,
                CSHIP_ADD2: a.MASK_CSHIP_ADD2,
                CSHIP_ADD_GEOG_ID: a.MASK_CSHIP_ADD_GEOG_ID,
                CSHIP_ADD_PIN: a.MASK_CSHIP_ADD_PIN,
                SOHD_ROUND_OFF_AMT: "0.00",
                SOHD_TOT_BASE_CRNC_AMT: a.MASK_SOHD_TOT_AMT,
                SOHD_TOT_AMT: a.MASK_SOHD_TOT_AMT,
                DELIVERY_INSTR: a.MASK_DELIVERY_INSTR,
                BILLING_INSTRUCTION: a.MASK_BILLING_INSTRUCTION,
                PRINT_HEADING: a.MASK_PRINT_HEADING,
                PRINT_MESSAGE: null,
                DEFINED_BY: a.MASK_DEFINED_BY,
                STATUS: fullCancelToggle ? "CN" : a.MASK_STATUS,
                ENTERED_BY: auth.UserId,
                TAX_AREA_ID: a.MASK_TAX_AREA_ID,
                EVAL_BY_LOT_TAG: a.MASK_EVAL_BY_LOT_TAG,
                REMARKS: a.MASK_REMARKS,
                TRANSCHARGE_ID: a.MASK_TRANSCHARGE_ID,
                TRANSCHARGE_RATE: a.MASK_TRANSCHARGE_RATE,
                SALES_AREA_ID: a.MASK_SALES_AREA_ID,
                SHIP_SL_NO: a.MASK_SHIP_SL_NO.toString(),
                SALESMAN_PERC: a.MASK_SALESMAN_PERC,
                CBILL_CONT_FNAME: a.MASK_CBILL_CONT_FNAME,
                ESPECIE: a.MASK_ESPECIE,
                SERIE: a.MASK_SERIE,
                CIF_FOB: a.MASK_CIF_FOB,
                Param52: "000",

            // EVAL_BY_LOT_TAG: "N",
            // SHIP_SL_NO: "1",
            // CBILL_CONT_FNAME: "A",
            }));

            const salesOrderDtl = filteredSalesDetails.map((a) => ({
                COMP_ID: auth.CompId,
                DETL_ID: a.MASK_SEQUENCE!=null?a.MASK_SEQUENCE.toString():a.MASK_SEQUENCE,
                SODT_ID: a.MASK_SODT_ID,
                SOHD_ID: switchUpdate ? soHdrId : null,
                PROD_ID: a.MASK_PROD_ID,
                PROD_SMALL_DESC: a.PROD_SMALL_DESC,
                SODT_PROD_STK_TYPE:
          a.STOCK_TYPE === "Inventory" || a.STOCK_TYPE === "1" ? "1" : "2",
                SODT_PACK_UOM_CODE:
          a.MASK_PACK_UOM_CODE === null ? "EA" : a.MASK_PACK_UOM_CODE,
                SODT_TOT_ORD_QTY: a.TOT_ORD_QTY.toString(),
                SODT_TOT_REL_QTY: a.TOT_REL_QTY.toString(),
                SODT_TOT_BACK_QTY: a.MASK_TOT_BACK_QTY.toString(),
                SODT_TOT_DROP_QTY: a.MASK_TOT_DROP_QTY.toString(),
                SODT_TOT_INV_QTY: a.TOT_INV_QTY.toString(),
                SODT_CASE_UOM_CODE: a.CASE_TYPE,
                SODT_CASE_ORD_QTY: a.CASE_ORD_QTY.toString(),
                SODT_CASE_REL_QTY: a.CASE_REL_QTY.toString(),
                SODT_CASE_FACTOR: a.MASK_CASE_FACTOR.toString(),
                SODT_PROD_BATCH_NO: a.PROD_LOT_NO,
                PRICE_LIST_NO: a.PROD_LOT_NO,
                SODT_CDR_DISC: a.CDR_DISC,
                SODT_EUS_DISC_PERC: a.EUS_DISC_PERC.toString(),
                SODT_EUS_DISC: a.EUS_DISC.toString(),
                SODT_TRADE_DISC: a.MASK_TRADE_DISC.toString(),
                SODT_PROD_PRICE: a.PROD_PRICE.toString(),
                SODT_PROD_BASE_PRICE: a.BASE_PRICE.toString(),
                SODT_PROD_VAL: a.MASK_PROD_VAL,
                SODT_VAL_BASE_CRNC: a.MASK_VAL_BASE_CRNC.toString(),
                SODT_SPLCON_TAX: a.MASK_SPLCON_TAX.toString(),
                SODT_TOT_TAX: a.MASK_TOT_TAX.toString(),
                EVALUATE_BY: a.MASK_EVAL_BY_LOT_TAG,
                STATUS: "N",
                ENTERED_BY: auth.UserId,
                SODT_TOT_VAT: a.MASK_SODT_TOT_VAT.toString(),
                SODT_EUS_DISC_PERC_TAG: a.MASK_SODT_EUS_DISC_PERC_TAG,
                SOHD_SO_DATE: changeDateFormat(currentDate),
                SODT_TRANS_VAL: a.MASK_SODT_TRANS_VAL.toString(),
                SODT_CDR_DISC_PERC: a.MASK_SODT_CDR_DISC_PERC.toString(),
                SODT_CDR_DISC_PERC_TAG: a.MASK_SODT_CDR_DISC_PERC_TAG,
                SODT_PRICE_OR_TAG: a.MASK_SODT_PRICE_OR_TAG,
                SODT_UPPER_BAND: a.MASK_SODT_UPPER_BAND,
                SODT_LOWER_BAND: a.MASK_SODT_LOWER_BAND,
                SODT_PRL_PRICE: a.MASK_SODT_PRL_PRICE,
                DIVN_ID: a.MASK_DIVN_ID,
                EUS_DISC_APP_TO: a.MASK_EUS_DISC_APP_TO,
                PRODUCT_MRP: a.MASK_PRODUCT_MRP.toString(),
                IUKEY: a.MASK_IUKEY,
                OLD_STK_ONLY: "N",
                BASE_PROD_CODE: null,
                RBO_REAS_CODE: a.RBO_REAS_CODE,
            }));
            let schmData = filteredSalesDetails.map(
                (a) => ({
                    COMP_ID: auth.CompId,
                    SODT_ID: a.MASK_SODT_ID,
                    SOHD_ID: salesOrderHdr.SOHD_ID,
                    PROD_ID: a.PRODUCT_CODE,
                    PROD_SMALL_DESC: a.PROD_SMALL_DESC,
                    PROD_STK_TYPE:
          a.STOCK_TYPE === "Inventory" || a.STOCK_TYPE === "1" ? "1" : "2",
                    PACK_UOM_CODE: a.MASK_PACK_UOM_CODE,
                    TOT_ORD_QTY: a.TOT_ORD_QTY.toString(),
                    TOT_REL_QTY: a.TOT_REL_QTY.toString(),
                    TOT_BACK_QTY: a.MASK_TOT_BACK_QTY.toString(),
                    TOT_DROP_QTY: a.MASK_TOT_DROP_QTY.toString(),
                    PROD_BATCH_NO: a.PROD_LOT_NO,
                    EVALUATE_BY: a.MASK_EVAL_BY_LOT_TAG,
                    ENTERED_BY: auth.UserId,
                    EUS_DISC_PERC_TAG: "Y",
                    SO_DATE: changeDateFormat(salesOrderHdr.SO_DATE), //(dd / MM / yyyy),
                    CDR_DISC_PERC_TAG: a.MASK_SODT_CDR_DISC_PERC_TAG,
                    PRICE_OR_TAG: a.MASK_SODT_PRICE_OR_TAG,
                    PRL_PRICE: a.MASK_SODT_PRL_PRICE,
                    DIVN_ID: a.MASK_DIVN_ID,
                    SCHM_ID: ("CIL|" + a.FREE_SCHM_CODE).toString(),
                })
            // }
            );
            let stockData = filteredSalesDetails.map((a) => ({
                COMP_ID: auth.CompId,
                DIVN_ID: a.MASK_DIVN_ID,
                WH_ID: salesOrderHdr.MASK_WH_ID,
                TYPE_ID: a.STOCK_TYPE === "Inventory" || a.STOCK_TYPE === "1" ? "1" : "2",
                PROD_ID: a.MASK_PROD_ID,
                BATCH_NO: a.PROD_LOT_NO,
                ALLOCATED_QTY: (
                    Number(a.TOT_REL_QTY) - Number(a.MASK_TOT_REL_QTY)
                ).toString(),
                BASE_PROD_CODE: a.PRODUCT_CODE,
                BASE_PROD_LOT: a.BASE_PROD_LOT,
                BASE_ALLOCATED_QTY: a.BASE_PROD_ALLOC,
            }));

            let sODetailsData = filteredSalesDetails.map((a) => ({
                COMP_ID: a.COMP_ID,
                MASK_SODT_ID: a.MASK_SODT_ID,
                DIVISION: a.DIVISION,
                MASK_PROD_ID: a.MASK_PROD_ID,
                STOCK_TYPE:
        a.STOCK_TYPE === "Inventory" || a.STOCK_TYPE === "1" ? "1" : "2",
                PRODUCT_CODE: a.PRODUCT_CODE,
                PROD_SMALL_DESC: a.PROD_SMALL_DESC,
                PROD_LOT_NO: a.PROD_LOT_NO,
                PRICE_LIST_NO: a.PRICE_LIST_NO,
                CASE_TYPE: a.CASE_TYPE,
                CDR_DISC: a.CDR_DISC,
                EUS_DISC_PERC: a.EUS_DISC_PERC.toString(),
                EUS_DISC: a.EUS_DISC.toString(),
                CASE_ORD_QTY: a.CASE_ORD_QTY.toString(),
                PACK_ORD_QTY: a.PACK_ORD_QTY.toString(),
                TOT_ORD_QTY: a.TOT_ORD_QTY.toString(),
                PROD_PRICE: a.PROD_PRICE.toString(),
                BASE_PRICE: a.BASE_PRICE.toString(),
                CASE_REL_QTY: a.CASE_REL_QTY.toString(),
                PACK_REL_QTY: a.PACK_REL_QTY.toString(),
                TOT_REL_QTY: a.TOT_REL_QTY.toString(),
                TOT_INV_QTY: a.TOT_INV_QTY.toString(),
                MASK_PROD_VAL: a.MASK_PROD_VAL,
                MASK_SODT_PROD_STK_TYPE: a.MASK_SODT_PROD_STK_TYPE,
                MASK_PACK_UOM_CODE: a.MASK_PACK_UOM_CODE,
                MASK_TOT_BACK_QTY: a.MASK_TOT_BACK_QTY.toString(),
                MASK_TOT_DROP_QTY: a.MASK_TOT_DROP_QTY.toString(),
                MASK_TRADE_DISC: a.MASK_TRADE_DISC.toString(),
                MASK_VAL_BASE_CRNC: a.MASK_VAL_BASE_CRNC.toString(),
                MASK_SPLCON_TAX: a.MASK_SPLCON_TAX.toString(),
                MASK_TOT_TAX: a.MASK_TOT_TAX.toString(),
                MASK_SODT_TOT_VAT: a.MASK_SODT_TOT_VAT.toString(),
                MASK_CASE_FACTOR: a.MASK_CASE_FACTOR.toString(),
                MASK_STATUS: a.MASK_STATUS,
                // MASK_UPDATE_COUNT: a.MASK_UPDATE_COUNT,
                MASK_EXPORTED: a.MASK_EXPORTED,
                MASK_FIRST_ENTERED_BY: a.MASK_FIRST_ENTERED_BY,
                MASK_FIRST_ENTERED_ON: a.MASK_FIRST_ENTERED_ON,
                MASK_LAST_MODIFIED_BY: a.MASK_LAST_MODIFIED_BY,
                MASK_LAST_MODIFIED_ON: a.MASK_LAST_MODIFIED_ON,
                MASK_IUKEY: a.MASK_IUKEY,
                MASK_SEQUENCE: a.MASK_SEQUENCE!=null?a.MASK_SEQUENCE.toString():a.MASK_SEQUENCE,
                EXTENDED_PRICE: a.EXTENDED_PRICE.toString(),
                MASK_SODT_EUS_DISC_PERC_TAG: a.MASK_SODT_EUS_DISC_PERC_TAG,
                MASK_SODT_TRANS_VAL: a.MASK_SODT_TRANS_VAL.toString(),
                MASK_SODT_CDR_DISC_PERC: a.MASK_SODT_CDR_DISC_PERC.toString(),
                MASK_SODT_CDR_DISC_PERC_TAG: a.MASK_SODT_CDR_DISC_PERC_TAG,
                MASK_SODT_PRICE_OR_TAG: a.MASK_SODT_PRICE_OR_TAG,
                MASK_SODT_UPPER_BAND: a.MASK_SODT_UPPER_BAND,
                MASK_SODT_LOWER_BAND: a.MASK_SODT_LOWER_BAND,
                MASK_SODT_PRL_PRICE: a.MASK_SODT_PRL_PRICE,
                MASK_SOHD_ID: a.MASK_SOHD_ID,
                MASK_DIVN_ID: a.MASK_DIVN_ID,
                MASK_EVAL_BY_LOT_TAG: a.MASK_EVAL_BY_LOT_TAG,
                MASK_EUS_DISC_APP_TO: a.MASK_EUS_DISC_APP_TO,
                MASK_PRODUCT_MRP: a.MASK_PRODUCT_MRP.toString(),
                MASK_OLD_STK_ONLY: a.MASK_OLD_STK_ONLY,
                RBO_REAS_CODE: a.RBO_REAS_CODE,
                STOCK_TYP:
        a.STOCK_TYPE === "Inventory" || a.STOCK_TYPE === "1" ? "1" : "2",
                DESCRIPTION: a.DESCRIPTION,
                TOT_AVL_QTY: a.TOT_AVL_QTY,
                FREE_SCHM_CODE: a.FREE_SCHM_CODE,
                BASE_PROD_CODE: a.BASE_PROD_CODE,
                BASE_PROD_LOT: a.BASE_PROD_LOT,
                BASE_PROD_ALLOC: a.BASE_PROD_ALLOC,
                MASK_UPDATE_COUNT: a.MASK_UPDATE_COUNT.toString(),
            }));

            let obj = {
                CICode: auth.CICode,
                IUKey: "2",
                SalesOrderHdr: _salesOrderHdr,
                SalesOrderDtl: salesOrderDtl,
                SchmData: schmData,
                StockData: stockData,
                DocPayTermData: docPayTermData,
                SODetailsData: sODetailsData,
                OEMORDER: "",
            };
            console.log("Save Allocation",obj);
            // return;
            //eslint-disable-next-line
        try {
                const res = await Axios.post(INSERT_SO, JSON.stringify(obj), {
                    headers: { "Content-Type": "application/json" },
                });
                if (res.data.status == "SUCCESS" ) {
                    if (auth.DistTag == "I" && fullCancelToggle) {
                        await Axios.post(CancelDataILS_URL + "CICode=" + auth.CICode, [
                            hdrID,
                            reasonCode,
                            reasonName,
                        ]);
                        toast.success("Sales Order Has Been Fully Cancelled!", {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        });
                    } else if (fullCancelToggle) {
                        await Axios.post(CancelData_URL + "CICode=" + auth.CICode, [
                            hdrID,
                            reasonCode,
                            reasonName,
                        ]);
                        toast.success("Sales Order Has Been Fully Cancelled!", {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        });
                    } else {
                        toast.success("Sales Order Updated Successfully", {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        });
                    }
                }
                setUpdate(true);
                setAdd(true);
                setFilteredSalesDetails([]);
                setFilteredSalesDetails(res.data.DTSOData);
                setUpdateStatusCheck(true);
                setFullCancelToggle(false);
                // if (cancelFlag) {
                //     toast.success("Sales Order Updated Successfully", {
                //         position: toast.POSITION.TOP_CENTER,
                //         pauseOnFocusLoss: false,
                //         closeButton: false,
                //         autoClose: 2000,
                //     });
                // }

                if (res.data.IsSave == false) {
                    toast.error("Sales Order not UPDATED!", {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    });
                }
                setShowUpdateMsg(true);
                setSoHdrId(res.data.SOHD_ID);
                const SOStatusURL = `${SOStatus}CICode=${auth.CICode}&SOHD_ID=${res.data.SOHD_ID}`;
                const statusRes = await Axios.get(SOStatusURL);
                // console.log("SOStatus", statusRes.data.Status);
                setOrderStatus(statusRes.data.Status);
                const url = `${SALES_ORDER_URL}CICode=${auth.CICode}&strSalesOrderId=${res.data.SOHD_ID}&LangId=1`;
                const response = await Axios.get(url);
                setFilteredSalesDetails(response.data);
                let arrLen = response.data.filter((val) => val.TOT_REL_QTY > 0); //PACK_REL_QTY
                setReleaseQtyCheck(arrLen.length);
                setLoading(false);
                setInvoiceBtn(true);
                getSalesOrderDetails(salesOrderHdr);
            } catch (error) {
                setLoading(false);
                toast.error(error.response.data.Message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
                console.log("error 00:00", error);
            }
        }
    };

    // const immediateInvoke = (updatedData) => {
    //     setFilteredSalesDetails(updatedData);
    // };
    const handleCancelOrder = (e, refReason) => {
        const filterCancel = filteredSalesDetails.map((val) => {
            if (fullCancelToggle) {
                if (val.PACK_ORD_QTY != val.TOT_INV_QTY && val.MASK_STATUS != "CN") {
                    return { ...val, RBO_REAS_CODE: refReason, MASK_STATUS: "Y" }; // full order cancellation
                } else {
                    return val;
                }
            } else {
                if (
                    val.PRODUCT_CODE == productCodeCancel &&
          val.PROD_LOT_NO == lotNoCancel
                ) {
                    return { ...val, RBO_REAS_CODE: refReason, MASK_STATUS: "CN" }; // partial cancellation
                } else {
                    return val;
                }
            }
        });
        // console.log("poc000", filterCancel);
        // console.log("foc000", filterCancel);
        setFilteredSalesDetails(filterCancel);
        setFullCancelColor(true);
        // setLineLevelUpdate(true);
        // let filterReason = reasonDetails.filter((val) => val.CODE == refReason);
        // reasonName = filterReason[0].NAME;
        // reasonCode = filterReason[0].CODE;

        if (!fullCancelToggle) {
            let filterReason = reasonDetails.filter((val) => val.CODE == refReason);
            reasonName = filterReason[0].NAME;
            reasonCode = filterReason[0].CODE;
            updateSales(filterCancel);
        }
        if (fullCancelToggle) {
            let filterReason = hedaerReasonDetails?.filter((val) => val.CODE == refReason);
            reasonName = filterReason[0].NAME;
            reasonCode = filterReason[0].CODE;
            updateSales(filterCancel);
        }
    };

    const handleDeleteSales = (row) => {
    // setCancelItem(true);
        setProductCodeCancel(row.PRODUCT_CODE);
        setLotNoCancel(row.PROD_LOT_NO);
        setEditModal(true);
        setFullCancelToggle(false);
    };
    //----------------------Reallocate-------------------------------
    const handleReallocate = () => {
        setDisable(true);
        setModalShow(true);
        invoiceQty = null;
    };

    const handleFgtPwd = () => {
        setFgtModalShow(true);
    };

    // function for prevent specail character..
    const blockInvalidChar = (e) => {
        [".", "+", "-", "e", "E"].includes(e.key) && e.preventDefault();
    };
    const cancelreasons = async () => {
        const cancelDetails = `${cancelDetails_url}CICode=${auth.CICode}`;
        const reasonAll = await Axios.get(cancelDetails);
        setReasonDetails(reasonAll.data);

        const headercancelDetails = `${headerReason_URL}CICode=${auth.CICode}`;
        const headerReasonDetails = await Axios.get(headercancelDetails);
        setHedaerReasonDetails(headerReasonDetails.data);

        const defaultReson = `${DefaultVal_URL}CompId=${auth.CompId}&defId=35`;
        const defaultReasonApi = await Axios.get(defaultReson);
        const foundItem = headerReasonDetails.data.find(item => item.MASK_REASON_ID === defaultReasonApi.data.Id);

        if (foundItem) {
            setDefaultReasonData(foundItem.CODE);
        } else {
            setDefaultReasonData("Select Reason"); 
        }
        // headerReasonDetails.data.map((item)=>{
        //     if(item.MASK_REASON_ID===defaultReasonApi.data.Id){
        //         setDefaultReasonData(item.CODE);
        //         return;
        //     }
        // });
    };
    useEffect(() => {
        cancelreasons();
        // console.log("caling from Sales details context Useeffect");
    }, []);
    let CartVolume=[];
    const handleDeletecart = (row) => {

        setCartVal((current) =>
            current.filter((productDtl) => {
                return (
                    productDtl.PRODUCT_CODE + productDtl.PROD_LOT_NO !==
          row.PRODUCT_CODE + row.PROD_LOT_NO
                );
            })
        );
        CartVolume.push(cartVal.filter((productDtl) => {
            return (
                productDtl.PRODUCT_CODE + productDtl.PROD_LOT_NO !==
      row.PRODUCT_CODE + row.PROD_LOT_NO
            );
        }));
        netvol();
    };
    const netvol = async () => {
        setWeight(0);
        setVolume(0);
        let sumwt = 0;
        let sumlt = 0;
        for (let i = 0; i < CartVolume[0].length; i++) {
            const volltr = `${GetNetVolltr_URL}CICode=${auth.CICode}&strProdId=${CartVolume[0][i].MASK_PROD_ID}`;
            const volwt = `${GetNetVolwt_URL}CICode=${auth.CICode}&strProdId=${CartVolume[0][i].MASK_PROD_ID}`;
            const netwt = await Axios.get(volwt);
            const netltr = await Axios.get(volltr);
            sumwt =
        sumwt + parseFloat(netwt.data) * parseFloat(CartVolume[0][i].TOT_ORD_QTY);
            sumlt =
        sumlt + parseFloat(netltr.data) * parseFloat(CartVolume[0][i].TOT_ORD_QTY);
        }
        if(CartVolume[0].length>0){
            setWeight(0);
            setVolume(0);
        }
        setWeight(sumwt.toFixed(2));
        setVolume(sumlt.toFixed(2));
        setManualCart(CartVolume[0]);
    };
    const filterRow = (row) => {
        let cart = cartVal.filter((val) => {
            return (
                val.PRODUCT_CODE + val.PROD_LOT_NO !==
        row.PRODUCT_CODE + row.PROD_LOT_NO
            );
        });
        setDisableRelease(cart);
    };
    const filterRow1 = () => {
        if (cartVal.length !== 0) {
            setDisableRelease(cartVal);
        }
    };
    const filterRow2 = (row) => {
        let cart = filteredSalesDetails.filter((val) => {
            return (
                val.PRODUCT_CODE + val.PROD_LOT_NO !==
        row.PRODUCT_CODE + row.PROD_LOT_NO
            );
        });
        setDisableRelease(cart);
    };
    const getSalesOrderDetails = async (row) => {
        setLoading(true);
        
        if (salesOrderDtlpath) {
            try {
                // console.log("pdtrowdetails", row);
                setSalesOrderHdr(row);
                // console.log("soid000", row);
                hdrID = row.MASK_SO_ID;

                const url = `${SALES_ORDER_URL}CICode=${auth.CICode}&strSalesOrderId=${row.MASK_SO_ID}&LangId=1`;
                const credURL = `${CreditPosition_URL}CICode=${auth.CICode}&strCustId=${row.MASK_CUST_ID}&strTransType=S&strCompId=${auth.CompId}`;
                const credURL2 = `${CreditPosition_URL2}CICode=${auth.CICode}&strCustId=${row.MASK_CUST_ID}`;
                const volltr = `${GetNetVolltr_URL}CICode=${auth.CICode}&strProdId=${row.MASK_PROD_ID}`;
                const volwt = `${GetNetVolwt_URL}CICode=${auth.CICode}&strProdId=${row.MASK_PROD_ID}`;
                const summary = `${SalesOrderSummary_URL}CICode=${auth.CICode}&strSalesOrderId=${row.MASK_SO_ID}`;
                // const custinfo = `${apicalling}CICode=${auth.CICode}&strCustId=${row.MASK_CUST_ID}`;

                setFullCancelColor(false);

                //--------------------------------------------------------------------------

                const summary_res = await Axios.get(summary);
                // console.log("summary", summary_res.data);
                // const custinfo_res = await Axios.get(custinfo);
                // setCustomerinformationapi(custinfo_res.data.Table);
                // console.log("CustInfo", custinfo_res.data?.Table);
                // console.log("Customeriformation inside state", custinfo_res.data.Table);

                setSalesOrderSummary(summary_res.data);
                // console.log("tttttt",summary_res.data);
                const netwt = await Axios.get(volwt);
                setNetVolWt(netwt.data);
                const netltr = await Axios.get(volltr);
                setNetVolLtr(netltr.data);
                const credResponse2 = await Axios.get(credURL2);
                setCreditDetails2(credResponse2.data);
                const credResponse = await Axios.get(credURL);
                setCreditDetails(credResponse.data);
                const response = await Axios.get(url);
                setFilteredSalesDetails(response.data);
                // console.log("allrow000", response.data);
                // console.log("hdr000", filteredSalesDetails);
                setSalesDetails(response.data);
                setSoHdrId(row.MASK_SO_ID);
                const SOStatusURL = `${SOStatus}CICode=${auth.CICode}&SOHD_ID=${row.MASK_SO_ID}`;
                const statusRes = await Axios.get(SOStatusURL);
                setOrderStatus(statusRes.data.Status);

                let x = response.data.length;
                let sum = 0;
                let grossValue = 0;
                let AmntBfrTax = 0;
                let totAmount = 0;
                for (let i = 0; i < x; i++) {
                    grossValue = grossValue + parseFloat(response.data[i].BASE_PRICE);
                    sum = sum + parseFloat(response.data[i].PACK_ORD_QTY);
                    AmntBfrTax =
            AmntBfrTax +
            parseFloat(
                response.data[i].BASE_PRICE -
                response.data[i].CDR_DISC -
                response.data[i].EUS_DISC -
                response.data[i].MASK_TRADE_DISC
            );
                    totAmount =
            totAmount +
            parseFloat(
                response.data[i].MASK_SODT_TRANS_VAL +
                response.data[i].MASK_TOT_TAX +
                response.data[i].MASK_SODT_TOT_VAT
            );
                }
                setGross(grossValue);
                setTotalOrder(sum);
                setAmountBeforeTax(AmntBfrTax);
                setTotalAmount(totAmount);

                setFilteredSalesDetails(response.data);
                setLoading(false);
                let arrLen = response.data.filter((val) => val.TOT_REL_QTY > 0); //PACK_REL_QTY
                setReleaseQtyCheck(arrLen.length);
                setInvoiceNo(0);
                setFullCancelColor(false);
            } catch (error) {
                console.log("error 00:00", error);
            }
        } else {
            if (custInfo.length == 0){
                toast.warn("Please Select Customer", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
                setLoading(false);
            }
            else if (cartVal.length == 0){
                toast.warn("Please add Product to cart, to generate Sales Order", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
                setLoading(false);
            }
            else{
                let branchid = "000";
                let sono_response;
                const So_nogetting = `${generate_SONO}strTransactionDate=${currentDate.toString()}&strBranchId=${branchid}&strCompId=${auth.CompId}`;
                try {
                    sono_response = await Axios.get(So_nogetting);
                }
                catch(error){
                    console.log("erororor",error);
                    toast.warn(`${error.response.data.Message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    });
                    setLoading(false);
                    return;
                }
                // console.log(sono_response);
                // console.log(payTerm,"payTerm11");
                // console.log(MopId,"payTerm12");
                // return;
                let docPayTermData = [
                    {
                        COMP_ID: auth.CompId,
                        DOC_ID: switchUpdate ? soHdrId : "",
                        PAY_TERM_ID: custInfo[0].MASK_PAY_TERM_ID,
                        DOC_TYPE: "",
                        DUE_DATE: changeDateFormat(payTermDueDate),
                        TYPE: "",
                    },
                ];
                let _salesOrderHdr = custInfo.map((a) => ({
                    SOHD_ID: switchUpdate ? soHdrId : "",
                    SOHD_SO_NO: switchUpdate ? SONo : sono_response.data.SO_NO,
                    SOHD_SO_DATE: changeDateFormat(currentDate),
                    SOHD_TYPE: "R",
                    COMP_ID: auth.CompId,
                    DIVN_ID: "GEN",
                    SOHD_REQ_DEL_DATE: changeDateFormat(currentDate),
                    SOHD_PROMISED_DUE_DATE: changeDateFormat(payTermDueDate),
                    CURRENCY_ID: a.MASK_CURRENCY_ID,
                    SOHD_EXCH_RATE: exchRate,
                    SOHD_TRADE_DISC_PERC: tradeDisc ? tradeDisc.toString() : "0",
                    ORD_MODE_ID: OrdMode, //defaultVal API(defId:7)
                    MOP_ID: MopId == undefined ? a.MASK_MOP_ID : MopId,//a.MASK_MOP_ID,
                    SOHD_CREATE_EMP_ID: a.MASK_SALES_EXEC_EMP_ID, //sales exicutive
                    WH_ID: defWh.Id,
                    TAX_FORM_ID: a.MASK_TAX_FORM_ID,
                    VAN_RT_ID: a.MASK_VAN_RT_ID,
                    PAY_TERM_ID: payTerm == undefined ? a.MASK_PAY_TERM_ID : payTerm,//a.MASK_PAY_TERM_ID,
                    SOHD_CUST_PO_NO: custPONo.toString(),
                    SOHD_CUST_PO_DATE: null,
                    CUST_ID: custId[0].MASK_CUST_ID,
                    CUST_NAME: custId[0].NAME,
                    CBILL_ADD1: a.CBILL_ADD1,
                    CBILL_ADD2: a.CBILL_ADD2,
                    CBILL_ADD_GEOG_ID: a.CBILL_ADD_GEOG_ID,
                    CBILL_ADD_PIN: a.CBILL_ADD_PIN,
                    CSHIP_ADD1: a.CSHIP_ADD1,
                    CSHIP_ADD2: a.CSHIP_ADD2,
                    CSHIP_ADD_GEOG_ID: a.CSHIP_ADD_GEOG_ID,
                    CSHIP_ADD_PIN: a.CSHIP_ADD_PIN,
                    SOHD_ROUND_OFF_AMT: "0.00",
                    SOHD_TOT_BASE_CRNC_AMT: (
                        exchRate *
          (priceCalculation
              .map((a) => Number(a.BASE_PRICE))
              .reduce((a, b) => a + b) -
            priceCalculation
                .map((a) => Number(a.CDR_DISC))
                .reduce((a, b) => a + b) -
            priceCalculation
                .map((a) => Number(a.EUS_DISC))
                .reduce((a, b) => a + b) -
            priceCalculation
                .map((a) => Number(a.MASK_TRADE_DISC))
                .reduce((a, b) => a + b) +
            (priceCalculation
                .map((a) => Number(a.MASK_SODT_TRANS_VAL))
                .reduce((a, b) => a + b) +
              priceCalculation
                  .map((a) => Number(a.MASK_TOT_TAX))
                  .reduce((a, b) => a + b) +
              priceCalculation
                  .map((a) => Number(a.MASK_SODT_TOT_VAT))
                  .reduce((a, b) => a + b)))
                    ).toString(),
                    SOHD_TOT_AMT: (
                        priceCalculation
                            .map((a) => Number(a.BASE_PRICE))
                            .reduce((a, b) => a + b) -
          priceCalculation
              .map((a) => Number(a.CDR_DISC))
              .reduce((a, b) => a + b) -
          priceCalculation
              .map((a) => Number(a.EUS_DISC))
              .reduce((a, b) => a + b) -
          priceCalculation
              .map((a) => Number(a.MASK_TRADE_DISC))
              .reduce((a, b) => a + b) +
          (priceCalculation
              .map((a) => Number(a.MASK_SODT_TRANS_VAL))
              .reduce((a, b) => a + b) +
            priceCalculation
                .map((a) => Number(a.MASK_TOT_TAX))
                .reduce((a, b) => a + b) +
            priceCalculation
                .map((a) => Number(a.MASK_SODT_TOT_VAT))
                .reduce((a, b) => a + b))
                    ).toString(),
                    DELIVERY_INSTR: deliveryInstruction, //defaultVal API(defId:21)
                    BILLING_INSTRUCTION: billingInstruction, //defaultVal API(defId:20)
                    PRINT_HEADING: printHeading, //defaultVal API(defId:16)
                    PRINT_MESSAGE: null, //GetPrintMessage API
                    DEFINED_BY: auth.DefinedBy,
                    STATUS: "OP",
                    ENTERED_BY: auth.UserId,
                    TAX_AREA_ID: a.MASK_TAX_AREA_ID,
                    EVAL_BY_LOT_TAG: cartVal[0].MASK_EVAL_BY_LOT_TAG,
                    REMARKS: remarks,
                    TRANSCHARGE_ID: a.MASK_TRANS_CHARGE_ID,
                    TRANSCHARGE_RATE: ".00",
                    //   a.MASK_TRANSCHARGE_RATE,
                    SALES_AREA_ID: a.MASK_SALES_AREA_ID,
                    SHIP_SL_NO: a.MASK_CSHIP_SRL_NO,
                    SALESMAN_PERC: "0.00",
                    CBILL_CONT_FNAME: a.MASK_CBILL_CONT_FNAME,
                    ESPECIE: ESPECIE, //defaultVal API(defId:22)
                    SERIE: serie, // 1st GetBranchByWH API then GetSerieByBranch API
                    CIF_FOB: "",
                    Param52: "000",
                }));
                let salesOrderDtl = cartVal.map((a) => ({
                    COMP_ID: auth.CompId,
                    DETL_ID: a.MASK_SEQUENCE!=null?a.MASK_SEQUENCE.toString():a.MASK_SEQUENCE,
                    SODT_ID: a.MASK_SODT_ID,
                    SOHD_ID: switchUpdate ? soHdrId : null,
                    PROD_ID: a.MASK_PROD_ID,
                    PROD_SMALL_DESC: a.PROD_SMALL_DESC,
                    SODT_PROD_STK_TYPE:
          a.STOCK_TYPE === "Inventory" || a.STOCK_TYPE === "1" ? "1" : "2",
                    SODT_PACK_UOM_CODE:
          a.MASK_PACK_UOM_CODE === null ? "EA" : a.MASK_PACK_UOM_CODE,
                    SODT_TOT_ORD_QTY: a.TOT_ORD_QTY.toString(),
                    SODT_TOT_REL_QTY: a.TOT_REL_QTY.toString(),
                    SODT_TOT_BACK_QTY: a.MASK_TOT_BACK_QTY.toString(),
                    SODT_TOT_DROP_QTY: a.MASK_TOT_DROP_QTY.toString(),
                    SODT_TOT_INV_QTY: a.TOT_INV_QTY.toString(),
                    SODT_CASE_UOM_CODE: a.CASE_TYPE,
                    SODT_CASE_ORD_QTY: a.CASE_ORD_QTY.toString(),
                    SODT_CASE_REL_QTY: a.CASE_REL_QTY.toString(),
                    SODT_CASE_FACTOR: a.MASK_CASE_FACTOR.toString(),
                    SODT_PROD_BATCH_NO: a.PROD_LOT_NO,
                    PRICE_LIST_NO: a.PROD_LOT_NO,
                    SODT_CDR_DISC: a.CDR_DISC,
                    SODT_EUS_DISC_PERC: a.EUS_DISC_PERC.toString(),
                    SODT_EUS_DISC: a.EUS_DISC.toString(),
                    SODT_TRADE_DISC: a.MASK_TRADE_DISC.toString(),
                    SODT_PROD_PRICE: a.PROD_PRICE.toString(),
                    SODT_PROD_BASE_PRICE: a.BASE_PRICE.toString(),
                    SODT_PROD_VAL: a.MASK_PROD_VAL,
                    SODT_VAL_BASE_CRNC: a.MASK_VAL_BASE_CRNC.toString(),
                    SODT_SPLCON_TAX: a.MASK_SPLCON_TAX.toString(),
                    SODT_TOT_TAX: a.MASK_TOT_TAX.toString(),
                    EVALUATE_BY: a.MASK_EVAL_BY_LOT_TAG,
                    STATUS: "N",
                    ENTERED_BY: auth.UserId,
                    SODT_TOT_VAT: a.MASK_SODT_TOT_VAT.toString(),
                    SODT_EUS_DISC_PERC_TAG: a.MASK_SODT_EUS_DISC_PERC_TAG,
                    SOHD_SO_DATE: changeDateFormat(currentDate),
                    SODT_TRANS_VAL: a.MASK_SODT_TRANS_VAL.toString(),
                    SODT_CDR_DISC_PERC: a.MASK_SODT_CDR_DISC_PERC.toString(),
                    SODT_CDR_DISC_PERC_TAG: a.MASK_SODT_CDR_DISC_PERC_TAG,
                    SODT_PRICE_OR_TAG: a.MASK_SODT_PRICE_OR_TAG,
                    SODT_UPPER_BAND: a.MASK_SODT_UPPER_BAND,
                    SODT_LOWER_BAND: a.MASK_SODT_LOWER_BAND,
                    SODT_PRL_PRICE: a.MASK_SODT_PRL_PRICE,
                    DIVN_ID: a.MASK_DIVN_ID,
                    EUS_DISC_APP_TO: a.MASK_EUS_DISC_APP_TO,
                    PRODUCT_MRP: a.MASK_PRODUCT_MRP.toString(),
                    IUKEY: a.MASK_IUKEY,
                    OLD_STK_ONLY: "N",
                    BASE_PROD_CODE: null,
                    RBO_REAS_CODE: a.RBO_REAS_CODE,
                }));
                let schmData = cartVal.map((a) => ({
                    COMP_ID: auth.CompId,
                    SODT_ID: a.MASK_SODT_ID,
                    SOHD_ID: switchUpdate ? soHdrId : null,
                    PROD_ID: a.PRODUCT_CODE,
                    PROD_SMALL_DESC: a.PROD_SMALL_DESC,
                    PROD_STK_TYPE:
          a.STOCK_TYPE === "Inventory" || a.STOCK_TYPE === "1" ? "1" : "2",
                    PACK_UOM_CODE: "EA",
                    TOT_ORD_QTY: a.TOT_ORD_QTY.toString(),
                    TOT_REL_QTY: a.TOT_REL_QTY.toString(),
                    TOT_BACK_QTY: a.MASK_TOT_BACK_QTY.toString(),
                    TOT_DROP_QTY: a.MASK_TOT_DROP_QTY.toString(),
                    PROD_BATCH_NO: a.PROD_LOT_NO,
                    EVALUATE_BY: a.MASK_EVAL_BY_LOT_TAG,
                    ENTERED_BY: auth.UserId,
                    EUS_DISC_PERC_TAG: "Y",
                    SO_DATE: changeDateFormat(currentDate),
                    CDR_DISC_PERC_TAG: a.MASK_SODT_CDR_DISC_PERC_TAG,
                    PRICE_OR_TAG: a.MASK_SODT_PRICE_OR_TAG,
                    PRL_PRICE: a.MASK_SODT_PRL_PRICE,
                    DIVN_ID: a.MASK_DIVN_ID,
                    SCHM_ID: ("CIL|" + a.FREE_SCHM_CODE).toString(),
                }));
                let stockData = cartVal.map((a) => ({
                    COMP_ID: auth.CompId,
                    DIVN_ID: a.MASK_DIVN_ID,
                    WH_ID: defWh.Id,
                    TYPE_ID:
          a.STOCK_TYPE === "Inventory" || a.STOCK_TYPE === "1" ? "1" : "2", // ! if inventory then 1 else 2,
                    PROD_ID: a.MASK_PROD_ID,
                    BATCH_NO: a.PROD_LOT_NO,
                    ALLOCATED_QTY: (
                        Number(a.TOT_REL_QTY) - Number(a.MASK_TOT_REL_QTY)
                    ).toString(),
                    BASE_PROD_CODE: a.PRODUCT_CODE,
                    BASE_PROD_LOT: a.BASE_PROD_LOT,
                    BASE_ALLOCATED_QTY: a.BASE_PROD_ALLOC,
                }));
                let sODetailsData = cartVal.map((a) => ({
                    COMP_ID: a.COMP_ID,
                    MASK_SODT_ID: a.MASK_SODT_ID,
                    DIVISION: a.DIVISION,
                    MASK_PROD_ID: a.MASK_PROD_ID,
                    STOCK_TYPE:
        a.STOCK_TYPE === "Inventory" || a.STOCK_TYPE === "1" ? "1" : "2",
                    PRODUCT_CODE: a.PRODUCT_CODE,
                    PROD_SMALL_DESC: a.PROD_SMALL_DESC,
                    PROD_LOT_NO: a.PROD_LOT_NO,
                    PRICE_LIST_NO: a.PRICE_LIST_NO,
                    CASE_TYPE: a.CASE_TYPE,
                    CDR_DISC: a.CDR_DISC,
                    EUS_DISC_PERC: a.EUS_DISC_PERC.toString(),
                    EUS_DISC: a.EUS_DISC.toString(),
                    CASE_ORD_QTY: a.CASE_ORD_QTY.toString(),
                    PACK_ORD_QTY: a.PACK_ORD_QTY.toString(),
                    TOT_ORD_QTY: a.TOT_ORD_QTY.toString(),
                    PROD_PRICE: a.PROD_PRICE.toString(),
                    BASE_PRICE: a.BASE_PRICE.toString(),
                    CASE_REL_QTY: a.CASE_REL_QTY.toString(),
                    PACK_REL_QTY: a.PACK_REL_QTY.toString(),
                    TOT_REL_QTY: a.TOT_REL_QTY.toString(),
                    TOT_INV_QTY: a.TOT_INV_QTY.toString(),
                    MASK_PROD_VAL: a.MASK_PROD_VAL,
                    MASK_SODT_PROD_STK_TYPE: a.MASK_SODT_PROD_STK_TYPE,
                    MASK_PACK_UOM_CODE: a.MASK_PACK_UOM_CODE,
                    MASK_TOT_BACK_QTY: a.MASK_TOT_BACK_QTY.toString(),
                    MASK_TOT_DROP_QTY: a.MASK_TOT_DROP_QTY.toString(),
                    MASK_TRADE_DISC: a.MASK_TRADE_DISC.toString(),
                    MASK_VAL_BASE_CRNC: a.MASK_VAL_BASE_CRNC.toString(),
                    MASK_SPLCON_TAX: a.MASK_SPLCON_TAX.toString(),
                    MASK_TOT_TAX: a.MASK_TOT_TAX.toString(),
                    MASK_SODT_TOT_VAT: a.MASK_SODT_TOT_VAT.toString(),
                    MASK_CASE_FACTOR: a.MASK_CASE_FACTOR.toString(),
                    MASK_STATUS: a.MASK_STATUS,
                    // MASK_UPDATE_COUNT: a.MASK_UPDATE_COUNT,
                    MASK_EXPORTED: a.MASK_EXPORTED,
                    MASK_FIRST_ENTERED_BY: a.MASK_FIRST_ENTERED_BY,
                    MASK_FIRST_ENTERED_ON: a.MASK_FIRST_ENTERED_ON,
                    MASK_LAST_MODIFIED_BY: a.MASK_LAST_MODIFIED_BY,
                    MASK_LAST_MODIFIED_ON: a.MASK_LAST_MODIFIED_ON,
                    MASK_IUKEY: a.MASK_IUKEY,
                    MASK_SEQUENCE: a.MASK_SEQUENCE!=null?a.MASK_SEQUENCE.toString():a.MASK_SEQUENCE,
                    EXTENDED_PRICE: a.EXTENDED_PRICE.toString(),
                    MASK_SODT_EUS_DISC_PERC_TAG: a.MASK_SODT_EUS_DISC_PERC_TAG,
                    MASK_SODT_TRANS_VAL: a.MASK_SODT_TRANS_VAL.toString(),
                    MASK_SODT_CDR_DISC_PERC: a.MASK_SODT_CDR_DISC_PERC.toString(),
                    MASK_SODT_CDR_DISC_PERC_TAG: a.MASK_SODT_CDR_DISC_PERC_TAG,
                    MASK_SODT_PRICE_OR_TAG: a.MASK_SODT_PRICE_OR_TAG,
                    MASK_SODT_UPPER_BAND: a.MASK_SODT_UPPER_BAND,
                    MASK_SODT_LOWER_BAND: a.MASK_SODT_LOWER_BAND,
                    MASK_SODT_PRL_PRICE: a.MASK_SODT_PRL_PRICE,
                    MASK_SOHD_ID: a.MASK_SOHD_ID,
                    MASK_DIVN_ID: a.MASK_DIVN_ID,
                    MASK_EVAL_BY_LOT_TAG: a.MASK_EVAL_BY_LOT_TAG,
                    MASK_EUS_DISC_APP_TO: a.MASK_EUS_DISC_APP_TO,
                    MASK_PRODUCT_MRP: a.MASK_PRODUCT_MRP.toString(),
                    MASK_OLD_STK_ONLY: a.MASK_OLD_STK_ONLY,
                    RBO_REAS_CODE: a.RBO_REAS_CODE,
                    STOCK_TYP:
        a.STOCK_TYPE === "Inventory" || a.STOCK_TYPE === "1" ? "1" : "2",
                    DESCRIPTION: a.DESCRIPTION,
                    TOT_AVL_QTY: a.TOT_AVL_QTY,
                    FREE_SCHM_CODE: a.FREE_SCHM_CODE,
                    BASE_PROD_CODE: a.BASE_PROD_CODE,
                    BASE_PROD_LOT: a.BASE_PROD_LOT,
                    BASE_PROD_ALLOC: a.BASE_PROD_ALLOC,
                    MASK_UPDATE_COUNT: a.MASK_UPDATE_COUNT.toString(),
                }));
                let obj = {
                    CICode: auth.CICode,
                    IUKey: switchUpdate ? "2" : "1",
                    SalesOrderHdr: _salesOrderHdr,
                    SalesOrderDtl: salesOrderDtl,
                    SchmData: schmData,
                    StockData: stockData,
                    DocPayTermData: docPayTermData,
                    SODetailsData: sODetailsData,
                    OEMORDER: "",
                };
                const res = await Axios.post(INSERT_SO, JSON.stringify(obj), {
                    headers: { "Content-Type": "application/json" },
                });
                console.log("updateresponse", res.data);
                setInvoiceBtn(true);
                //   setLoading(false);
                toast.success("Sales Order Created Successfully", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
                setMopId();
                setPayTerm();
                hdrID = res.data.SOHD_ID;
                setCartVal(sODetailsData);
                setFilteredSalesDetails(sODetailsData);
                setSoHdrId(res.data.SOHD_ID);
                setSONo(res.data.SOHD_SO_NO);
                setUpdateStatusCheck(true);
                setPayload(false);
                setPriceCalculation([]);
                const SOStatusURL = `${SOStatus}CICode=${auth.CICode}&SOHD_ID=${res.data.SOHD_ID}`;
                const statusRes = await Axios.get(SOStatusURL);
                setOrderStatus(statusRes.data.Status);
                nav(`/salesmanagement/salesorder/details?${encodeURIComponent(res.data.SOHD_SO_NO)}`);
                // console.log("manualSOHdr", soHdrId);
                try {
                // console.log("pdtrowdetails", row);
                    const currentDate = format(new Date(), "dd/MM/yyyy");
                    const date = subDays(new Date(), 60);
                    const sevenDays = format(date, "dd/MM/yyyy");
                    const Sales_url = `${Base_SALES_URL}CICode=${auth.CICode}&FromDate=${sevenDays}&ToDate=${currentDate}&Tag=N&LangId=1&UserId=${auth.UserId}&CompId=${auth.CompId}`;
                    const response1 = await Axios.get(Sales_url);
                    console.log(
                        "pdtrowdetails",
                        response1.data.filter((val) => val.MASK_SO_ID === res.data.SOHD_ID)
                    );
                    const salesHdr = response1.data.filter(
                        (val) => val.MASK_SO_ID === res.data.SOHD_ID
                    );
                    setSalesOrderHdr(salesHdr[0]);
                    setSelectedSalesOder(salesHdr);
                    const url = `${SALES_ORDER_URL}CICode=${auth.CICode}&strSalesOrderId=${res.data.SOHD_ID}&LangId=1`;
                    const credURL = `${CreditPosition_URL}CICode=${auth.CICode}&strCustId=${custId[0].MASK_CUST_ID}&strTransType=S`;
                    const credURL2 = `${CreditPosition_URL2}CICode=${auth.CICode}&strCustId=${custId[0].MASK_CUST_ID}`;
                    const summary = `${SalesOrderSummary_URL}CICode=${auth.CICode}&strSalesOrderId=${res.data.SOHD_ID}`;
                    const summary_res = await Axios.get(summary);
                    console.log("summary", summary_res.data);
                    setSalesOrderSummary(summary_res.data);
                    let sumwt = 0;
                    let sumlt = 0;
                    for (let i = 0; i < cartVal.length; i++) {
                        const volltr = `${GetNetVolltr_URL}CICode=${auth.CICode}&strProdId=${cartVal[i].MASK_PROD_ID}`;
                        const volwt = `${GetNetVolwt_URL}CICode=${auth.CICode}&strProdId=${cartVal[i].MASK_PROD_ID}`;
                        const netwt = await Axios.get(volwt);
                        const netltr = await Axios.get(volltr);
                        sumwt =
            sumwt + parseFloat(netwt.data) * parseFloat(cartVal[i].TOT_ORD_QTY);
                        sumlt =
            sumlt +
            parseFloat(netltr.data) * parseFloat(cartVal[i].TOT_ORD_QTY);
                    }

                    setFullCancelColor(false);

                    //--------------------------------------------------------------------------
                    setNetVolWt(sumwt);
                    setNetVolLtr(sumlt);
                    const credResponse2 = await Axios.get(credURL2);
                    setCreditDetails2(credResponse2.data);
                    const credResponse = await Axios.get(credURL);
                    setCreditDetails(credResponse.data);
                    const response = await Axios.get(url);
                    setFilteredSalesDetails(response.data);
                    // console.log("allrow000", response.data);
                    // console.log("hdr000", filteredSalesDetails);
                    setSalesDetails(response.data);

                    let x = response.data.length;
                    let sum = 0;
                    let grossValue = 0;
                    let AmntBfrTax = 0;
                    let totAmount = 0;
                    for (let i = 0; i < x; i++) {
                        grossValue = grossValue + parseFloat(response.data[i].BASE_PRICE);
                        sum = sum + parseFloat(response.data[i].PACK_ORD_QTY);
                        AmntBfrTax =
            AmntBfrTax +
            parseFloat(
                response.data[i].BASE_PRICE -
                response.data[i].CDR_DISC -
                response.data[i].EUS_DISC -
                response.data[i].MASK_TRADE_DISC
            );
                        totAmount =
            totAmount +
            parseFloat(
                response.data[i].MASK_SODT_TRANS_VAL +
                response.data[i].MASK_TOT_TAX +
                response.data[i].MASK_SODT_TOT_VAT
            );
                    }
                    setGross(grossValue);
                    setTotalOrder(sum);
                    setAmountBeforeTax(AmntBfrTax);
                    setTotalAmount(totAmount);
                    setFilteredSalesDetails(response.data);
                    setLoading(false);
                    let arrLen = response.data.filter((val) => val.TOT_REL_QTY > 0); //PACK_REL_QTY
                    setReleaseQtyCheck(arrLen.length);
                    setInvoiceNo(0);
                    setFullCancelColor(false);
                    setSave(true);
                } catch (error) {
                    console.log("error 00:00", error);
                }
            }
        }
    };

    return (
        <SalesDetailsContext.Provider
            value={{
                getSalesOrderDetails,
                handleDeleteSales,
                filteredSalesDetails,
                setFilteredSalesDetails,
                loading,
                showMsg,
                showUpdateMsg,
                updateSales,
                setDisable,
                disable,
                productName,
                productCode,
                setProductName,
                setProductCode,
                orderQty,
                setOrderQty,
                lotNo,
                setLotNo,
                productPrice,
                setProductPrice,
                availableQty,
                setAvailableQty,
                reallocateToggle,
                setReallocateToggle,
                // handleUpdate,
                handleReallocate,
                handleFgtPwd,
                modalShow,
                setModalShow,
                fgtModalShow,
                setFgtModalShow,
                // handleAddToCart,
                disableAutoAllocate,
                setDisableAutoAllocate,
                setCreditDetails,
                creditDetails,
                setCreditDetails2,
                creditDetails2,
                setCancel,
                cancel,
                setSalesDetails,
                salesDetails,
                setNetVolLtr,
                netVolLtr,
                setNetVolWt,
                netVolWt,
                setTotalOrder,
                totalOrder,
                setAmountBeforeTax,
                amountBeforeTax,
                totalAmount,
                setShowMsg,
                setShowUpdateMsg,
                // InvoicedQty,
                // stockAvailable,
                // setModalShow,
                invoiceCall,
                invoiceQty,
                editModal,
                setEditModal,
                handleCancelOrder,
                refReason,
                setRefReason,
                // handleEditCancel,
                blockInvalidChar,
                gross,
                salesOrderHdr,
                invoicePopup,
                setInvoicePopup,
                printInvoiceModalData,
                releaseQtyCheck,
                updateStatusCheck,
                setUpdateStatusCheck,
                // setAutoAllocateData,
                setLoading,
                setAdd,
                add,
                setUpdate,
                update,
                invoice,
                setInvoice,
                setInvoiceNo,
                invoiceNo,
                reasonDetails,
                setFullCancelToggle,
                fullCancelToggle,
                fullCancelColor,
                setFullCancelColor,
                userReasonSelected,
                setUserReasonSelected,
                setCustId,
                custId,
                payload,
                setPayload,
                soHdrId,
                setSoHdrId,
                switchUpdate,
                setSwitchUpdate,
                setSalesOrderDtlpath,
                cartVal,
                setCartVal,
                SONo,
                setSONo,
                custInfo,
                setCustInfo,
                exchRate,
                setExchRate,
                priceCalculation,
                setPriceCalculation,
                payTermDueDate,
                setPayTermDueDate,
                defWh,
                setDefWh,
                printMessage,
                setPrintMessage,
                remarks,
                setRemarks,
                serie,
                setSerie,
                OrdMode,
                setOrdMode,
                deliveryInstruction,
                setDeliveryInstruction,
                billingInstruction,
                setBillingInstruction,
                printHeading,
                setPrintHeading,
                ESPECIE,
                setESPECIE,
                orderSummary,
                setOrderSummary,
                salesOrderSummary,
                reason,
                setReason,
                handleDeletecart,
                customer,
                setCustomer,
                product,
                setProduct,
                addToCart,
                setAddToCart,
                save,
                setSave,
                manualSOLoader,
                setManualSOLoader,
                invoiceBtn,
                setInvoiceBtn,
                currentDate,
                filterRow,
                filterRow1,
                disableRelease,
                orderSatus,
                setOrderStatus,
                tradeDisc,
                setTradeDisc,
                custPONo,
                setCustPONo,
                filterRow2,
                setpoptobeshow,
                poptobeshow,
                PopulateCrdNote,
                isPrintInvoice,
                setIsPrintInvoice,
                PopulateCreditNoteFunc,
                AdjustCreditNoteFunc,
                setPrintInvoiceModalData,
                volume,
                weight,
                setVolume,
                setWeight,netvol,
                manualCart,
                setDisableRelease,
                payTerm, 
                setPayTerm,
                MopId, 
                setMopId,
                passExpCaution,
                setpassExpCaution,
                hedaerReasonDetails,
                defaultReasonData,
                setDefaultReasonData,
                setHedaerReasonDetails,
                cancelreasons,
                cancelFullorderModal, 
                setCancelFullorderModal,
                kycdisabled, setKycdisabled
            }}
        >
            {children}
        </SalesDetailsContext.Provider>
    );
};
export const useGlobalContext1 = () => {
    return useContext(SalesDetailsContext);
};

export { SalesDetailsProvider, SalesDetailsContext };
