import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import Loader from "../../Components/Loader";
import { Column } from "primereact/column";
import * as Fa from "react-icons/fa";
import { toast } from "react-toastify";
import { InputText } from "primereact/inputtext";
import {
    createAPIEndpointGRNNonINV,
    Endpoint,
} from "../../Services/GRNNonINV/GrnNonInvService";
import storage from "../../Services/Storage";
import { format } from "date-fns";
import {
    createAPIEndpointPurchaseReturn,
    EndpointPurchaseReturn,
} from "../../Services/PurchaseReturn/PurchaseReturnService";

function ViewGRNnonINV() {
    const [savechecked, setSavechecked] = useState(false);
    const [grnnumber, setGrnnumber] = useState("");
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const [grnDetailsData, setGrnDetailsData] = useState([]);
    const [afterSaveddt, setAfterSaveddt] = useState([]);
    const [grnProdDetailsData, setGrnProdDetailsData] = useState();
    const [paytermtype, setPaytermtype] = useState("");
    const [paytermdt, setPaytermdt] = useState("");
    const auth = storage.getLocal("user");
    let grnId = searchParams.get("grnNonInvId");
    let status = searchParams.get("status");

    useEffect(() => {
        if (grnId) {
            if (status == "GRN") {
                getgitgrndeats(grnId);
            } else {
                grnDetailsPageLoad(grnId);
            }
        }
    }, []);

    const getgitgrndeats = (filteredgrn) => {
        setLoading(true);
        createAPIEndpointGRNNonINV()
            .get(
                `${Endpoint.GetGRNDataId}?CompId=${auth.CompId}&UserID=${auth.UserId}&GrnGitNo=${filteredgrn}`
            )
            .then((response) => {
                setGrnDetailsData(response?.data[0]);
                getProductDetailsAll(
                    response?.data[0]?.MASK_GRN_ID,
                    response?.data[0]?.IMPORT_TYPE,
                    response?.data[0]?.IMPORT_ID
                );
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };

    const grnDetailsPageLoad = (grnId) => {
        setLoading(true);
        createAPIEndpointGRNNonINV()
            .get(
                `${Endpoint.GetGRNDataId}?CompId=${auth.CompId}&UserID=${auth.UserId}&GrnGitNo=${grnId}`
            )
            .then((response) => {
                setGrnDetailsData(response?.data[0]);
                suppilerById(
                    response?.data[0]?.MASK_SUPP_ID,
                    response?.data[0]?.MASK_GRN_SUPP_INV_DATE
                );
                getProductDetailsAll(
                    response?.data[0]?.MASK_GRN_ID,
                    response?.data[0]?.IMPORT_TYPE,
                    response?.data[0]?.IMPORT_ID
                );
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };

    const suppilerById = (filteredsuppId, dtPaymentDate) => {
        createAPIEndpointPurchaseReturn()
            .get(`${EndpointPurchaseReturn.GetSupplier}?CompId=${auth.CompId}`)
            .then((res) => {
                let getsuppid = res.data.Data;
                let suppId = getsuppid.filter(
                    (item) => item.MASK_SUPP_ID == filteredsuppId
                );
                suppilerdataIdByID(suppId, dtPaymentDate);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const suppilerdataIdByID = (suppid, dtPaymentDate) => {
        setLoading(true);
        createAPIEndpointGRNNonINV()
            .get(
                `${Endpoint.GetSupplierData}?CompId=${auth.CompId}&SuppId=${suppid[0].MASK_SUPP_ID}`
            )
            .then((res) => {
                setPaytermtype(res?.data[0]?.MASK_PAY_TERM_TYPE);
                if (res?.data[0]?.MASK_PAY_TERM_ID != "") {
                    if (res?.data[0]?.MASK_PAY_TERM_TYPE == "N") {
                        paymentdtcall(
                            res?.data[0].MASK_PAY_TERM_ID,
                            res?.data[0].MASK_PAY_TERM_TAG,
                            dtPaymentDate
                        );
                        setLoading(false);
                    } else {
                        setPaytermdt("");
                        setLoading(false);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const paymentdtcall = (strPaymentId, strPayTermTag, dtPaymentDate) => {
        setLoading(true);
        createAPIEndpointGRNNonINV()
            .get(
                `${Endpoint.GetPaymentDate}?strPaymentId=${strPaymentId}&strPaymentTag=${strPayTermTag}&dtGrnDate=${dtPaymentDate}`
            )
            .then((res) => {
                setPaytermdt(res.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const getProductDetailsAll = (strGRNHdrId, ImportType, ImportId) => {
        setLoading(true);
        createAPIEndpointGRNNonINV()
            .get(
                `${Endpoint.DesignDetailGrid}?strGRNHdrId=${strGRNHdrId}&ImportType=${ImportType}&ImportId=${ImportId}&CompId=${auth.CompId}`
            )
            .then((response) => {
                setGrnProdDetailsData(response.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };

    const onAmountChange = (val, rowData) => {
        const updatedData = grnProdDetailsData.map((item) => {
            if (item.PROD_CODE === rowData.PROD_CODE) {
                return { ...item, RECIEVE_QTY: val.toString() };
            }
            return item;
        });
        setGrnProdDetailsData(updatedData);
    };

    let checkReceiveQuantity = (objects) => {
        let errors = [];
        objects.forEach((obj) => {
            var numberRegex = /^[+]?\d+$/;
            let receiveQty = parseInt(obj.RECIEVE_QTY ?? 0, 10);
            let packRecvQty = parseInt(obj.GRND_PACK_RECV_QTY, 10);
            if (receiveQty == 0) {
                errors.push({
                    productCode: obj.proD_CODE,
                    errorMessage: "Received Quantity can't be left blank",
                });
            } else if (!numberRegex.test(obj?.RECIEVE_QTY)) {
                errors.push({
                    productCode: obj.proD_CODE,
                    errorMessage: "please give input into a correct fomat",
                });
            } else if (receiveQty > packRecvQty) {
                errors.push({
                    productCode: obj.proD_CODE,
                    errorMessage: `Received Quantity for "${obj.PROD_SMALL_DESC}" is greater than Order Quantity`,
                });
            }
        });

        return errors;
    };

    const saveGRNNonInv = () => {
        let deatilsobjdata = {
            suppInvNo: grnDetailsData.MASK_GRN_SUPP_INV_NO,
            taxFormId: grnDetailsData.MASK_TAX_FORM_ID,
            grnNo: grnDetailsData.GRN_NO,
            locId: grnDetailsData.MASK_LOC_ID,
            imporT_TYPE: grnDetailsData.IMPORT_TYPE,
            imporT_ID: grnDetailsData.IMPORT_ID,
            suppInvDate: grnDetailsData.MASK_GRN_SUPP_INV_DATE,
            strPayTermId: grnDetailsData.MASK_PAY_TERM_ID,
            strWarehouseId: grnDetailsData.MASK_WH_ID,
            strRecvEmpId: grnDetailsData.MASK_GRN_RECV_EMP_ID,
            txtBaseCurrAmt: null, //decimal
            txtTotAmt: "0",
            txtSuppInvAmt: "0",
            strSupplierId: grnDetailsData.MASK_SUPP_ID, //
            txtAdd1: grnDetailsData.MASK_ADD1,
            txtAdd2: grnDetailsData.MASK_ADD2,
            txtAdd3: grnDetailsData.MASK_ADD3,
            txtAdd4: grnDetailsData.MASK_ADD4,
            txtPinCode: grnDetailsData.MASK_PIN,
            // dtRecvDate: grnDetailsData.MASK_GRN_RECV_DATE ?? "",
            dtRecvDate: format(new Date(), "dd/MM/yyyy"),
            strInspEmpId: grnDetailsData.MASK_GRN_INSP_EMP_ID,
            dtInspDate: format(new Date(), "dd/MM/yyyy"),
            strGLTag: "N",
            txtPassNo: grnDetailsData.MASK_GRN_GATE_PASS_NO,
            strCurrencyId: grnDetailsData.MASK_CURRENCY_ID,
            txtExchRate: grnDetailsData.MASK_EXCH_RATE.toString(),
            txtNetAmount: null, //double
            txtTax: null, //decimal
            txtTC: null, //dcimal
            txtOtherCharges: null, //deciaml
            txtTotalAmount: null, //deciaml
            txtTotProdLvlDiscount: null, //deciaml
            txtMOPDiscVal: null, //decimal
            txtTotTradeDiscount: null, //decimal
            txtTotSplConTax: null, //decimal
            strGeogId: grnDetailsData.MASK_GEOG_ID,
            strDivnId: grnDetailsData.MASK_DIVN_ID,
            dtPaymentDate: paytermdt,
            strSummaryTag: grnDetailsData.MASK_SUMMARY_PROC_TAG,
            txtSuppName: grnDetailsData.SUPP_NAME,
            txtTradeDiscPerc: grnDetailsData.MASK_GRN_TRADE_DISC_PERC ?? "0",
            strMOPId: grnDetailsData.MASK_MOP_ID,
            strTaxAreaId: grnDetailsData.MASK_TAX_AREA_ID,
            strOriGrnNo: "",
            strDivBatchTag: grnDetailsData.MASK_EVALUATE_TAG,
            strPurAccId: grnDetailsData.MASK_GRN_PUR_ACCT,
            strPurDiscAccId: grnDetailsData.MASK_GRN_PUR_DISCOUNT_ACCT,
            strRoundOffAccId: grnDetailsData.MASK_GRN_ROUND_OFF_ACCT,
            txtMOPDiscount: grnDetailsData.MASK_PUR_DISC_PERC,
            strBranchId: "000",
            strPayTermType: paytermtype, //supplier id  need to add from api call
            txtEspecie: grnDetailsData.MASK_SERIE,
            txtSerie: grnDetailsData.MASK_SERIE,
            strInvAutoGen: "N",
            txtNetTax: null, //Decimal
            txtNetVat: null, //double
            txtNetVolume: null, //double
        };
        let proddetslData = grnProdDetailsData.map((item) => ({
            pohD_NO: item.POHD_NO ?? "",
            pohD_DATE: item.POHD_DATE ?? "",
            proD_CODE: item.PROD_CODE ?? "",
            proD_SMALL_DESC: item.PROD_SMALL_DESC ?? "",
            proD_BATCH_NO: item.PROD_BATCH_NO ?? "",
            pricE_LIST_NO: item.PRICE_LIST_NO ?? "",
            cdR_DISC: item.CDR_DISC ?? "",
            grnD_CASE_UOM_CODE: item.GRND_CASE_UOM_CODE ?? "",
            casE_FACTOR: item.CASE_FACTOR ?? "",
            grnD_CASE_RECV_QTY: item.GRND_CASE_RECV_QTY ?? "",
            grnD_PACK_RECV_QTY: item.GRND_PACK_RECV_QTY.toString() ?? "",
            grnD_PROD_PRICE: item.GRND_PROD_PRICE ?? "",
            grnD_PROD_BASE_PRICE: item.GRND_PROD_BASE_PRICE ?? "",
            toT_ORD_QTY: item.TOT_ORD_QTY ?? "",
            toT_RECV_QTY: item.TOT_RECV_QTY ?? "",
            grnD_SPLCON_TAX: item.GRND_SPLCON_TAX ?? "",
            extenteD_PRICE: item.EXTENTED_PRICE ?? "",
            masK_GRND_PROD_VAL: item.MASK_GRND_PROD_VAL ?? "",
            masK_GRND_PODT_ID: item.MASK_GRND_PODT_ID ?? "",
            masK_PROD_ID: item.MASK_PROD_ID ?? "",
            masK_PROD_DESC: item.MASK_PROD_DESC ?? "",
            masK_PACK_UOM_CODE: item.MASK_PACK_UOM_CODE ?? "",
            masK_GRND_TOT_TAX: item.MASK_GRND_TOT_TAX ?? "",
            masK_GRND_TRADE_DISC: item.MASK_GRND_TRADE_DISC ?? "",
            masK_GRND_VAL_BASE_CRNC: item.MASK_GRND_VAL_BASE_CRNC ?? "",
            masK_SEQUENCE: item.MASK_SEQUENCE ?? "",
            masK_DROP_QTY: item.MASK_DROP_QTY ?? "",
            masK_TO_RECV_QTY: item.MASK_TO_RECV_QTY ?? "",
            masK_OTHER_CHARGES: item.MASK_OTHER_CHARGES ?? "",
            masK_GROSS_WEIGHT: item.MASK_GROSS_WEIGHT ?? "",
            masK_TOT_RECV_QTY: item.MASK_TOT_RECV_QTY ?? "",
            masK_FREIGHT_CHARGES: item.MASK_FREIGHT_CHARGES ?? "",
            masK_STCK_OTHER_CHARGE: item.MASK_STCK_OTHER_CHARGE ?? "",
            masK_STCK_FREIGHT_CHARGE: item.MASK_STCK_FREIGHT_CHARGE ?? "",
            masK_PRICE_CALCULATED: item.MASK_PRICE_CALCULATED ?? "",
            masK_SUBSTITUTE_PROD: item.MASK_SUBSTITUTE_PROD ?? "",
            masK_GRND_ID: item.MASK_GRND_ID ?? "",
            masK_GRND_TOT_VAT: item.MASK_GRND_TOT_VAT ?? "",
            masK_GRND_MOP_DISC: item.MASK_GRND_MOP_DISC ?? "",
            masK_GRND_SCHM_PROCESS_TAG: item.MASK_GRND_SCHM_PROCESS_TAG ?? "",
            masK_GRND_CDR_DISC_PERC: item.MASK_GRND_CDR_DISC_PERC ?? "",
            masK_GRND_CDR_DISC_TAG: item.MASK_GRND_CDR_DISC_TAG ?? "",
            masK_PODT_PRICE_OR_TAG: item.MASK_PODT_PRICE_OR_TAG ?? "",
            masK_CDR_DISC_APP_TO: item.MASK_CDR_DISC_APP_TO ?? "",
            masK_CFOP_ID: item.MASK_CFOP_ID ?? "",
            cfoP_CODE: item.CFOP_CODE ?? "",
            masK_GRND_STK_TYPE_ID: item.MASK_GRND_STK_TYPE_ID ?? "",
            cfoP_DESCRIPTION: item.CFOP_DESCRIPTION ?? "",
            masK_CONVERSION_FACTOR: item.MASK_CONVERSION_FACTOR ?? "",
            masK_MAPPED_PROD_BULK_TAG: item.MASK_MAPPED_PROD_BULK_TAG ?? "",
            masK_MAPPED_PROD_BULK: item.MASK_MAPPED_PROD_BULK ?? "",
            STATUS: item.STATUS ?? "",
            masK_PRICE_LIST_ID: item.MASK_PRICE_LIST_ID ?? "",
            proD_ID: item.PROD_ID ?? "",
            qty: item.QTY ?? "",
            RECIEVE_QTY: item.RECIEVE_QTY,
        }));

        let errors = checkReceiveQuantity(grnProdDetailsData);

        if (errors.length > 0) {
            errors.forEach((error) => {
                toast.warn(`${error.errorMessage}`, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });
            });
        } else {
            let savePayload = {
                UserId: auth.UserId,
                CompId: auth.CompId,
                IUKey: "1",
                objHdr: deatilsobjdata,
                objDetl: proddetslData,
            };
            // console.log(savePayload);
            callingSaveApi(savePayload);
        }
    };

    const callingSaveApi = (payload) => {
        setLoading(true);
        createAPIEndpointGRNNonINV()
            .post(`${Endpoint.SaveNonInvGRN}`, JSON.stringify(payload), {
                headers: { "Content-Type": "application/json" },
            })
            .then((res) => {
                let grnnumber = res.data.Message.split(":")[1];
                setGrnnumber(grnnumber);
                setAfterSaveddt(payload.objDetl);
                toast.success(`${res.data.Message}`, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });
                setSavechecked(true);
                setLoading(false);
            })
            .catch((error) => {
                toast.warn(`${error.response.data.Message}`, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });

                setLoading(false);
            });
    };

    if (loading) {
        return (
            <>
                <Loader />
            </>
        );
    } else {
        return (
            <div>
                <div className="col-12 pt-3 pb-3">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mx-1">
                                    <li className="breadcrumb-item">
                                        <Link to="/purchasemanagement/goodsreceiptNoninventory">
                      GRN Non INV
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                    Details
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="float-end">
                                <Link to="/purchasemanagement/goodsreceiptNoninventory">
                                    <button type="submit" className="btn btn-primary">
                                        <Fa.FaLongArrowAltLeft className="backIcon" />
                    Back to All Records
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <section className="sales-box">
                        <h4>Non Inventory GRN Details</h4>
                        <form>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    {savechecked ? (
                                        <div className="mb-3">
                                            <label className="form-label">GRN Number</label>
                                            <input
                                                type="Text"
                                                disabled
                                                value={grnnumber}
                                                className="form-control"
                                            />
                                        </div>
                                    ) : (
                                        <div className="mb-3">
                                            <label className="form-label">
                                                {`${grnDetailsData?.STATUS} Number`}{" "}
                                            </label>
                                            <input
                                                type="Text"
                                                disabled
                                                value={grnDetailsData?.GRN_NO}
                                                className="form-control"
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label">
                                            {" "}
                                            {savechecked
                                                ? "GRN Date"
                                                : `${grnDetailsData?.STATUS} Date`}{" "}
                                        </label>
                                        <input
                                            type="Text"
                                            disabled
                                            value={grnDetailsData?.GRN_DATE}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label">Warehouse</label>
                                        <input
                                            type="Text"
                                            disabled
                                            value={grnDetailsData?.MASK_WH_DESC}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label">Supplier Name</label>
                                        <input
                                            type="Text"
                                            disabled
                                            value={grnDetailsData?.SUPP_NAME}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label">
                      Supplier Invoice Number
                                        </label>
                                        <input
                                            type="Text"
                                            disabled
                                            value={grnDetailsData?.MASK_GRN_SUPP_INV_NO}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label">Supplier Invoice Date</label>
                                        <input
                                            type="Text"
                                            disabled
                                            value={grnDetailsData?.MASK_GRN_SUPP_INV_DATE}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label">AWB</label>
                                        <input
                                            type="Text"
                                            disabled
                                            value={grnDetailsData?.AWB}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                {grnDetailsData?.STATUS == "GIT" && (
                                    <div className="float-end">
                                        <button
                                            type="button"
                                            className="btn btn-primary mt-2"
                                            onClick={saveGRNNonInv}
                                            disabled={savechecked}
                                        >
                      Save
                                        </button>
                                    </div>
                                )}
                            </div>
                        </form>
                    </section>
                    <section className="mt-3">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h3>All Records</h3>
                            <div className="DataTable pb-4 pt-1">
                                {savechecked ? (
                                    <DataTable
                                        value={afterSaveddt}
                                        rows={10}
                                        showGridlines
                                        scrollHeight="80vh"
                                        stripedRows
                                        tableStyle={{ minWidth: "60rem" }}
                                    >
                                        <Column field="proD_CODE" header="Product Code" />
                                        <Column
                                            field="proD_SMALL_DESC"
                                            header="Product Description"
                                        />
                                        <Column
                                            field="grnD_PACK_RECV_QTY"
                                            header="Order Quantity"
                                        />
                                        <Column
                                            field="RECIEVE_QTY"
                                            header="Received Quantity"
                                        ></Column>
                                    </DataTable>
                                ) : (
                                    <DataTable
                                        value={grnProdDetailsData}
                                        paginator
                                        rows={10}
                                        showGridlines
                                        rowsPerPageOptions={[10, 25, 50]}
                                        scrollHeight="80vh"
                                        stripedRows
                                        emptyMessage="No GRN NonInv found."
                                        tableStyle={{ minWidth: "60rem" }}
                                    >
                                        <Column field="PROD_CODE" header="Product Code" />
                                        <Column
                                            field="PROD_SMALL_DESC"
                                            header="Product Description"
                                        />
                                        <Column
                                            field="GRND_PACK_RECV_QTY"
                                            header="Order Quantity  "
                                            body={(rowData) =>
                                                new Intl.NumberFormat("en-US", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }).format(rowData.GRND_PACK_RECV_QTY)
                                            }
                                        />

                                        {grnDetailsData?.STATUS == "GIT" && (
                                            <Column
                                                field="RECIEVE_QTY"
                                                header="Received Quantity"
                                                body={(rowData) => (
                                                    <InputText
                                                        value={rowData.amount}
                                                        disabled={savechecked}
                                                        onChange={(e) => {
                                                            const inputValue = e.target.value;
                                                            onAmountChange(inputValue, rowData);
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    </DataTable>
                                )}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default ViewGRNnonINV;
