import React, { useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "./DCprint.scss";
import { PDFExport } from "@progress/kendo-react-pdf";
import { MdCurrencyRupee } from "react-icons/md";
import { useDeliveryChallanContext } from "../../context/DeliveryChallanContext";

const PrintView = () => {
    const printRef = useRef();
    const pdfExportComponent = useRef(null);
    function getUniqueNo() {
        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let dateunique = date.valueOf();
        let uniqueNo = `DeliveryChallan-${day}-${month}-${year}-${dateunique}`;
        return uniqueNo;
    }

    const
        {
            DCpopup,
            setDCPopup,
            printData
        }=useDeliveryChallanContext();
     
    // let sr_no=printData.ProductList;
    //  
    return (
        <>
            <Modal className="print-popup" show={DCpopup} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Body>
                    <div id="printablediv">
                        <PDFExport 
                            fileName={getUniqueNo()}
                            paperSize="A4" forcePageBreak=".page-break" 
                            ref={pdfExportComponent}
                        >
                            <div>
                                <>
                                    <div className="py-5 mt-4">
                                        <div className="">
                                            <div className="">
                                                <p className="text-center" style={{ fontSize: "10px" }}><b>Delivery Challan</b></p>
                                            </div>
                                            <div className="parent  parent-invoice-terms">
                                                <div className="childOne  invoice-terms">
                                                    <p><span>Distributor PAN Number</span> : {printData.DIST_PAN}</p>
                                                    <p><span>Challan Number</span> : {printData.SUPP_INV_NO}</p>
                                                    <p><span>Challan Date</span> : {printData.SUPP_INV_DATE}</p>
                                                    <p><span>S.O/P.O Number</span> : {printData.SONo}</p>
                                                    <p><span>S.O/P.O Date</span> : {printData.SODate}</p>
                                                    <p><span>Mode of Payment </span> : {printData.MOP}</p>
                                                    <p><span>Payment Terms</span> : {printData.PAYMENT_TERM}</p>
                                                    <p><span>Distributor GSTN</span> : {printData.DIST_GST_NO}</p>
                                                </div>
                                            </div>

                                            <div className="parent parent-invoice-table-address" style={{ height: "auto" , paddingBottom: "50px" }}>
                                                <table className="child invoice-table-address" style={{ margin: "auto", width: "90%", marginLeft: "2%" }}>
                                                    <tr className="table-addresses">
                                                        <th>Customer Address</th>
                                                        <th>Delivery Address</th>
                                                    </tr>
                                                    <tr className="temp">
                                                        <td width="200px">
                                                            {printData.CUST_CODE}, PID: {printData.CUST_PARENT_ID} PAN Number:
                                                            {printData.Cust_Name} ULHASNAGAR-5
                                                            Delivery Address: {printData.Bill_Add1}
                                                            {" "}{printData.Bill_Town}{" "}
                                                            {printData.Bill_State}

                                                        </td>
                                                        <td width="200px">
                                                            {printData.Ship_Add2}{" "}
                                                            Delivery Address: {printData.Ship_Add3}
                                                            {printData.Ship_Add4}{" "}{printData.Ship_Add1}

                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>

                                            <div className="parent parent-invoice-table">
                                                <table className="invoice-table-details">
                                                    <thead>
                                                        <tr className="table-row-border">
                                                            <th width="20px">
                                                                Sr<br></br> No
                                                            </th>
                                                            <th width="100px">
                                                                Product Pack Description
                                                            </th>
                                                            <th className="RightAlignTD">HSN</th>
                                                            <th className="RightAlignTD">
                                                                Rate Per <br></br>Pack
                                                            </th>
                                                            <th className="RightAlignTD">MRP</th>
                                                            <th className="RightAlignTD">
                                                                No. of <br></br>Packs
                                                            </th>
                                                            <th className="RightAlignTD">
                                                                Quantity<br></br>
                                                                (EA/Kg/Ltrs)
                                                            </th>
                                                            <th className="RightAlignTD">
                                                                Scheme<br></br>Disc/Rebate
                                                            </th>
                                                            <th className="RightAlignTD">
                                                                Coupon<br></br>Scheme Disc
                                                            </th>
                                                            <th className="RightAlignTD">
                                                                Taxable<br></br>Value
                                                            </th>
                                                            <th className="RightAlignTD">
                                                                CGST Rs.<br></br>Rate%
                                                            </th>
                                                            <th className="RightAlignTD">
                                                                SGST Rs.<br></br>Rate%
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody key={1} style={{ borderBottom: "1px solid #fff" }}>     
                                                        <div className="MainValue">
                                                            {Object.keys(printData).length>1?printData.ProductList.map((data)=>(
                                                                <tr key={data.Sl_No}>
                                                                    <>
                                                                        <td width="20px">{data.Sl_No}</td>
                                                                        <td width="100px">{data.Product_Pack_Desc}</td>
                                                                        <td className="RightAlignTD">{data?.HSN}</td>
                                                                        <td className="RightAlignTD">{data?.Rate_Per_Pack}</td>
                                                                        <td className="RightAlignTD">{data.MRP}</td>
                                                                        <td className="RightAlignTD">{Number(data?.No_Of_Pack).toFixed(2)}</td>
                                                                        <td className="RightAlignTD">{data?.Quantity_EA_Kg_Ltrs}</td>
                                                                        <td className="RightAlignTD">{data?.Scheme_Disc_Rebate}</td>
                                                                        <td className="RightAlignTD">{data?.Coupon_Scheme_Disc}</td>
                                                                        <td className="RightAlignTD">{data?.Taxable_Value}</td>
                                                                        <td className="RightAlignTD">{data?.CGST_IGST_AMT}</td>
                                                                        <td className="RightAlignTD">{data?.SGST_UTGST_AMT}</td>
                                                                    </>
                                                                </tr>
                                                            )):null}

                                                        </div>
                                                        <div>
                                                            <>
                                                                <tr>
                                                                    <td width="20px"></td>
                                                                    <td width="100px"></td>
                                                                    <td className="RightAlignTD"></td>
                                                                    <td className="RightAlignTD"></td>
                                                                    <td className="RightAlignTD"></td>
                                                                    <td className="RightAlignTD"></td>
                                                                    <td className="RightAlignTD"><b>Total</b></td>
                                                                    <td className="RightAlignTD">{printData.Total_Packs}</td>
                                                                    <td className="RightAlignTD">{printData.Total_Volume}</td>
                                                                    <td className="RightAlignTD">{printData.Total_Scheme_Disc_Rebate}</td>
                                                                    <td className="RightAlignTD">{printData.Total_Coupon_Scheme_Disc}</td>
                                                                    <td className="RightAlignTD">{printData.Total_Taxable_Amount}</td>
                                                                </tr>
                                                            </>
                                                        </div>
                                                        
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div id="myFooter">
                                                <div className="row lastPart w-100">
                                                    <div className="column1">
                                                        <div className="rupeeBox">
                                                            <p className="signatureSp">
                                                                Receiver&#39;s Signature
                                                                Date Stamp
                                                            </p>
                                                            <p>
                                                                Scheme Benefit (inclusive
                                                                of GST) =
                                                                <span className="sp">
                                                                    <MdCurrencyRupee />

                                                                </span>
                                                            </p>

                                                            <p>
                                                                Amount Payable (in Words)
                                                            </p>
                                                            <p>
                                                                Rupee= {printData.Amount_Payable_Words}
                                                            </p>

                                                            <p className="boxxo">
                                                                Customer Service Toll Free
                                                                No. :
                                                                1029384756/0987654321
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="column2">
                                                        <div className="childOne invoice-terms-one">
                                                            <p><span>Gross Amount :</span> <span style={{ textAlign: "right" }}><MdCurrencyRupee /> {printData.Gross_Amount}</span></p>
                                                            <p><span>Other Discount :</span> <span style={{ textAlign: "right" }}> <MdCurrencyRupee />{printData.Other_Discount}</span></p>
                                                            <p><span>Total Adjusted Amount :</span> <span style={{ textAlign: "right" }}> <MdCurrencyRupee />{printData.Total_Adj_Amount}</span></p>
                                                            <p><span>GST :</span> <span style={{ textAlign: "right" }}> <MdCurrencyRupee />{printData.Total_Gst}</span></p>
                                                            <p><span>Grand Total:</span>  <span style={{ textAlign: "right" }}><MdCurrencyRupee /> {printData.Grand_Total}</span></p>
                                                            <p><span>Credit Adjustment :</span> <span style={{ textAlign: "right" }}><MdCurrencyRupee /> {printData.Credit_Adj}</span></p>
                                                            <p><span>Amount Payable :</span> <span style={{ textAlign: "right" }}><MdCurrencyRupee /> {printData.Amount_Payable}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            </div>
                        </PDFExport>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-cancel-red me-3" onClick={()=>setDCPopup(false)}>Close</button>
                    <button className="btn btn-primary"
                        ref={printRef}
                        onClick={() => {
                            if (pdfExportComponent.current) {
                                pdfExportComponent.current.save();
                            }
                        }}
                    >Download</button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PrintView;