import Axios from "../../api/Axios";
const BaseUrl = "CustReActivationSegChange/";
export const Endpoint = {
    GetAllRecordsofCustomerForReactivation : "GetAllRecordsofCustomerForReactivation",
    GetAllCustomerForReactivation : "GetAllCustomerForReactivation",
    AssignSeg : "AssignSeg",
    SubmitforApproval : "SubmitforApproval"
};

export const createAPICustReActivationSegChangeEndpoint = () => {
    return {
        get: (Endpoint) => Axios.get(BaseUrl + Endpoint),
        post: (Endpoint, data) => Axios.post(BaseUrl + Endpoint, data),
    };
};