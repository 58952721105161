import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import React, { useEffect, useState } from "react";
import * as Fa from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useCustMasterContext } from "../../context/CustomerMasterContext";
import { createAPICustReActivationSegChangeEndpoint, Endpoint } from "../../Services/SegmentReactivationChangeRequest/SegmentReactivationChangeRequest";
import storage from "../../Services/Storage";
import Modal from "react-bootstrap/Modal";
import { AutoComplete } from "primereact/autocomplete";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { format } from "date-fns";
import Loader from "../../Components/Loader";
import XLSX from "xlsx";
import Axios from "../../api/Axios";

const CreateChangeRequest = () => {
    const getCustomerInfoByCustId_URL = "CustomerMast/GetCustomerInfoByCustId?";
    const nav = useNavigate();
    const {show,setShow,getSegmentForBill,setSegmentConfirmBtn,handleSegmentStream,
        segmentStreamInput,
        segmentStreamFilter,
        segmentStreamSuggestion,
        handleSegmentChannel,
        segmentChannelInput,
        segmentChannelFilter,
        segmentChannelSuggestion,
        segmentSubChannelInput,
        segmentSubChannelFilter,
        segmentSubChannelSuggestion,
        handleSegmentSubChannel,
        handleSubSegmentDetail,
        subSegmentDetailInput,
        subSegmentDetailSuggestion,
        segmentSubDetailFilter,
        setSegmentStreamInput,
        setSegmentChannelInput,
        setSegmentSubChannelInput,
        setSubSegmentDetailInput,
        setManualSegmentEnterBtn,
        segmentConfirmBtn,
        viewCustomerDetails} = useCustMasterContext();
    const auth = storage.getLocal("user");
    const [strType, setStrType] = useState("SC");
    const [allData, setAllData] = useState([]);
    const [selectedCustData, setSelectedCustData] = useState([]);
    const [show1, setShow1] = useState(false);
    const [visible,setVisible] = useState(false);
    const [commentTxt,setCommentTxt] = useState("");
    const [loader,setLoader] = useState(false);
    const [errorData, setErrorData] = useState([]);
    const [errBtn, setErrBtn] = useState(false);
    const GetAllCustomerForReactivation = async(Type) =>{
        setLoader(true);
        createAPICustReActivationSegChangeEndpoint()
            .get(`${Endpoint.GetAllCustomerForReactivation}?CompId=${auth.CompId}&strType=${(Type==="SC"||Type==="EC")?Type:strType}`)
            .then((res)=>{
                setAllData(res.data.Result.Item1.Data);
                setLoader(false);
            })
            .catch((err)=>{
                console.log(err,"GetAllCustomerForReactivation");
                setLoader(false);
                setAllData([]);
            });
    };
    const ExpiryEditor = (options) => {
        return(
        // <Calendar value={options.value} onChange={(e) => options.editorCallback(e.target.value)} showIcon dateFormat="dd/mm/yy"/>
            <input type = "date" className="form-control-small" onChange={(e) => {options.editorCallback(format(new Date(e.target.value),"dd/MM/yyyy")),console.log(e.target.value,"ExpiryEditor");}}/>
        );
    };
    const handleClose = () => setShow1(false);
    const handleClose1 = () => setShow(false);
    const StreamTemplate = (stream) => {
        return (
            <>
                {stream.STREAM_CHANNEL_DESC}&nbsp; 
            </>
        );
    };
    const ChannelTemplate = (channel) => {
        return (
            <>
                {channel.STREAM_CHANNEL_DESC}&nbsp;
            </>
        );
    };
    const SubChannelTemplate = (subchannel) => {
        return (
            <>
                {subchannel.STREAM_CHANNEL_DESC}&nbsp; 
            </>
        );
    };
    const SubSegmentTemplete = (subdetail) => {
        return (
            <>
                {subdetail.SEG_DESC}&nbsp; 
            </>
        );
    };
    const SegmentEditor = (options) => {
        return(<>
            <InputText
                id="segment"
                name="segment"
                placeholder="Select"
                disabled
                value={options.value}
            />
            <div className="input-group-prepend src-btn">
                {" "}
                <span
                    className="input-group-text ms-2"
                    onClick={()=>{setShow1(true);getSegmentForBill();setSegmentConfirmBtn(false);}}
                >
                    <Fa.FaSearch />
                </span>{" "}
            </div>
            <div>
                <>
                    <div className="col-12">
                        <Modal
                            show={show1}
                            onHide={handleClose}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Segment</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                    <label className="form-label"> Stream</label>
                                    <AutoComplete
                                        field="STREAM_CHANNEL_DESC"
                                        value={segmentStreamInput}
                                        suggestions={segmentStreamSuggestion}
                                        completeMethod={segmentStreamFilter}
                                        onChange={(e) => {
                                            handleSegmentStream(e);
                                            setSegmentStreamInput(e.value);
                                        }}
                                        dropdown={true}
                                        itemTemplate={StreamTemplate}
                                        showEmptyMessage={true}
                                    />
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                    <label className="form-label">  Channel</label>
                                    <AutoComplete
                                        field="STREAM_CHANNEL_DESC"
                                        value={segmentChannelInput}
                                        suggestions={segmentChannelSuggestion}
                                        completeMethod={segmentChannelFilter}
                                        onChange={(e) => {
                                            handleSegmentChannel(e);
                                            setSegmentChannelInput(e.value);
                                        }}
                                        dropdown={true}
                                        itemTemplate={ChannelTemplate}
                                        showEmptyMessage={true}
                                    />
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                    <label className="form-label">  Sub-Channel</label>
                                    <AutoComplete
                                        field="STREAM_CHANNEL_DESC"
                                        value={segmentSubChannelInput}
                                        suggestions={segmentSubChannelSuggestion}
                                        completeMethod={segmentSubChannelFilter}
                                        onChange={(e) => {
                                            handleSegmentSubChannel(e);
                                            setSegmentSubChannelInput(e.value);
                                        }}
                                        // disabled={true}
                                        dropdown={true}
                                        itemTemplate={SubChannelTemplate}
                                        showEmptyMessage={true}
                                    />
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                    <label className="form-label">
                                        {" "}
                           Sub-Segment detail
                                    </label>
                                    <AutoComplete
                                        field="SEG_DESC"
                                        value={subSegmentDetailInput}
                                        suggestions={subSegmentDetailSuggestion}
                                        completeMethod={segmentSubDetailFilter}
                                        onChange={(e) => {
                                            handleSubSegmentDetail(e);
                                            setSubSegmentDetailInput(e.value);
                                            options.editorCallback(e.value.SEG_DESC);
                                        }}
                                        dropdown={true}
                                        itemTemplate={SubSegmentTemplete}
                                        showEmptyMessage={true}
                                    />
                                </div>

                                <div className="float-end mt-4">
                                    <button
                                        type="button"
                                        className="btn btn-cancel me-3"
                                        onClick={() => {
                                            setSegmentStreamInput([]);
                                            setSegmentChannelInput([]);
                                            setSegmentSubChannelInput([]);
                                            setSubSegmentDetailInput([]);
                                        }}

                                    >
                        Clear
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {
                                            toast.warning(
                                                `Selected segment is  "${subSegmentDetailInput.SEG_DESC}" for the customer, after saving the segment cannot be edited.`,
                                                {
                                                    position: toast.POSITION.TOP_CENTER,
                                                    pauseOnFocusLoss: false,
                                                    closeButton: false,
                                                    autoClose: 8000,
                                                }
                                            );
                                            setManualSegmentEnterBtn(true);
                                            setShow1(false);
                                        }}
                                        disabled={!segmentConfirmBtn}
                                    >
                        Confirm
                                    </button>

                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </>
            </div>
        </>
        );
    };
    const RenderExpiry = (rowData) => {
        return(
        // <Calendar value={rowData.PROP_EXP_DATE===null?null:new Date(rowData.PROP_EXP_DATE)} disabled dateFormat="dd/mm/yy"/>
            <input value={rowData.PROP_EXP_DATE} disabled className="form-control-small" />
        );
    };
    const RenderAction = (rowData) => {
        return(
            <div className="input-group">
                <InputText
                    id="segment"
                    name="segment"
                    placeholder="Select"
                    disabled
                    value={rowData.PROP_SEG_NAME}
                />
            </div>
        );
    };
    const onRowEditComplete = (e) => {
        let _allData = [...allData];
        let { newData, index } = e;

        _allData[index] = newData;
        _allData[index].PROP_SEG_ID = subSegmentDetailInput?.SBSD_ID;
        console.log(_allData,"onRowEditComplete");
        setAllData(_allData);
        setSelectedCustData([]);
    };
    const allowEdit = (rowData) => {
        return rowData.name !== "Blue Band";
    };
    const selectProposedSeg = () => {
        setLoader(true);
        try{
            if(selectedCustData.length===0){
                toast.warning("Select data to proceed",{
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 8000,
                });
                setLoader(false);
                return;
            }
            setShow(true);
            getSegmentForBill();
            setSegmentConfirmBtn(false);
            setLoader(false);
        }
        catch(ex){
            toast.error("exception occured",{
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 8000,
            });
            setLoader(false);
        }
    };
    const AssignSeg = async() => {
        setLoader(true);
        try{
            let _allData = allData.map((val)=>({
                COMP_ID:val.COMP_ID,
                COMP_NAME:val.COMP_NAME,
                CUST_CODE:val.CUST_CODE,
                CUST_ID:val.CUST_ID,
                CUST_NAME:val.CUST_NAME,
                EXPIRY_DATE:val.EXPIRY_DATE,
                PROP_EXP_DATE:val.PROP_EXP_DATE,
                PROP_SEG_ID:val.PROP_SEG_ID,
                PROP_SEG_NAME:val.PROP_SEG_NAME,
                SEG_CODE:val.SEG_CODE,
                SEG_ID:val.SEG_ID,
                SEG_NAME:val.SEG_NAME,
                UNIQUE_CODE:val.UNIQUE_CODE
            }));
            let _selectedData = selectedCustData.map((val)=>({
                COMP_ID:val.COMP_ID,
                COMP_NAME:val.COMP_NAME,
                CUST_CODE:val.CUST_CODE,
                CUST_ID:val.CUST_ID,
                CUST_NAME:val.CUST_NAME,
                EXPIRY_DATE:val.EXPIRY_DATE,
                PROP_EXP_DATE:val.PROP_EXP_DATE,
                PROP_SEG_ID:val.PROP_SEG_ID,
                PROP_SEG_NAME:val.PROP_SEG_NAME,
                SEG_CODE:val.SEG_CODE,
                SEG_ID:val.SEG_ID,
                SEG_NAME:val.SEG_NAME,
                UNIQUE_CODE:val.UNIQUE_CODE,
            }));
            let obj = {
                segDesc : subSegmentDetailInput?.SEG_DESC,
                segId : subSegmentDetailInput?.SBSD_ID,
                allData : _allData,
                selectedData : _selectedData 
            };
            const res = await createAPICustReActivationSegChangeEndpoint().post(`${Endpoint.AssignSeg}`,JSON.stringify(obj));
            setAllData(res.data.Result.Item1.Data);
            setSelectedCustData([]);
            setLoader(false);
        }
        catch(err){
            console.log(err);
            setLoader(false);
        }
    };
    const submitClick = async() => {
        setLoader(true);
        try{
            if(selectedCustData.length===0){
                toast.warning("Select any one customer to proceed",{
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 8000,
                });
                setVisible(false);
                setLoader(false);
                return;
            }
            if(commentTxt.length===0){
                toast.warning("Please give comments",{
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 8000,
                });
                setLoader(false);
                return;
            }
            if(strType === "SC"){
                for(let i = 0; i < selectedCustData.length; i++){
                    if(selectedCustData[i].PROP_SEG_ID===null||selectedCustData[i].PROP_SEG_ID===""){
                        toast.warning(`Select segment for customer code ${selectedCustData[i].CUST_CODE}`,{
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 8000,
                        });
                        setVisible(false);
                        setLoader(false);
                        return;
                    }
                }
            }
            let _objData = selectedCustData.map((val)=>({
                COMP_ID:val.COMP_ID,
                COMP_NAME:val.COMP_NAME,
                CUST_CODE:val.CUST_CODE,
                CUST_ID:val.CUST_ID,
                CUST_NAME:val.CUST_NAME,
                EXPIRY_DATE:val.EXPIRY_DATE===null?"":val.EXPIRY_DATE,
                PROP_EXP_DATE:val.PROP_EXP_DATE===null?"":format(new Date(val.PROP_EXP_DATE),"dd/MM/yyyy"),
                PROP_SEG_ID:val.PROP_SEG_ID,
                PROP_SEG_NAME:val.PROP_SEG_NAME,
                SEG_CODE:val.SEG_CODE,
                SEG_ID:val.SEG_ID,
                SEG_NAME:val.SEG_NAME,
                UNIQUE_CODE:val.UNIQUE_CODE,
                comments:commentTxt
            }));
            let obj = {
                userId:auth.UserId,
                type:strType,
                objData:_objData
            };
            // return;
            const res = await createAPICustReActivationSegChangeEndpoint().post(`${Endpoint.SubmitforApproval}`,JSON.stringify(obj));
            setVisible(false);
            GetAllCustomerForReactivation();
            setSelectedCustData([]);
            setCommentTxt("");
            setSubSegmentDetailInput([]);
            setLoader(false);
            if(res.data.Status === "Error"){
                setErrBtn(true);
                setErrorData(res.data.dtErr);
                toast.warning(res.data.Message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
            }
            else{
                toast.success(res.data.Message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
                setErrBtn(false);
                setErrorData([]);
            }
        }
        catch(err){
            console.log(err);
            toast.error(err.response.Message, {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
            setVisible(false);
            setLoader(false);
        }
    };
    const onErrorClick = () => {
        let date = new Date();
        let day = date.getDate();
        var months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        let exactmonth = months[date.getMonth()];
        let exactDay = day.toString().padStart(2, "0");
        let year = date.getFullYear();
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(errorData);
        XLSX.utils.book_append_sheet(wb, ws, strType==="SC"?"Customer_Segment_Change_Error_":"Customer_Expiry_Change_Error_");
        XLSX.writeFile(wb, `${strType==="SC"?"Customer_Segment_Change_Error_":"Customer_Expiry_Change_Error_"}${exactDay}${exactmonth}${year}.xlsx`);
    };
    const onViewClick = async(row) => {
        try{
            const custInfo_api = `${getCustomerInfoByCustId_URL}CICode=${auth.CICode}&CustId=${row.CUST_ID}&CompId=${auth.CompId}`;
            const custInfoRes = await Axios.get(custInfo_api);
            const obj = {
                MASK_CUST_ID : custInfoRes.data.Table[0].MASK_CUST_ID,
                PARTY_CODE : custInfoRes.data.Table[0].PARTY_CODE,
                CUST_NAME : custInfoRes.data.Table[0].CUST_NAME,
                DISTRIBUTOR_NAME : custInfoRes.data.Table[0].DISTRIBUTOR_NAME,
                MASK_UPDATE_COUNT : custInfoRes.data.Table[0].MASK_UPDATE_COUNT,
                MASK_EXPORT_TAG : custInfoRes.data.Table[0].MASK_EXPORT_TAG,
                MASK_FIRST_ENTERED_BY : custInfoRes.data.Table[0].MASK_FIRST_ENTERED_BY,
                MASK_FIRST_ENTERED_ON : custInfoRes.data.Table[0].MASK_FIRST_ENTERED_ON,
                MASK_LAST_MODIFIED_BY : custInfoRes.data.Table[0].MASK_LAST_MODIFIED_BY,
                MASK_LAST_MODIFIED_ON : custInfoRes.data.Table[0].MASK_LAST_MODIFIED_ON,
                CREATION_DATE : custInfoRes.data.Table[0].MASK_CREATION_DATE,
                MASK_KYC_STATUS : custInfoRes.data.Table[0].MASK_KYC_STATUS,
                MASK_GEOG_DESC : custInfoRes.data.Table[0].MASK_TOWN,
                MASK_CUST_UNIQUE_ID : custInfoRes.data.Table[0].MASK_CUST_UNIQUE_CODE
            };
            viewCustomerDetails(obj);
            nav(`/masters/customermaster/details?id=${"1"}`);
        }
        catch(err){
            console.log(err);
        }
    };
    const renderView = (rowData) => {
        return (
            <>
                <div className="action-icon">
                    <div className="d-inline" data-toggle="tooltip" data-placement="bottom" title="View Customer Details">
                        <button className="grid-icon-img" 
                            onClick={()=>{
                                onViewClick(rowData);
                                // nav("/masters/customermaster/details");
                            }}>
                            <img alt='InnoRise' width='20px' src={require("../../Assets/Images/ViewEdit.png")} />
                        </button>
                    </div>
                </div>
            </>
        );
    };
    useEffect(()=>{
        GetAllCustomerForReactivation();
    },[]);
    if(loader){
        return(<Loader/>);
    }
    else{
        return (
            <>
                <main>
                    <div className="col-12 pt-3 pb-3">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <h3 className="py-2">Segment / Reactivation Change Request</h3>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="float-end mb-2">
                                    {errBtn&&<button type="button" className="btn btn-cancel-red me-3" onClick={onErrorClick}>
                                Error Download
                                    </button>}
                                    <Link to="/masters/segmentreactivationchangerequestallrecords">
                                        <button type="button" className="btn btn-outline-primary me-3">
                                All Records
                                        </button>
                                    </Link>
                                    <button type="button" className="btn btn-primary" 
                                        onClick={()=>setVisible(true)}
                                    >
                                Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <form>
                                <div className="my-3 d-flex justify-content-center">
                                    <span className='me-3'>Select Type :</span>
                                    <div className="d-inline-flex">
                                        <RadioButton name="Type" value="SC" onChange = {(e)=>{setStrType(e.value);GetAllCustomerForReactivation(e.value);}} checked={strType === "SC"}/>
                                        <label className="mx-2">Segment Change</label>
                                        <RadioButton name="Type" value="EC" onChange = {(e)=>{setStrType(e.value);GetAllCustomerForReactivation(e.value);}} checked={strType === "EC"}/>
                                        <label className="mx-2">Re-activation</label>
                                    </div>
                                </div>
                            </form>
                        </div>

                        {/* Segment Change */}
                        {strType==="SC"&&<section className="mt-3">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <h3 className="py-2">Records</h3>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="float-end">
                                            <button type="button" className="btn btn-light" onClick={selectProposedSeg}>
                                        Select Proposed Segment <Fa.FaSearch className="ms-2" />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="DataTable pb-4">
                                    <DataTable
                                        value={allData}
                                        // dataKey="DRCR_NO"
                                        paginator
                                        rows={10}
                                        // showSelectAll={false}
                                        rowsPerPageOptions={[10, 25, 50]}
                                        scrollHeight="80vh"
                                        filterDisplay="row"
                                        showGridlines
                                        scrollable
                                        stripedRows
                                        selectionMode='checkbox'
                                        selection={selectedCustData}
                                        onSelectionChange={(e) => {
                                            setSelectedCustData(e.value);
                                        }}
                                        emptyMessage="No Data Found."
                                        tableStyle={{ minWidth: "80rem" }}
                                        editMode="row"
                                        onRowEditComplete={onRowEditComplete}
                                    >
                                        <Column
                                            selectionMode="multiple"
                                            header="Select All"
                                            style={{ minwidth: "12rem" }}
                                        ></Column>
                                        <Column
                                            field="CUST_CODE"
                                            header="Customer Code"
                                            filterMatchMode="contains"
                                            filter
                                            filterPlaceholder="Search Here..."
                                            style={{ minwidth: "14rem" }}
                                        ></Column>
                                        <Column
                                            field="CUST_NAME"
                                            header="Customer Name"
                                            filterMatchMode="contains"
                                            filter
                                            filterPlaceholder="Search Here..."
                                            style={{ minwidth: "12rem" }}
                                        ></Column>
                                        <Column
                                            field="UNIQUE_CODE"
                                            header="Unique Code"
                                            filterMatchMode="contains"
                                            filter
                                            filterPlaceholder="Search Here..."
                                            style={{ minwidth: "12rem" }}
                                        ></Column>
                                        <Column
                                            field="SEG_CODE"
                                            header="Segment Code"
                                            filterMatchMode="contains"
                                            filter
                                            filterPlaceholder="Search Here..."
                                            style={{ minwidth: "12rem" }}
                                        ></Column>
                                        <Column
                                            field='PROP_SEG_NAME'
                                            body={RenderAction}
                                            header="Proposed Segment"
                                            editor={(options) => SegmentEditor(options)}
                                            style={{ minwidth: "18rem" }}
                                        ></Column>
                                        {/* Input Text Here */}
                                        <Column header="Action" rowEditor={allowEdit}
                                            style={{ minwidth: "8rem" }} />
                                        <Column body={renderView} header="View" />
                                        {/* There should be a View/Edit Icon, which redirect Customer Master Page */}
                                    </DataTable>
                                </div>
                            </div>
                        </section>}

                        {/* Re-activation Change */}
                        {strType==="EC"&&<section className="mt-3">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <h3 className="py-2">Records</h3>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">

                                    </div>
                                </div>

                                <div className="DataTable pb-4">
                                    <DataTable
                                        value={allData}
                                        // dataKey="DRCR_NO"
                                        paginator
                                        rows={10}
                                        // showSelectAll={false}
                                        rowsPerPageOptions={[10, 25, 50]}
                                        scrollHeight="80vh"
                                        filterDisplay="row"
                                        showGridlines
                                        scrollable
                                        stripedRows
                                        selection={selectedCustData}
                                        onSelectionChange={(e) => {
                                            setSelectedCustData(e.value);
                                        }}
                                        emptyMessage="No Data Found."
                                        tableStyle={{ minWidth: "80rem" }}
                                        editMode="row"
                                        onRowEditComplete={onRowEditComplete}
                                    >
                                        <Column
                                            selectionMode="multiple"
                                            header="Select All"
                                        ></Column>
                                        <Column
                                            field="CUST_CODE"
                                            header="Customer Code"
                                            filterMatchMode="contains"
                                            filter
                                            filterPlaceholder="Search Here..."
                                        ></Column>
                                        <Column
                                            field="CUST_NAME"
                                            header="Customer Name"
                                            filterMatchMode="contains"
                                            filter
                                            filterPlaceholder="Search Here..."
                                        ></Column>
                                        <Column
                                            field="UNIQUE_CODE"
                                            header="Unique Code"
                                            filterMatchMode="contains"
                                            filter
                                            filterPlaceholder="Search Here..."
                                        ></Column>
                                        <Column
                                            field="EXPIRY_DATE"
                                            header="Expiry Date"
                                        ></Column>
                                        <Column
                                            field="PROP_EXP_DATE"
                                            body = {RenderExpiry}
                                            header="Revised Expiry Date"
                                            editor={(options) => ExpiryEditor(options)}
                                        ></Column>
                                        {/* Input Date Here */}
                                        <Column header="Action" rowEditor={allowEdit} />
                                        <Column body={renderView} header="View" />
                                        {/* There should be a View/Edit Icon, which redirect Customer Master Page */}
                                    </DataTable>
                                </div>
                            </div>
                        </section>}
                    </div>
                </main>
                
                <div className="col-12">
                    <Modal
                        show={show}
                        onHide={handleClose1}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Segment</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            
                            <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                <label className="form-label"> Stream</label>
                                <AutoComplete
                                    field="STREAM_CHANNEL_DESC"
                                    value={segmentStreamInput}
                                    suggestions={segmentStreamSuggestion}
                                    completeMethod={segmentStreamFilter}
                                    onChange={(e) => {
                                        handleSegmentStream(e);
                                        setSegmentStreamInput(e.value);
                                    }}
                                    dropdown={true}
                                    itemTemplate={StreamTemplate}
                                    showEmptyMessage={true}
                                />
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                <label className="form-label">  Channel</label>
                                <AutoComplete
                                    field="STREAM_CHANNEL_DESC"
                                    value={segmentChannelInput}
                                    suggestions={segmentChannelSuggestion}
                                    completeMethod={segmentChannelFilter}
                                    onChange={(e) => {
                                        handleSegmentChannel(e);
                                        setSegmentChannelInput(e.value);
                                    }}
                                    dropdown={true}
                                    itemTemplate={ChannelTemplate}
                                    showEmptyMessage={true}
                                />
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                <label className="form-label">  Sub-Channel</label>
                                <AutoComplete
                                    field="STREAM_CHANNEL_DESC"
                                    value={segmentSubChannelInput}
                                    suggestions={segmentSubChannelSuggestion}
                                    completeMethod={segmentSubChannelFilter}
                                    onChange={(e) => {
                                        handleSegmentSubChannel(e);
                                        setSegmentSubChannelInput(e.value);
                                    }}
                                    // disabled={true}
                                    dropdown={true}
                                    itemTemplate={SubChannelTemplate}
                                    showEmptyMessage={true}
                                />
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                <label className="form-label">
                                    {" "}
                       Sub-Segment detail
                                </label>
                                <AutoComplete
                                    field="SEG_DESC"
                                    value={subSegmentDetailInput}
                                    suggestions={subSegmentDetailSuggestion}
                                    completeMethod={segmentSubDetailFilter}
                                    onChange={(e) => {
                                        handleSubSegmentDetail(e);
                                        setSubSegmentDetailInput(e.value);
                                    }}
                                    dropdown={true}
                                    itemTemplate={SubSegmentTemplete}
                                    showEmptyMessage={true}
                                />
                            </div>

                            <div className="float-end mt-4">
                                <button
                                    type="button"
                                    className="btn btn-cancel me-3"
                                    onClick={() => {
                                        setSegmentStreamInput([]);
                                        setSegmentChannelInput([]);
                                        setSegmentSubChannelInput([]);
                                        setSubSegmentDetailInput([]);
                                    }}
                                        
                                >
                    Clear
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        toast.warning(
                                            `Selected segment is  "${subSegmentDetailInput.SEG_DESC}" for the customer, after saving the segment cannot be edited.`,
                                            {
                                                position: toast.POSITION.TOP_CENTER,
                                                pauseOnFocusLoss: false,
                                                closeButton: false,
                                                autoClose: 8000,
                                            }
                                        );
                                        setManualSegmentEnterBtn(true);
                                        setShow(false);
                                        AssignSeg();
                                    }}
                                    disabled={!segmentConfirmBtn}
                                >
                    Confirm
                                </button>
                                    
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
                <Dialog header="Comments" visible={visible} style={{ width: "50vw" }} breakpoints={{ "960px": "75vw", "641px": "100vw" }} onHide={() => {if (!visible) return; setVisible(false); }} closable={false}>
                    <p className="m-0">
                        <InputTextarea placeholder="Comments" value={commentTxt} onChange={(e) => setCommentTxt(e.target.value)} rows={5} cols={30} />
                        <div className="float-end mb-2">
                            <button type="button" className="btn btn-primary me-2" onClick={()=> submitClick()}>
                            Submit
                            </button>
                            <button type="button" className="btn btn-cancel-red" onClick={()=>setVisible(false)}>
                            Cancel
                            </button>
                        </div>
                    </p>
                </Dialog>
            </>
        );
    }
};

export default CreateChangeRequest;