import React, { useContext } from "react";
import { useState } from "react";
// import { useNavigate } from "react-router";
import Axios from "../api/Axios";
import storage from "../Services/Storage";
import format from "date-fns/format";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
// import { Decrypt } from "../../src/EncryptDecrypt/EncryptDecrypt";

const CustomerMasterContext = React.createContext();
const CustomerMasterProvider = ({ children }) => {
    const auth = storage.getLocal("user");
    const nav = useNavigate();
    //SINGLE API------------------------------------------
    const [customerDetailsById, setCustomerDetailsById] = useState([]);
    const [compState, setCompState] = useState("");
    const [townList, setTownList] = useState([]);
    const [editCustMastLoader, setEditCustMastLoader] = useState(false);
    const [creationDate, setCreationdate] = useState([]);
    const [expiryDate, setExpiryDate] = useState([]);
    const [townInput, setTownInput] = useState(0);
    const [townSuggestion, setTownSuggestion] = useState(null);
    const [shipTownSuggestion, setShipTownSuggestion] = useState([]);
    const [billDistrict, setBillDistrict] = useState([]);
    const [billTown, setBillTown] = useState([]);
    const [billState, setBillState] = useState([]);
    const [billZip, setBillZip] = useState("");
    const [manualTownEnter, setManualTownEnter] = useState(false);
    const [shipTownList, setShipTownList] = useState([]);
    const [shipSalesArea, setShipSalesArea] = useState([]);
    const [customerSaveUpdateLoader, setCustomerSaveUpdateLoader] =
    useState(false);
    const [partyCodeOnSave, setPartyCodeOnsave] = useState([]);
    const [segmentConfirmBtn, setSegmentConfirmBtn] = useState(false);
    const [uniqueCodeBtn, setUniqueCodeBtn] = useState(false);
    //---------------------------shipping----------------------------------
    const [shipTown, setShipTown] = useState([]);
    const [shipDistrict, setShipDistrict] = useState([]);
    const [shipState, setShipState] = useState([]);
    const [shipZip, setShipZip] = useState([]);
    const [manualShipTownEnter, setManualShipEnter] = useState(false);
    const [custId, setCustId] = useState([]);
    const [billAddressLoader, setBillAddressLoader] = useState(false);
    const [show, setShow] = useState(false);
    const [segmentStreamList, setSegmentStreamList] = useState([]);
    const [segmentStreamInput, setSegmentStreamInput] = useState(null);
    const [segmentStreamSuggestion, setSegmentStreamSuggestion] = useState(null);
    const [segmentChannelList, setSegmentChannelList] = useState([]);
    const [segmentChannelInput, setSegmentChannelInput] = useState(0);
    const [segmentChannelSuggestion, setSegmentChannelSuggestion] =
    useState(null);
    const [segmentForEdit, setSegmentForEdit] = useState([]);
    const [newCustomerSave, setNewCustomerSave] = useState(false);

    const [segmentSubChannelList, setSegmentSubChannelList] = useState([]);
    const [segmentSubChannelInput, setSegmentSubChannelInput] = useState(0);
    const [segmentSubChannelSuggestion, setSegmentSubChannelSuggestion] =
    useState(null);

    const [subSegmentDetailList, setSubSegmentDetailList] = useState([]);
    const [subSegmentDetailInput, setSubSegmentDetailInput] = useState(0);
    const [subSegmentDetailSuggestion, setSubSegmentDetailSuggestion] =
    useState(null);
    const [selectedTown, setSelectedTown] = useState(null);
    const [selectedShipTown, setSelectedShipTown] = useState([]);
    const [segmentValue, setSegmentValue] = useState([]);
    const [townError, setTownError] = useState(false);
    const [townInitialVal, setTownInitialVal] = useState(true);
    const [shipTownError, setShipTownError] = useState(false);
    const [shipTownInitailVal, setShipTownInitialVal] = useState(true);
    const [manualSegmentEnterBtn, setManualSegmentEnterBtn] = useState(false);
    const [newCutomerUniCode, setNewCustomerUniCode] = useState([]);
    const [customerSaveDisable, setCustomerSaveDisable] = useState(false);
    const [errorLabel, setErrorLable] = useState(false);
    const [exportbtnpopup, setExportbtnpopup] = useState(false);
    const [copyToShip, setCopyToShip] = useState(false);
    const [editView, setEditView] = useState(false);
    const [kycStatus, setKycStatus] = useState(false);
    const [kycfieldDisabled, setKycfieldDisabled] = useState(false);
    // const[allCustomerexportData,setAllCustomerexportData]=useState([]);

    const getCustomerDetailsById_URL = "CustomerMast/GetCustomerDetailsById?";
    const town_URL = "CustomerMast/GetTown?";
    const ship_town_URL = "CustomerMast/GetShipTown?";
    const zip_Search_URL = "CustomerMast/zipSearch?";
    const getCustomerInfoByCustId_URL = "CustomerMast/GetCustomerInfoByCustId?";

    const viewCustomerDetails = async (row) => {
        setCustId(row.MASK_CUST_ID);
        const getCustomerDetailsById_api = `${getCustomerDetailsById_URL}CICode=${auth.CICode}&UserId=${auth.UserId}&CompId=${auth.CompId}&PartyId=${row.PARTY_CODE}&CustId=${row.MASK_CUST_ID}`;
        const town_api = `${town_URL}CICode=${auth.CICode}&intGeogLevel=3`;
        const custInfo_api = `${getCustomerInfoByCustId_URL}CICode=${auth.CICode}&CustId=${row.MASK_CUST_ID}&CompId=${auth.CompId}`;
        const ship_town_api = `${ship_town_URL}CICode=${auth.CICode}&intGeogLevel=3&CompId=${auth.CompId}`;

        setEditCustMastLoader(true);
        setNewCustomerSave(false);
        setManualSegmentEnterBtn(false);

        try {
            const res = await Axios.get(getCustomerDetailsById_api);

            setCustomerDetailsById(res.data);
            if (res.data.KYC === "BS" || res.data.KYC === "R") {
                setKycStatus(true);
            } else if (
                res.data.KYC === "S" ||
        res.data.KYC === "AP" ||
        res.data.KYC === "RSS" ||
        res.data.KYC === "RSC" ||
        res.data.KYC === "DP"
            ) {
                setKycfieldDisabled(true);
            } else {
                setKycStatus(false);
                setKycfieldDisabled(false);
            }

            //   if (res.data.KYC === "S" || res.data.KYC === "AP"||res.data.KYC === "RSS"||res.data.KYC === "RSC") {
            //     setKycfieldDisabled(true);
            //   } else {
            //     setKycfieldDisabled(false);
            //   }

            const dateConversion = (str) => {
                const [day, month, year] = str.split("/");
                const date = new Date(+year, +month - 1, +day);
                const creationDate = format(date, "yyyy-MM-dd");
                return creationDate;
            };
            const town_res = await Axios.get(town_api);
            setTownList(
                town_res.data.map((val) => {
                    return val;
                })
            );
            //   console.log(
            //     "townres",
            //     town_res.data.map((val) => {
            //       return val;
            //     })
            //   );
            const ship_town_res = await Axios.get(ship_town_api);

            setShipTownList(ship_town_res.data);
            const custInfoRes = await Axios.get(custInfo_api);

            setSegmentForEdit(custInfoRes.data.Table);
            const cre_date = dateConversion(res.data.CreationDate);
            setCreationdate(cre_date);
            // const exp_date = dateConversion(res.data.ExpiryDate);
            const exp_date = res.data.ExpiryDate
                ? dateConversion(res.data.ExpiryDate)
                : "";
            setExpiryDate(exp_date);

            setManualSegmentEnterBtn(false);

            setEditCustMastLoader(false);
            setErrorLable(false);
        } catch (e) {
            setEditCustMastLoader(false);
        }

        setTownInput([]);
    };
    const getSaveBillShipTown = async () => {
        setEditCustMastLoader(true);
        setNewCustomerSave(true);
        setManualSegmentEnterBtn(false);
        setBillZip([]);
        setBillDistrict([]);
        setBillState([]);
        setNewCustomerUniCode();
        setSegmentValue([]);
        setShipDistrict([]);
        setShipState([]);
        setShipZip([]);
        setCustomerSaveDisable(false);
        setErrorLable(false);
        setPartyCodeOnsave([]);
        setUniqueCodeBtn(false);

        const town_api = `${town_URL}CICode=${auth.CICode}&intGeogLevel=3`;
        const ship_town_api = `${ship_town_URL}CICode=${auth.CICode}&intGeogLevel=3&CompId=${auth.CompId}`;
        const town_res = await Axios.get(town_api);
        setTownList(
            town_res.data.map((val) => {
                return val;
            })
        );
        // console.log(
        //   "townres",
        //   town_res.data.map((val) => {
        //     return val;
        //   })
        // );
        setEditCustMastLoader(false);
        const ship_town_res = await Axios.get(ship_town_api);

        setShipTownList(ship_town_res.data);
    };
    const townHandleChange = async (e) => {
        setManualTownEnter(true);
        setBillZip([]);
        setBillDistrict([]);
        setBillState([]);
        //

        if (e.target.value.GEOG_ID !== undefined) {
            setTownError(false);
            setTownInitialVal(false);

            setBillTown(e.target.value.NAME);
            setUniqueCodeBtn(true);
            // for dist
            const town_dist = `${town_URL}CICode=${auth.CICode}&intGeogLevel=2&strGeogId=${e.target.value.GEOG_ID}`;
            const uniqueCodeGen_URL = "CustomerMast/UCode_AutoGen?";
            const dist_res = await Axios.get(town_dist);
            setBillDistrict(dist_res.data[0].NAME);

            // for state
            const geoIdForState = dist_res.data[0].CODE;
            const town_state = `${town_URL}CICode=${auth.CICode}&intGeogLevel=1&strGeogId=${geoIdForState}`;
            const state_res = await Axios.get(town_state);
            setBillState(state_res.data[0].NAME);
            // alert(state_res.data[0].NAME);

            // for pin
            const zip_api = `${zip_Search_URL}CICode=${auth.CICode}&strGeogId=${e.target.value.GEOG_ID}`;
            const zip_res = await Axios.get(zip_api);
            setBillZip(zip_res.data);

            const uniqueCode_api = `${uniqueCodeGen_URL}CICode=${auth.CICode}&ZipCode=${zip_res.data}&CompId=${auth.CompId}`;
            if (newCustomerSave) {
                const uniqueRes = await Axios.get(uniqueCode_api);

                setNewCustomerUniCode(uniqueRes.data);
                setUniqueCodeBtn(true);
            }

            setBillAddressLoader(false);
        } else if (e.target.value.GEOG_ID === undefined || e.target.value === "") {
            setTownError(true);
            setTownInitialVal(false);
        }
    };
    const townFilter = (e) => {
        setTimeout(() => {
            let _filteredTown;
            if (!e.query.trim().length) {
                _filteredTown = [...townList];
            } else {
                _filteredTown = townList.filter((town) => {
                    return (
                        town.PIN_CODE.toLowerCase().startsWith(e.query.toLowerCase()) ||
            town.NAME.toLowerCase().startsWith(e.query.toLowerCase())
                    );
                });
            }
            setTownSuggestion(_filteredTown);
        }, 250);
    };
    //for shipping--------------------------------------------------
    const shiptownFilter = (e) => {
        setTimeout(() => {
            let _filteredTown;
            if (!e.query.trim().length) {
                _filteredTown = [...shipTownList];
            } else {
                _filteredTown = shipTownList.filter((town) => {
                    return (
                        town.PIN_CODE.toLowerCase().startsWith(e.query.toLowerCase()) ||
            town.TOWN.toLowerCase().startsWith(e.query.toLowerCase())
                    );
                });
            }
            setShipTownSuggestion(_filteredTown);
        }, 250);
    };
    const shipTownHandleChange = async (e, id) => {
        const shipSalesArea_URL = "CustomerMast/GetShipSalesArea?";

        if (id == 1) {
            setManualShipEnter(true);
            setShipZip([]);
            setShipDistrict([]);
            setShipState([]);
            //
            if (e.target.value.MASK_GEOG_ID !== undefined) {
                setShipTownError(false);
                setShipTownInitialVal(false);
                setShipTown(e.target.value.TOWN);
                setShipDistrict(e.target.value.DISTRICT);
                setShipZip(e.target.value.PIN_CODE);
                setShipState(e.target.value.STATE);

                const shipSalesArea_api = `${shipSalesArea_URL}CICode=${auth.CICode}&strShipAreaId=${e.target.value.MASK_GEOG_ID}`;
                const salesshipRes = await Axios.get(shipSalesArea_api);

                setShipSalesArea(salesshipRes.data[0].MASK_AREA_ID);

                // for dist
                // const town_dist = `${town_URL}CICode=${auth.CICode}&intGeogLevel=2&strGeogId=${e.target.value.GEOG_ID}`;
                // const dist_res = await Axios.get(town_dist);
                // setShipDistrict(dist_res.data[0].NAME);
                //
                // alert("jj");

                // // for state
                // const geoIdForState = dist_res.data[0].CODE;
                // const town_state = `${town_URL}CICode=${auth.CICode}&intGeogLevel=1&strGeogId=${geoIdForState}`;
                // const state_res = await Axios.get(town_state);
                // setShipState(state_res.data[0].NAME);
                // // for pin
                // const zip_api = `${zip_Search_URL}CICode=${auth.CICode}&strGeogId=${e.target.value.GEOG_ID}`;
                // const zip_res = await Axios.get(zip_api);
                // setShipZip(zip_res.data);
                //

                setBillAddressLoader(false);
            } else if (
                e.target.value.GEOG_ID === undefined ||
        e.target.value === ""
            ) {
                setShipTownError(true);
                setShipTownInitialVal(false);
            }
        } else if (id == 2) {
            if (e.GEOG_ID !== undefined) {
                setShipTown(e.NAME);
                const shipSalesArea_api = `${shipSalesArea_URL}CICode=${auth.CICode}&strShipAreaId=${e.GEOG_ID}`;
                const salesshipRes = await Axios.get(shipSalesArea_api);

                setShipSalesArea(salesshipRes.data[0].MASK_AREA_ID);

                // for dist
                // const town_dist = `${town_URL}CICode=${auth.CICode}&intGeogLevel=2&strGeogId=${e.target.value.GEOG_ID}`;
                // const dist_res = await Axios.get(town_dist);
                // setShipDistrict(dist_res.data[0].NAME);
                //
                // alert("jj");

                // // for state
                // const geoIdForState = dist_res.data[0].CODE;
                // const town_state = `${town_URL}CICode=${auth.CICode}&intGeogLevel=1&strGeogId=${geoIdForState}`;
                // const state_res = await Axios.get(town_state);
                // setShipState(state_res.data[0].NAME);
                // // for pin
                // const zip_api = `${zip_Search_URL}CICode=${auth.CICode}&strGeogId=${e.target.value.GEOG_ID}`;
                // const zip_res = await Axios.get(zip_api);
                // setShipZip(zip_res.data);
                //

                setBillAddressLoader(false);
            } else if (e.GEOG_ID === undefined || e === "") {
                setShipTownError(true);
                setShipTownInitialVal(false);
            }
        }
    };

    const getSegmentForBill = async () => {
        const segmentStream = `CustomerMast/GetStream?CICode=${auth.CICode}&UserId=${auth.UserId}`;
        const segmentStreamRes = await Axios.get(segmentStream);

        const newSegmentStreamres = segmentStreamRes.data.filter((a) => {
            return a.STREAM_CHANNEL_DESC == "B2C";
        });
        setSegmentStreamList(newSegmentStreamres);
        setSegmentStreamInput([]);
        setSegmentChannelInput([]);
        setSegmentSubChannelInput([]);
        setSubSegmentDetailInput([]);
    };
    const handleSegmentStream = async (e) => {
        if (e.target.value.STREAM_CHANNEL_ID !== undefined);
        {
            const channel_URL = "CustomerMast/GetChannel?";
            const channelApi = `${channel_URL}CICode=${auth.CICode}&SegparentId=${e.target.value.STREAM_CHANNEL_ID}&UserId=${auth.UserId}`;
            const channelRes = await Axios.get(channelApi);

            const newChannelList = channelRes.data.filter((a) => {
                return (
                    a.STREAM_CHANNEL_DESC == "RETAIL" ||
          a.STREAM_CHANNEL_DESC == "PCO IWS" ||
          a.STREAM_CHANNEL_DESC == "MCO IWS"
                );
            });
            setSegmentChannelList(newChannelList);
            setSegmentChannelInput([]);
            setSegmentSubChannelInput([]);
            setSubSegmentDetailInput([]);
        }
        //
        // const channel_URL = "CustomerMast/GetChannel?";
        //
        // setSegmentStreamInput(e.target.value);
        // let segmentStream = segmentStreamList
        //   .map((val) => {
        //     return val.STREAM_CHANNEL_CODE + " " + val.STREAM_CHANNEL_DESC;
        //   })
        //   .filter((val) => val == e.target.value);

        // if (segmentStream.length > 0) {
        //   const channelID = segmentStreamList.filter((val) => {
        //     return (
        //       val.STREAM_CHANNEL_CODE + " " + val.STREAM_CHANNEL_DESC ==
        //       segmentStream
        //     );
        //   });
        //
        //   const channelID1 = channelID[0].STREAM_CHANNEL_ID;
        //   const channelApi = `${channel_URL}CICode=${auth.CICode}&SegparentId=${channelID1}&UserId=${auth.UserId}`;
        //   const channelRes = await Axios.get(channelApi);
        //
        //   setSegmentChannelList(channelRes.data);

    //   console.log(
    //     "segmentStreamRow",
    //     segmentStreamList.filter((val) => {
    //       return val.STREAM_CHANNEL_DESC == segmentStream;
    //     })
    //   );
    // }
    };
    const segmentStreamFilter = (e) => {
    // let results = segmentStreamList
    //   .map((val) => val.STREAM_CHANNEL_CODE + " " + val.STREAM_CHANNEL_DESC)
    //   .filter((capital) => {
    //     return capital.toLowerCase().includes(e.query.toLowerCase());
    //   });
    // setSegmentStreamSuggestion(results);
        setTimeout(() => {
            let _filteredStream;
            if (!e.query.trim().length) {
                _filteredStream = [...segmentStreamList];
            } else {
                _filteredStream = segmentStreamList.filter((town) => {
                    return town.STREAM_CHANNEL_DESC.toLowerCase().startsWith(
                        e.query.toLowerCase()
                    );
                });
            }
            setSegmentStreamSuggestion(_filteredStream);
        }, 250);
    };
    const handleSegmentChannel = async (e) => {
        if (e.target.value.STREAM_CHANNEL_ID !== undefined);
        {
            const subchannel_URL = "CustomerMast/GetSubChannel?";
            const subchannelApi = `${subchannel_URL}CICode=${auth.CICode}&SegparentId=${e.target.value.STREAM_CHANNEL_ID}`;
            const subchannelRes = await Axios.get(subchannelApi);

            const newSegmentSubChannelList = subchannelRes.data.filter((a) => {
                return (
                    a.STREAM_CHANNEL_DESC == "NFW (4 Wheeler)" ||
          a.STREAM_CHANNEL_DESC == "NFW(2 Wheelers)" ||
          a.STREAM_CHANNEL_DESC == "RETAIL"
                );
            });
            setSegmentSubChannelList(newSegmentSubChannelList);
            setSegmentSubChannelInput([]);
            setSubSegmentDetailInput([]);
        }

        // setSegmentChannelInput(e.target.value);
        // let segmentChannel = segmentChannelList
        //   .map((val) => {
        //     return val.STREAM_CHANNEL_CODE + " " + val.STREAM_CHANNEL_DESC;
        //   })
        //   .filter((val) => val == e.target.value);
        // if (segmentChannel) {
        //   const subChannelID = segmentChannelList.filter((val) => {
        //     return (
        //       val.STREAM_CHANNEL_CODE + " " + val.STREAM_CHANNEL_DESC ==
        //       segmentChannel
        //     );
        //   });
        //   const subChannelID1 = subChannelID[0].STREAM_CHANNEL_ID;
        //   const subchannelApi = `${subchannel_URL}CICode=${auth.CICode}&SegparentId=${subChannelID1}`;
        //   const subchannelRes = await Axios.get(subchannelApi);
        //
        //   setSegmentSubChannelList(subchannelRes.data);

    //
    // }
    };
    const segmentChannelFilter = (e) => {
        setTimeout(() => {
            let _filteredchannel;
            if (!e.query.trim().length) {
                _filteredchannel = [...segmentChannelList];
            } else {
                _filteredchannel = segmentChannelList.filter((town) => {
                    return town.STREAM_CHANNEL_DESC.toLowerCase().startsWith(
                        e.query.toLowerCase()
                    );
                });
            }
            setSegmentChannelSuggestion(_filteredchannel);
        }, 250);

    // let results = segmentChannelList
    //   .map((val) => val.STREAM_CHANNEL_CODE + " " + val.STREAM_CHANNEL_DESC)
    //   .filter((capital) => {
    //     return capital.toLowerCase().includes(e.query.toLowerCase());
    //   });
    // setSegmentChannelSuggestion(results);
    };
    const handleSegmentSubChannel = async (e) => {
        if (e.target.value.STREAM_CHANNEL_ID !== undefined);
        {
            const segmentSubDetail_URL = "CustomerMast/GetSubSegmentDetail?";
            const subdetailApi = `${segmentSubDetail_URL}CICode=${auth.CICode}&SegparentId=${e.target.value.STREAM_CHANNEL_ID}`;
            const subdetailRes = await Axios.get(subdetailApi);

            setSubSegmentDetailList(subdetailRes.data);
            setSubSegmentDetailInput([]);
        }

    // setSegmentSubChannelInput(e.target.value);
    // const segmentSubChannel = segmentSubChannelList
    //   .map((val) => {
    //     return val.STREAM_CHANNEL_CODE + " " + val.STREAM_CHANNEL_DESC;
    //   })
    //   .filter((val) => val == e.target.value);
    // if (segmentSubChannel) {
    //   const subsegmentChannelID = segmentSubChannelList.filter((val) => {
    //     return (
    //       val.STREAM_CHANNEL_CODE + " " + val.STREAM_CHANNEL_DESC ==
    //       segmentSubChannel
    //     );
    //   });
    //   // call api for sub segment details
    //   // const subsegmentChannelID1 = subsegmentChannelID[0].STREAM_CHANNEL_ID;
    //   // const subdetailApi = `${segmentSubDetail_URL}CICode=${auth.CICode}&SegparentId=${subsegmentChannelID1}`;
    //   // const subdetailRes = await Axios.get(subdetailApi);
    //   //
    //   // setSubSegmentDetailList(subdetailRes.data);
    // }
    };
    const segmentSubChannelFilter = (e) => {
        setTimeout(() => {
            let _filteredsubchannel;
            if (!e.query.trim().length) {
                _filteredsubchannel = [...segmentSubChannelList];
            } else {
                _filteredsubchannel = segmentSubChannelList.filter((town) => {
                    return town.STREAM_CHANNEL_DESC.toLowerCase().startsWith(
                        e.query.toLowerCase()
                    );
                });
            }
            setSegmentSubChannelSuggestion(_filteredsubchannel);
        }, 250);
    };

    const segmentSubDetailFilter = (e) => {
        setTimeout(() => {
            let _filteredsubdetail;
            if (!e.query.trim().length) {
                _filteredsubdetail = [...subSegmentDetailList];
            } else {
                _filteredsubdetail = subSegmentDetailList.filter((town) => {
                    return town.SEG_DESC.toLowerCase().startsWith(e.query.toLowerCase());
                });
            }
            setSubSegmentDetailSuggestion(_filteredsubdetail);
        }, 250);
    };
    const handleSubSegmentDetail = (e) => {
        if (e.target.value.SBSD_ID !== undefined) {
            setSegmentConfirmBtn(true);

            setSegmentValue(e.target.value);
        }

    // setSubSegmentDetailInput(e.target.value);
    // const segmentSubDetail = subSegmentDetailList
    //   .map((val) => {
    //     return val.SEG_DESC;
    //   })
    //   .filter((val) => val == e.target.value);
    // if (segmentSubDetail) {
    //   alert("Final test");
    // }
    };

    const categories = [
        { name: "Active Customers", key: "A" },
        { name: "All Customers", key: "ALL" },
    ];

    const [selectedCategory, setSelectedCategory] = useState(categories[0]);

    const exportdata = () => {
        setExportbtnpopup(true);
    };

    const checckPhoneValidation = async (phnNumber) => {
        const phnchkURL = "CustomerMast/ChkMobExist?";
        const phNumbercall = `${phnchkURL}MobNo=${phnNumber.toString()}`;
        try {
            const phNumberRes = await Axios.get(phNumbercall);
            let phnChkResponse = phNumberRes.data;
            return phnChkResponse;
        } catch (e) {
            return null;
        }
    };

    const downloadAllcustdata = async () => {
        setExportbtnpopup(false);
        let strfromdate = "01/01/1890";
        let strtodate = "01/01/9999";
        let custTag = selectedCategory.key;
        const exportcustdata_URL = "CustomerMast/GetCustomerExportData?";
        const exportalldata = `${exportcustdata_URL}CICode=${auth.CICode}&strdist=${auth.CompId}&strfromdate=${strfromdate}&strtodate=${strtodate}&custTag=${custTag}`;
        const response = await Axios.get(exportalldata);

        let date = new Date();
        let day = date.getDate();
        var months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        let exactmonths = months[date.getMonth()];
        // let exactmonth = (date.getMonth() + 1).toString().padStart(2, "0");
        let year = date.getFullYear();
        let uniqueNo = `Customer_Mast_Report_${day}${exactmonths}${year}`;
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(response.data);
        XLSX.utils.book_append_sheet(wb, ws, uniqueNo);
        XLSX.writeFile(wb, `${uniqueNo}.xlsx`);
    };

    // CUSTOMER MASTER SAVE FOR EDIT

    const customerMasterSave = async (obj1) => {
        // console.log("OBJ",obj1);
        // return;
        let isChanged = "";
        setCustomerSaveUpdateLoader(true);
        setErrorLable(true);
        const saveCustomerMaster_URL = "CustomerMast/SaveCustomerDetails";

        // if(customerDetailsById.KYC==="")
        if (customerDetailsById.KYC == "A") {
            if (
                customerDetailsById.Firstname === obj1.cfname &&
        customerDetailsById.Lastname === obj1.clname &&
        customerDetailsById.Add1 === obj1.bill_add1 &&
        customerDetailsById.Add2 === obj1.bill_add2 &&
        customerDetailsById.BillPinCode === obj1.bill_zipcode &&
        customerDetailsById.OtherPhoneNo === obj1.cmobileno
            ) {
                isChanged = "N";
            } else {
                isChanged = "Y";
            }
        }

        // const tax_area_row = dropDownAll[0].filter((val) => {
        //   return val.NAME == obj1.tax_area;
        // });
        // const payment_term_row = dropDownAll[1].filter((val) => {
        //   return val.NAME == obj1.payment_term;
        // });
        // const modeofpayment = dropDownAll[2].filter((val) => {
        //   return val.NAME == obj1.payment_mode;
        // });
        //
        // debugger;

        const creation_date = format(obj1.creation_date, "dd/MM/yyyy");
        // const expiry = format(obj1.expiry_date, "dd/MM/yyyy");
        //
        const expiry_date = obj1.expiry_date
            ? format(obj1.expiry_date, "dd/MM/yyyy")
            : "";

        //

        // add new customer master payload------------------------------------------

        const addnewSaveObj = {
            ciCode: auth.CICode, // send blank for insert
            custId: "", // blank for insert
            compid: auth.CompId,
            creditHold: obj1.credit_hold,
            custGstin: obj1.gst_no,
            uniqueCode: obj1.unique_code,
            tinno: obj1.tin_no,
            paN_NO: obj1.pan_no,
            taxForm: "", // disabled for insert
            kyc: "", //obj1.kyc_status ? "Y" : "N",
            aadharno: obj1.caadhar,
            imageUrl: "",
            custPhoto: "",
            specialCode: "",
            employee: `${obj1.employeeSelect.MASK_ID}~${obj1.employeeSelect.MASK_EMP_ID}~${obj1.employeeSelect.CODE}~${obj1.employeeSelect.NAME}`,
            permission: true,
            userId: auth.UserId,
            partyId: "", // customerDetailsById.PartyId,
            maxOrderVal: "",
            remarks: obj1.gst_no, // add bill gst "GSTNo"
            creditLimit: obj1.credit_limit,
            billingIns: obj1.billing_inst,
            invCopies: "0",
            custdeliveryInst: obj1.delivery_inst,
            priority: "",
            privilege: "", // send blank
            docDiscount: obj1.trade_discount,
            taxAreaId: obj1.tax_area.MASK_AREA_ID,
            taxArea: `${obj1.tax_area.MASK_AREA_ID}~${obj1.tax_area.CODE}~${obj1.tax_area.NAME}`,
            payTermId: obj1.payment_term.MASK_PAY_TERM_ID,
            payTerm: `${obj1.payment_term.MASK_PAY_TERM_ID}~${obj1.payment_term.CODE}~${obj1.payment_term.NAME}`,
            payModeId: obj1.payment_mode.MASK_MOP_ID,
            modeOfPayment: `${obj1.payment_mode.MASK_MOP_ID}~${obj1.payment_mode.CODE}~${obj1.payment_mode.NAME}`,
            currencyId: "",
            currencyName: "",
            vanrtId: "711401000110001",

            gstNo: obj1.gst_no,
            // geogId: obj1.ship_town.MASK_GEOG_ID,
            segId: manualSegmentEnterBtn
                ? segmentValue.SBSD_ID
                : segmentForEdit[0].MASK_SEG_ID,
            displaySeq: "",
            segment: manualSegmentEnterBtn
                ? segmentValue.SEG_DESC
                : segmentForEdit[0].MASK_SEG_DESC,
            seG_NAME: manualSegmentEnterBtn
                ? segmentValue.SEG_DESC
                : segmentForEdit[0].MASK_SEG_DESC,
            allchkmId: "G_BIGS_NA-2|G", //send hard coded
            salesAreaId: shipSalesArea, // call api GetShipSalesArea
            minOrderVal: "",
            lat: obj1.latitude.toString(),
            long: obj1.longitude.toString(),
            insertUpdate: "1", //1 for insert 2 for update
            salesexecutiveId: "", // send blank
            transportChargeId: "", //  send blank
            transportCharges: "",
            creationDate: creation_date,
            expiryDate: expiry_date,
            compstate: compState,
            taxPercentageRate: "",
            insertUpdateBill: "1",
            prefferedDay: obj1.p_day_of_delivery.id,
            dayOfWeek: `${obj1.day_of_beat1.CODE}~${obj1.day_of_beat1.NAME}`,
            deliveryDay1: "", //send blank
            deliveryDay2: "", //send blank
            frequency: `${obj1.beat_frequency.MASK_FREQUENCY_ID}~${obj1.beat_frequency.CODE}~${obj1.beat_frequency.NAME}`,
            beatFrequency: "", //send blank
            startWeek: "", //send blank
            beat2: "", //send blank
            shipSerialNo: "1", // send 1 always
            shipTitle: obj1.ship_title,
            shipFirstname: obj1.fname,
            shipMiddlename: obj1.mname,
            shipLastname: obj1.lname,
            shipEmail: obj1.ship_co_email,
            shipFax: "", // send blank
            shipToGSTN: "",
            shipToName: obj1.ship_to_name,
            shipSTD: "", // send blank
            shipPhoneNo: obj1.phno,
            shipOtherPhoneNo: obj1.mob,
            shipAdd1: obj1.sadd1,
            shipAdd2: obj1.sadd2,
            shipState: obj1.ship_state,
            shipTown: copyToShip ? obj1.ship_town.NAME : obj1.ship_town.TOWN,
            shipDistrict: obj1.ship_district,
            shipPinCode: obj1.ship_zipcode,
            salesArea: shipSalesArea, //call api GetShipSalesArea
            shipGeogId: copyToShip
                ? obj1.ship_town.GEOG_ID
                : obj1.ship_town.MASK_GEOG_ID,
            shipSalesExecutive: obj1.employeeSelect.NAME, // employee Name drop down
            shipSalesExecutiveId: obj1.employeeSelect.MASK_EMP_ID, // employeeID drop down
            shipStatus: "Y", // plesae add chechbox
            vanRt: "DEFAULT VT",
            insertUpdateShip: "1", // 2 for update 1 for insert
            taxFormId: "", // send blank
            prefix: obj1.prefix,
            firstname: obj1.cfname,
            middleName: obj1.cmname,
            lastname: obj1.clname,
            stdCode: "",
            phoneNo: obj1.cphoneno,
            otherPhoneNo: obj1.cmobileno,
            add1: obj1.bill_add1,
            add2: obj1.bill_add2,
            district: obj1.bill_district,
            state: obj1.bill_state,
            town: obj1.bill_town.NAME,
            email: obj1.cemail,
            accountId: "", //send blank
            code: "",
            name: obj1.name,
            ledgerId: "", //send blank
            ledgerName: "", //send blank
            retailTag: "", //send blank
            fax: "", //send blank
            pincode: obj1.bill_zipcode,
            active: obj1.active_check ? "Y" : "N", // bind check status
            chkActive: false, // send false
            distTag: auth.DistTag,
            status: obj1.kyc_status ? "Y" : "N",
            salesValue: "", // send blank
            tradeDiscount: obj1.trade_discount,
            appPermission: obj1.app_per ? true : false, // set on basis of user check
            // strGeogId: obj1.bill_town.GEOG_ID,
            billTitle: obj1.prefix,
            billFirstname: obj1.cfname,
            billMiddlename: obj1.cmname,
            billLastname: obj1.clname,
            billSerialNo: "1", // always 1
            billAdd1: obj1.bill_add1,
            billAdd2: obj1.bill_add2,
            billEmail: obj1.cemail,
            billFax: "", //send blank
            billPhoneNo: obj1.cphoneno,
            billOtherPhoneNo: obj1.cmobileno,
            billGeogId: obj1.bill_town.GEOG_ID,
            billTown: obj1.bill_town.NAME,
            billDistrict: obj1.bill_district,
            billState: obj1.bill_state,
            billPinCode: obj1.bill_zipcode,
            billStatus: "Y", // bind check status
            eInvApplicability: auth.EInvApplicability,
            eInvIRPUserId: auth.EInvIRPUserId,
            eInvIRPUserPassword: auth.EInvIRPUserPassword,
            eInvGSTNO: auth.EInvGSTNO,
            // eInvGSTNO: Decrypt((auth.EInvGSTNO).toString()),
            strcustkyc: "", //send blank
            mobile: obj1.cmobileno, //  add bill_otherphone
            isTCSApplicable: "", //send blank
            //send blank
            // geogId: "401607100",
            // strGeogId: "700102100",

            //ship section----
        };
        // edit customer master payload-----------------------------------------------
        const StrShip = [
            obj1.ship_title,
            obj1.fname,
            obj1.mname,
            obj1.lname,
            obj1.phno,
            obj1.mob,
            obj1.sadd1,
            obj1.sadd2,
            obj1.ship_town.TOWN,
            obj1.ship_co_email,
        ];
        const StrData = [
            customerDetailsById.AllchkmId,
            customerDetailsById.CurrencyId,
            obj1.payment_mode.MASK_MOP_ID,
            obj1.payment_term.MASK_PAY_TERM_ID,
            customerDetailsById.SalesAreaId,
            customerDetailsById.SalesexecutiveId,
            manualSegmentEnterBtn
                ? segmentValue.SBSD_ID
                : segmentForEdit[0].MASK_SEG_ID,
            obj1.tax_area.MASK_AREA_ID,
            customerDetailsById.TaxFormId,
            "711401000110001",
            customerDetailsById.TransportChargeId,
            "Y",
            expiry_date,
            obj1.trade_discount,
            obj1.credit_hold ? "Y" : "N",
            obj1.credit_limit,
            obj1.max_order,
            obj1.min_order,
            obj1.invoice_copy,
            obj1.delivery_inst,
            obj1.billing_inst,
            obj1.gst_no,
            "", // doubt
            "", // doubt
            "", //doubt
            obj1.priority,
            "",
            obj1.prefix,
            obj1.cfname,
            obj1.cmname,
            obj1.clname,
            customerDetailsById.StdCode,
            obj1.cphoneno,
            obj1.cmobileno,
            obj1.bill_add1,
            obj1.bill_add2,
            obj1.bill_town.NAME,
            obj1.tin_no,
            obj1.cemail,
        ];

        const editsaveobj = {
            SMSROWENTRY: [],
            CICode: auth.CICode,
            CustId: custId,
            IsTCSApplicable: customerDetailsById.IsTCSApplicable,
            Compid: auth.CompId,
            CreditHold: obj1.credit_hold,
            CustGstin: customerDetailsById.CustGstin,
            UniqueCode: obj1.unique_code,
            TINNO: obj1.tin_no,
            PAN_NO: obj1.pan_no,
            TaxForm: customerDetailsById.TaxForm,
            KYC: obj1.kyc_status,
            AADHARNO: obj1.caadhar,
            ImageUrl: "",
            CustPhoto: "",
            CustImage: [""],
            CustStore: "",
            StoreImage: [""],
            SpecialCode: "",
            Employee: `${obj1.employeeSelect.MASK_ID}~${obj1.employeeSelect.MASK_EMP_ID}~${obj1.employeeSelect.CODE}~${obj1.employeeSelect.NAME}`,
            Permission: true,
            UserId: auth.UserId,
            PartyId: customerDetailsById.PartyId,
            maxOrderVal: obj1.max_order,
            Remarks: obj1.gst_no,
            CreditLimit: obj1.credit_limit,
            BillingIns: obj1.billing_inst,
            InvCopies: "0",
            CustdeliveryInst: obj1.delivery_inst,
            Priority: obj1.priority,
            Privilege: customerDetailsById.Privilege,
            DocDiscount: obj1.trade_discount,
            TaxAreaId: obj1.tax_area.MASK_AREA_ID,
            TaxArea: `${obj1.tax_area.MASK_AREA_ID}~${obj1.tax_area.CODE}~${obj1.tax_area.NAME}`,
            PayTermId: obj1.payment_term.MASK_PAY_TERM_ID,
            PayTerm: `${obj1.payment_term.MASK_PAY_TERM_ID}~${obj1.payment_term.CODE}~${obj1.payment_term.NAME}`,
            PayModeId: obj1.payment_mode.MASK_MOP_ID,
            ModeOfPayment: `${obj1.payment_mode.MASK_MOP_ID}~${obj1.payment_mode.CODE}~${obj1.payment_mode.NAME}`,
            CurrencyId: customerDetailsById.CurrencyId,
            CurrencyName: customerDetailsById.CurrencyName,
            VanrtId: "711401000110001",
            GSTNo: obj1.gst_no,
            SegId: manualSegmentEnterBtn
                ? segmentValue.SBSD_ID
                : segmentForEdit[0].MASK_SEG_ID,
            DisplaySeq: "",
            Segment: manualSegmentEnterBtn
                ? segmentValue.SEG_DESC
                : segmentForEdit[0].MASK_SEG_DESC,
            SEG_NAME: manualSegmentEnterBtn
                ? segmentValue.SEG_DESC
                : segmentForEdit[0].MASK_SEG_DESC,
            AllchkmId: customerDetailsById.AllchkmId,
            SalesAreaId: customerDetailsById.SalesAreaId, // call api GetShipSalesArea
            MinOrderVal: obj1.min_order,
            Lat: obj1.latitude.toString(),
            Long: obj1.longitude.toString(),
            InsertUpdate: "2", //1 for insert 2 for update
            SalesexecutiveId: customerDetailsById.SalesexecutiveId,
            TransportChargeId: customerDetailsById.TransportChargeId,
            TransportCharges: obj1.transport_charges,
            CreationDate: creation_date,
            ExpiryDate: expiry_date,
            Compstate: customerDetailsById.Compstate,
            TaxPercentageRate: customerDetailsById.TaxPercentageRate,
            InsertUpdateBill: "2",
            PrefferedDay: obj1.p_day_of_delivery.id,
            DayOfWeek: `${obj1.day_of_beat1.CODE}~${obj1.day_of_beat1.NAME}`,
            DeliveryDay1: customerDetailsById.DeliveryDay1, //send blank
            DeliveryDay2: customerDetailsById.DeliveryDay1, //send blank
            Frequency: `${obj1.beat_frequency.MASK_FREQUENCY_ID}~${obj1.beat_frequency.CODE}~${obj1.beat_frequency.NAME}`,
            BeatFrequency: obj1.beat_frequency.NAME,
            StartWeek: customerDetailsById.StartWeek,
            Beat2: customerDetailsById.Beat2,
            ShipSerialNo: customerDetailsById.ShipSerialNo,
            ShipTitle: obj1.ship_title,
            ShipFirstname: obj1.fname,
            ShipMiddlename: obj1.mname,
            ShipLastname: obj1.lname,
            ShipEmail: obj1.ship_co_email,
            ShipFax: customerDetailsById.ShipFax,
            ShipToGSTN: obj1.ship_gst_no,
            ShipToName: obj1.ship_to_name,
            ShipSTD: customerDetailsById.ShipSTD, // send blank
            ShipPhoneNo: obj1.phno,
            ShipOtherPhoneNo: obj1.mob,
            ShipAdd1: obj1.sadd1,
            ShipAdd2: obj1.sadd2,
            ShipState: obj1.ship_state,
            ShipTown: copyToShip ? obj1.ship_town.NAME : obj1.ship_town.TOWN,
            ShipDistrict: obj1.ship_district,
            ShipPinCode: obj1.ship_zipcode,
            SalesArea: customerDetailsById.SalesArea,
            ShipGeogId: copyToShip
                ? obj1.ship_town.GEOG_ID
                : obj1.ship_town.MASK_GEOG_ID,
            ShipSalesExecutive: obj1.employeeSelect.NAME, // employee Name drop down
            ShipSalesExecutiveId: obj1.employeeSelect.MASK_EMP_ID, // employeeID drop down
            ShipStatus: "Y", // plesae add chechbox
            VanRt: "DEFAULT VT",
            InsertUpdateShip: "2", // 2 for update 1 for insert
            DTShippingOldData: customerDetailsById.DTShippingOldData,
            TaxFormId: customerDetailsById.TaxFormId,
            Prefix: obj1.prefix,
            Firstname: obj1.cfname,
            MiddleName: obj1.cmname,
            Lastname: obj1.clname,
            StdCode: "",
            PhoneNo: obj1.cphoneno,
            OtherPhoneNo: obj1.cmobileno,
            Add1: obj1.bill_add1,
            Add2: obj1.bill_add2,
            District: obj1.bill_district,
            State: obj1.bill_state,
            Town: obj1.bill_town.NAME,
            Email: obj1.cemail,
            Str: StrData,
            accountId: customerDetailsById.AccountId,
            Code: customerDetailsById.Code,
            Name: obj1.name,
            LedgerId: customerDetailsById.LedgerId,
            LedgerName: customerDetailsById.LedgerName,
            RetailTag: customerDetailsById.RetailTag,
            Fax: customerDetailsById.Fax,
            Pincode: customerDetailsById.Pincode,
            Active: "Y", // bind check status
            // Active: "Y", // bind check status
            ChkActive: false, // send false
            DistTag: auth.DistTag,
            Status: obj1.kyc_check ? "Y" : "N",
            SalesValue: customerDetailsById.SalesValue,
            TradeDiscount: obj1.trade_discount,
            AwaitedRILDetails: customerDetailsById.AwaitedRILDetails,
            AppPermission: customerDetailsById.AppPermission,
            BillTitle: obj1.prefix,
            BillFirstname: obj1.cfname,
            BillMiddlename: obj1.cmname,
            BillLastname: obj1.clname,
            BillSerialNo: "1", // always 1
            BillAdd1: obj1.bill_add1,
            BillAdd2: obj1.bill_add2,
            BillEmail: obj1.cemail,
            BillFax: "", //send blank
            BillPhoneNo: obj1.cphoneno,
            BillOtherPhoneNo: obj1.cmobileno,
            BillGeogId: obj1.bill_town.GEOG_ID,
            BillTown: obj1.bill_town.NAME,
            BillDistrict: obj1.bill_district,
            BillState: obj1.bill_state,
            BillPinCode: obj1.bill_zipcode,
            BillStatus: "Y", // bind check status
            DSBillData: customerDetailsById.DSBillData,
            DSShipData: customerDetailsById.DSShipData,
            EInvApplicability: auth.EInvApplicability,
            EInvIRPUserId: auth.EInvIRPUserId,
            EInvIRPUserPassword: auth.EInvIRPUserPassword,
            EInvGSTNO: auth.EInvGSTNO,
            // EInvGSTNO: Decrypt((auth.EInvGSTNO).toString()),
            Strcustkyc: customerDetailsById.strcustkyc, //send blank
            strship: StrShip,
            Mobile: obj1.cmobileno,
            dgDistDiscount: customerDetailsById.dgDistDiscount,
            strTrSuppTag: customerDetailsById.strTrSuppTag,
            // strTrSuppTag: customerDetailsById.strTrSuppTag,
            strTrCustTag: customerDetailsById.strTrCustTag,
            strNtrCustTag: customerDetailsById.strNtrCustTag,
            strDefinedBy: customerDetailsById.strDefinedBy,
            strCustCount: customerDetailsById.strCustCount,
            strSuppCount: customerDetailsById.strSuppCount,
            AlchemyName: customerDetailsById.AlchemyName,
            RecordDefinedBy: customerDetailsById.RecordDefinedBy,
            AwaitedDNADetails: customerDetailsById.AwaitedDNADetails,
            IsKYCApproved: isChanged,
        };

        // let errorRes;
        try {
            const res = await Axios.post(
                saveCustomerMaster_URL,
                JSON.stringify(newCustomerSave ? addnewSaveObj : editsaveobj),
                {
                    headers: { "Content-Type": "application/json" },
                }
            );
            // errorRes = res.Message;
            if (res.data) {
                setCustomerSaveUpdateLoader(false);
                nav("/masters/customermaster");
                if (res.data && newCustomerSave) {
                    toast.success(
                        `New Customer has been created Successfully with Customer code ${res.data.AccountId}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        }
                    );
                    setPartyCodeOnsave(res.data);
                    setCustomerSaveDisable(true);
                    setErrorLable(false);
                } else if (res.data && !newCustomerSave) {
                    toast.success(`${res.data.AccountId} has been updated successfully`, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    });
                    setErrorLable(false);
                    setCustomerSaveUpdateLoader(false);
                }
            } else {
                toast.warning("Please fill with correct data format", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
                setCustomerSaveUpdateLoader(false);
            }
        } catch (e) {
            if (e.response.data.Message == null) {
                toast.warning("Please enter valid data.", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
                setCustomerSaveUpdateLoader(false);
            }
            toast.warning(e.response.data.Message, {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
            setCustomerSaveUpdateLoader(false);
        }
    };
    return (
        <CustomerMasterContext.Provider
            value={{
                viewCustomerDetails,
                editCustMastLoader,
                customerDetailsById,
                creationDate,
                expiryDate,
                townList,
                townHandleChange,
                townInput,
                townFilter,
                townSuggestion,
                billDistrict,
                billState,
                billZip,
                billAddressLoader,
                setShow,
                show,
                getSegmentForBill,
                handleSegmentStream,
                segmentStreamInput,
                setSegmentStreamInput,
                segmentStreamFilter,
                segmentStreamSuggestion,
                handleSegmentChannel,
                segmentChannelInput,
                segmentChannelFilter,
                segmentChannelSuggestion,
                segmentSubChannelInput,
                segmentSubChannelFilter,
                segmentSubChannelSuggestion,
                handleSegmentSubChannel,
                handleSubSegmentDetail,
                subSegmentDetailInput,
                subSegmentDetailSuggestion,
                segmentSubDetailFilter,
                customerMasterSave,
                setManualTownEnter,
                manualTownEnter,
                setSelectedTown,
                selectedTown,
                setSegmentChannelInput,
                setSegmentSubChannelInput,
                setSubSegmentDetailInput,
                townError,
                billTown,
                segmentValue,
                setManualSegmentEnterBtn,
                manualSegmentEnterBtn,
                townInitialVal,
                selectedShipTown,
                setSelectedShipTown,
                shiptownFilter,
                shipTownSuggestion,
                shipTownHandleChange,
                manualShipTownEnter,
                shipDistrict,
                shipZip,
                shipState,
                shipTownError,
                shipTownInitailVal,
                shipTown,
                shipTownList,
                setNewCustomerSave,
                getSaveBillShipTown,
                newCutomerUniCode,
                newCustomerSave,
                customerSaveDisable,
                setCustomerSaveDisable,
                errorLabel,
                setErrorLable,
                customerSaveUpdateLoader,
                partyCodeOnSave,
                segmentConfirmBtn,
                setSegmentConfirmBtn,
                uniqueCodeBtn,
                setManualShipEnter,
                exportbtnpopup,
                setExportbtnpopup,
                exportdata,
                downloadAllcustdata,
                selectedCategory,
                setSelectedCategory,
                categories,
                setCopyToShip,
                editView,
                setEditView,
                setCompState,
                compState,
                kycStatus,
                kycfieldDisabled,
                setCustomerDetailsById,
                setKycfieldDisabled,
                checckPhoneValidation
            }}
        >
            {children}
        </CustomerMasterContext.Provider>
    );
};
export const useCustMasterContext = () => {
    return useContext(CustomerMasterContext);
};

export { CustomerMasterProvider, CustomerMasterContext };
